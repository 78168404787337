import {Amplify, API, graphqlOperation} from "aws-amplify";
import * as autoGenMutations from "./graphql/mutations";
import * as autoGenQueries from "./graphql/queries";
import * as customGql from "./graphql/customGql";
import * as autoGenAPI from "./API";
import {
  AnswerSelectType,
  EmailStatus,
  ImageExtension,
  MailType,
  SearchableAggregateType, SearchableTeamMemberMailAggregateField,
  SearchableThreadMessageAggregateField,
  SignupStatus,
  TeamCustomQuestion,
  TermsType,
  YesNoAnswer
} from "./API";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import { errorMessageNotInThread } from "src/utils/const";


const config = require("./aws-exports") // eslint-disable-line
Amplify.configure(config.default)

type AppInfoVariables = {
  appInfo?: {
    osType: 'ios' | 'android'
    appVersion: string
    // https://reactnative.dev/docs/platform-specific-code#platform-module
    // 例: iOS: 15.3.1, Android: 28
    osVersion: string
  }
}

type InterceptorParam = {
  maintenance?: Maintenance
  appVersions?: AppVersion[]
  newTerms?: Terms
  session?: string
}

type Maintenance = {
  content: string
  startAt: string
  endAt: string
}

type AppVersion = {
  version: string
  content: string
  releaseAt: string
}

type Terms = {
  version: string
  url: string
}


let interceptor: (param: InterceptorParam) => void
export const registerInterceptor = (fn: (param: InterceptorParam) => void) => {
  interceptor = fn
}

const RA9PLUS_JSON_RESPONSE_PREFIX = 'RA9PLUS-JSON-RESPONSE:'
type Ra9PlusJsonResponseType = 'MAINTENANCE_MODE_ON' | 'APP_UPDATE_REQUIRED' | 'TERM_UPDATE_REQUIRED' | 'INVALID_ACCESS_TOKEN'
type Ra9PlusJsonResponse = {
  message: string
  type: Ra9PlusJsonResponseType
  detail: { [k: string]: any }
}

const gqlError = (message: string): GqlError => ({
  errors: [{
    errorInfo: {
      userMessageWithCode: message
    }
  }]
})

let getRefreshToken: () => string | undefined
let setAccessToken: (accessToken: string) => void
export const registerGetRefreshToken = (fn: () => string | undefined) => {
  getRefreshToken = fn
}
export const registerSetAccessToken = (fn: (accessToken: string) => void) => {
  setAccessToken = fn
}

/**
 * グラフQLリクエストの実行
 */
const execGqlRequest = <T>(gql: string, variables: object, accessToken = 'custom-authorized', refreshAccessToken = true): Promise<GraphQLResult<T>> => {
  return (API.graphql(graphqlOperation(gql, variables, accessToken)) as Promise<GraphQLResult<T>>)
    .then(res => {
      interceptor?.({})
      return res
    })
    .catch((e: GqlError) => {
      // dataが入っている場合は権限による一部データ取得エラーのため、正常処理として返却
      if (e.data != null && Object.keys(e.data).length > 0) {
        return {
          data: e.data as T
        }
      }
      throw e
    })
    .catch(async e => {
      if (e.errors?.[0]?.message?.toLowerCase() === "network error") {
        throw gqlError("ネットワークエラー\nネットワークの接続に失敗しました。")
      }
      // messageに RA9PLUS_JSON_RESPONSE_PREFIX で始まる文字列が含まれている場合は、それ以降に含まれるJSONをパースして情報を取得
      // 上記ケースはAPIから無理やり500エラーを発生させるもののため、originalErrorから情報を取得
      const rawMessage: string = e.errors?.[0]?.originalError?.response?.data?.errors?.[0]?.message || ''

      if (rawMessage.startsWith(RA9PLUS_JSON_RESPONSE_PREFIX)) {
        const { message, type, detail } = JSON.parse(rawMessage.substring(RA9PLUS_JSON_RESPONSE_PREFIX.length)) as Ra9PlusJsonResponse
        switch (type) {
          case 'MAINTENANCE_MODE_ON':
            interceptor?.({
              maintenance: detail as Maintenance
            })
            throw gqlError(message)
          case 'APP_UPDATE_REQUIRED':
            interceptor?.({
              appVersions: (detail as { versions: AppVersion[] }).versions
            })
            throw gqlError(message)
          case 'TERM_UPDATE_REQUIRED':
            interceptor?.({
              newTerms: detail as Terms
            })
            throw gqlError(message)
          case 'INVALID_ACCESS_TOKEN':
            if (refreshAccessToken) {
              const refreshToken = getRefreshToken?.()
              if (refreshToken) {
                const refreshResult = await confirmAccessToken({
                  tokens: {
                    token: accessToken,
                    refresh_token: refreshToken
                  }
                })
                const newAccessToken = refreshResult.data?.confirmAccessToken.data?.AccessToken
                if (newAccessToken) {
                  setAccessToken?.(newAccessToken)
                  return execGqlRequest(gql, variables, newAccessToken, false)
                }
              }
            }

            throw gqlError('INVALID_ACCESS_TOKEN')
        }
      } else {
        interceptor?.({})
        throw e
      }
    })
}

/***
 * customAPIの呼出し例
 *
 * ```
 * import {signUp} from "./aws/customAPI"
 *
 * signUp({
 *   input: {
 *     email: 'hoge@aaa.com'
 *   }
 * })
 *  .then(res => {
 *     console.log(res.data?.signUp)
 *   })
 *  .catch((gqlError: GqlError) => {
 *     gqlError.errors.forEach(e => {
 *       if (e.errorInfo.code === XXX) {
 *         // 処理
 *       } else if (e.errorInfo.code === XXX) {
 *         // 処理
 *       }
 *     })
 *   })
 * ```
 **/

/***
 * 1. 認証系
 ***/


/**
 * 1-1. サインアップAPI
 *
 * アカウントを新規登録するために、認証コードを発行します。
 * 発行された認証コードは指定したメールアドレス宛に送信されます。
 */
export const signUp = (variables: SignUpVariables & AppInfoVariables): Promise<GraphQLResult<SignupResponse>> => {
  return execGqlRequest<SignupResponse>(autoGenMutations.signUp, variables)
}
export type SignUpVariables = autoGenAPI.SignUpMutationVariables
export type SignupResponse = autoGenAPI.SignUpMutation


/**
 * 1-2. サインインAPI
 *
 * サインインのための認証コードを発行し、指定したメールアドレスまたは電話番号宛に送信します。
 */
export const signIn = (variables: SignInVariables & AppInfoVariables): Promise<GraphQLResult<SignInResponse>> => {
  return execGqlRequest<SignInResponse>(autoGenMutations.signIn, variables)
}
export type SignInVariables = autoGenAPI.SignInMutationVariables
export type SignInResponse = autoGenAPI.SignInMutation


/**
 * 1-3. サインアウトAPI
 *
 * サインイン状態のアカウントをサインアウトします。
 */
export const signOut = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<signOutResponse>> => {
  return execGqlRequest<signOutResponse>(autoGenMutations.signOut, variables || {}, accessToken)
}
export type signOutResponse = autoGenAPI.SignOutMutation


/**
 * 1-4. 認証コード送信API
 *
 * サインアップAPI及びサインインAPIによって発行された認証コードを送信し、メールアドレスまたは電話番号を検証します。
 */
export const registerAuthCode = (variables: RegisterAuthCodeVariables & AppInfoVariables): Promise<GraphQLResult<RegisterAuthCodeResponse>> => {
  return execGqlRequest<RegisterAuthCodeResponse>(customGql.registerAuthCode, variables)
}
export type RegisterAuthCodeVariables = autoGenAPI.RegisterAuthCodeMutationVariables
export type RegisterAuthCodeResponse = {
  registerAuthCode: {
    message: string
    data: {
      auth: {
        ChallengeName: string
        Session: string
        ChallengeParameters: string
        AuthenticationResult: {
          AccessToken: string
          ExpiresIn: string
          TokenType: string
          RefreshToken: string
          IdToken: string
          NewDeviceMetadata: string
        }
      }
      user: {
        id: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        email: string
        signupStatus: autoGenAPI.SignupStatus
      }
    }
  }
}


/**
 * 1-5. デバイス情報登録API
 *
 * プッシュ通知等に必要なSP端末の情報を登録します。
 * すでに登録済みの場合は、何もせず正常終了します。
 */
export const createAccountDevice = (accessToken: string, variables: CreateAccountDeviceVariables & AppInfoVariables): Promise<GraphQLResult<CreateAccountDeviceResponse>> => {
  return execGqlRequest<UpdateAccountResponse>(autoGenMutations.createAccountDevice, variables, accessToken)
}
export type CreateAccountDeviceVariables = {
  input: Omit<autoGenAPI.CreateAccountDeviceMutationVariables['input'], 'accountDevicesId'>
}
export type CreateAccountDeviceResponse = autoGenAPI.UpdateAccountMutation

export const deleteAccountDevice = (accessToken: string, variables: RemoveAccountDeviceVariables & AppInfoVariables): Promise<GraphQLResult<RemoveAccountDeviceResponse>> => {
  return execGqlRequest<RemoveAccountDeviceResponse>(autoGenMutations.deleteAccountDevice, variables, accessToken)
}
export type RemoveAccountDeviceVariables = {
  input: Omit<autoGenAPI.DeleteAccountDeviceMutationVariables['input'], 'accountDevicesId'>
}
export type RemoveAccountDeviceResponse = autoGenAPI.DeleteAccountDeviceMutation

/**
 * 1-6. アクセストークン確認API
 *
 * アクセストークンの有効性を確認し、期限切れの場合はリフレッシュトークンによりアクセストークンを再発行します。
 */
export const confirmAccessToken = (variables: ConfirmAccessTokenVariables & AppInfoVariables): Promise<GraphQLResult<ConfirmAccessTokenResponse>> => {
  return execGqlRequest<ConfirmAccessTokenResponse>(autoGenMutations.confirmAccessToken, variables)
}
export type ConfirmAccessTokenVariables = autoGenAPI.ConfirmAccessTokenMutationVariables
export type ConfirmAccessTokenResponse = autoGenAPI.ConfirmAccessTokenMutation


/**
 * 1-7. メールアドレス/電話番号更新API
 *
 * アカウントのメールアドレス/電話番号変更のため、検証用の認証コードを指定したメールアドレス/電話番号宛にメール/SMSで送信します。
 */
export const updateAuthMethod = (accessToken: string, variables: UpdateAuthMethodVariables & AppInfoVariables): Promise<GraphQLResult<UpdateAuthMethodResponse>> => {
  return execGqlRequest<UpdateAuthMethodResponse>(autoGenMutations.updateAuthMethod, variables, accessToken)
}
export type UpdateAuthMethodVariables = autoGenAPI.UpdateAuthMethodMutationVariables
export type UpdateAuthMethodResponse = autoGenAPI.UpdateAuthMethodMutation

const _notifyAccountUpdated = async (type: 'email' | 'phoneNumber', accessToken: string) => {
  if (type === 'email') {
    await execGqlRequest(autoGenMutations.notifyAccountEmailUpdated, {}, accessToken)
  } else {
    await execGqlRequest(autoGenMutations.notifyAccountPhoneNumberUpdated, {}, accessToken)
  }
}


/**
 * 1-8. メールアドレス/電話番号更新用認証コード送信API
 *
 * アカウントのメールアドレス/電話番号変更のため、updateAuthMethodによって発行された認証コードを送信し、メールアドレス/電話番号を検証・更新します。
 */
export const registerAuthMethodCode = async (accessToken: string, variables: RegisterAuthMethodCodeVariables & AppInfoVariables): Promise<GraphQLResult<RegisterAuthMethodCodeResponse>> => {
  const {input, appInfo} = variables
  const {type, ...otherInput} = input
  const res = await execGqlRequest<RegisterAuthMethodCodeResponse>(autoGenMutations.registerAuthMethodCode, { input: otherInput, appInfo }, accessToken)
  await _notifyAccountUpdated(type, accessToken)

  return res
}
export type RegisterAuthMethodCodeVariables = {
  input: autoGenAPI.RegisterAuthMethodCodeMutationVariables['input'] & {
    type: 'email' | 'phoneNumber'
  }
}
export type RegisterAuthMethodCodeResponse = autoGenAPI.RegisterAuthMethodCodeMutation


/**
 * 1-9. 電話番号削除API
 *
 * アカウントに紐づく電話番号を削除し、電話番号によるサインアップを不可とします。
 */
export const deletePhoneNumber = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<DeletePhoneNumberResponse>> => {
  return execGqlRequest<DeletePhoneNumberResponse>(autoGenMutations.deletePhoneNumber, variables || {}, accessToken)
}
export type DeletePhoneNumberResponse = autoGenAPI.DeletePhoneNumberMutation


/**
 * 1-10. 一時トークン発行API
 *
 * アクセストークンを取得するための一時発行トークンを取得します。
 * SPのアプリからSP Webを開く際などアクセストークンを共有する際に使用します。
 */
export const getTempToken = (accessToken: string, variables: GetTempTokenVariables & AppInfoVariables): Promise<GraphQLResult<GetTempTokenResponse>> => {
  return execGqlRequest<GetTempTokenResponse>(autoGenMutations.getTempToken, variables, accessToken)
}
export type GetTempTokenVariables = autoGenAPI.GetTempTokenMutationVariables
export type GetTempTokenResponse = autoGenAPI.GetTempTokenMutation


/**
 * 1-11. 一時トークンによるアクセストークン取得API
 *
 * 一時発行トークンによりアクセストークンを取得します。
 */
export const getAccessTokenByTempToken = (variables: GetAccessTokenByTempTokenVariables & AppInfoVariables): Promise<GraphQLResult<GetAccessTokenByTempTokenResponse>> => {
  return execGqlRequest<GetAccessTokenByTempTokenResponse>(autoGenMutations.getAccessTokenByTempToken, variables)
}
export type GetAccessTokenByTempTokenVariables = autoGenAPI.GetAccessTokenByTempTokenMutationVariables
export type GetAccessTokenByTempTokenResponse = autoGenAPI.GetAccessTokenByTempTokenMutation


/***
 * 2. アカウント系
 ***/


/**
 * 2-1. アカウント本登録API
 *
 * 初回アカウント登録のフローの中で、サービスを使用する上で必要な情報を登録することで、アカウント仮登録後の本登録を行います。
 * 旧らくらく連絡網からの移行で事前設定データ(グループの紐づけ等)がある場合は、それらの設定を適用します。
 */
export const initAccount = (accessToken: string, variables: InitAccountVariables & AppInfoVariables): Promise<GraphQLResult<InitAccountResponse>> => {
  return execGqlRequest<InitAccountResponse>(autoGenMutations.initAccount, variables, accessToken)
}
export type InitAccountVariables = autoGenAPI.InitAccountMutationVariables
export type InitAccountResponse = autoGenAPI.InitAccountMutation


/**
 */
export const getAccountInfo = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<GetAccounInfoResponse>> => {
  return execGqlRequest<GetAccounInfoResponse>(autoGenQueries.getAccountInfo, variables || {}, accessToken)
}
export type GetAccounInfoResponse = autoGenAPI.GetAccountInfoQuery


/**
 * 2-2. アカウントID取得API
 *
 * accessTokenにより認証し、アカウントIDを取得します。
 */
export const getAccountId = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<GetAccountIdResponse>> => {
  return execGqlRequest<GetAccountIdResponse>(autoGenQueries.getAccountId, variables || {}, accessToken)
}
export type GetAccountIdResponse = autoGenAPI.GetAccountIdQuery


/**
 * 2-3. アカウント取得API
 *
 * アカウントに紐づく情報を取得します。
 */
export const getAccount = (accessToken: string, variables: GetAccountVariables & AppInfoVariables): Promise<GraphQLResult<GetAccountResponse>> => {
  return execGqlRequest<GetAccountResponse>(customGql.getAccount, variables, accessToken)
}
export type GetAccountVariables = autoGenAPI.GetAccountQueryVariables
export type GetAccountResponse = {
  getAccount: {
    id: string
    email: string
    phoneNumber: string
    lastName: string
    firstName: string
    lastNameKana: string
    firstNameKana: string
    gender?: autoGenAPI.Gender | null
    birthday?: string | null
    job?: {
      id: string
      label: string
    } | null
    school?: {
      id: string
      label: string
    } | null
    schoolDepartment?: {
      id: string
      label: string
    } | null
    schoolGraduationYear?: number | null
    prefecture?: {
      id: string
      label: string
    } | null
    city?: {
      id: string
      label: string
    } | null
    questionAnswers?: {
      items: {
        question: {
          id: string
          label: string
        }
        answer: {
          id: string
          label: string
        }
      }[]
    }
    matchingTermsAgreedAt?: string | null
    agreedMatchingTermsVersion?: string | null
    signupStatus: SignupStatus
    createdAt: string
    updatedAt: string
  }
}


/**
 * 2-4. アカウント更新API
 *
 * アカウントに紐づく情報を更新します。
 */
export const updateAccount = (accessToken: string, variables: UpdateAccountVariables & AppInfoVariables): Promise<GraphQLResult<UpdateAccountResponse>> => {
  return execGqlRequest<UpdateAccountResponse>(autoGenMutations.updateAccount, variables, accessToken)
}
export type UpdateAccountVariables = autoGenAPI.UpdateAccountMutationVariables
export type UpdateAccountResponse = autoGenAPI.UpdateAccountMutation


/**
 * 2-5. アカウント削除API
 *
 * Accountを削除し、らくらく連絡網+へのログインを不可とします。
 */
export const deleteAccount = async (accessToken: string, variables: DeleteAccountVariables & AppInfoVariables): Promise<GraphQLResult<DeleteAccountResponse>> => {
  // らくらく連絡網+のデータベースからデータを削除
  const res = await execGqlRequest<DeleteAccountResponse>(autoGenMutations.deleteAccount, variables, accessToken)
  if (!res.data?.deleteAccount.id || (res.errors || []).length > 0) return res

  // 認証基盤側のデータを削除
  await execGqlRequest<DeleteAccountResponse>(autoGenMutations.deleteAccountAuth, {}, accessToken)
  return res
}
export type DeleteAccountVariables = autoGenAPI.DeleteAccountMutationVariables
export type DeleteAccountResponse = autoGenAPI.DeleteAccountMutation


/**
 * 2-6. 通知設定取得API
 *
 * アカウントに紐づく1団体の通知設定を取得します。
 */
export const getNotificationSetting = (accessToken: string, variables: GetNotificationSettingVariables & AppInfoVariables): Promise<GraphQLResult<GetNotificationSettingResponse>> => {
  const { accountId, teamId, appInfo } = variables
  const variables_: autoGenAPI.GetNotificationSettingQueryVariables & AppInfoVariables = {
    id: `${accountId}.${teamId}`,
    appInfo
  }
  return execGqlRequest<GetNotificationSettingResponse>(autoGenQueries.getNotificationSetting, variables_, accessToken)
}
export type GetNotificationSettingVariables = {
  accountId: string
  teamId: string
}
export type GetNotificationSettingResponse = autoGenAPI.GetNotificationSettingQuery

export const listNotificationSetting = (accessToken: string,variables?: AppInfoVariables): Promise<GraphQLResult<listNotificationSettingResponese>> => {
  return execGqlRequest<listNotificationSettingResponese>(autoGenQueries.listNotificationSetting,variables || {}, accessToken)
}
export type listNotificationSettingResponese = autoGenAPI.ListNotificationSettingQuery

/**
 * 2-7. 通知設定更新API
 *
 * アカウントに紐づく通知設定を取得します。
 */
export const updateNotificationSetting = (accessToken: string, variables: UpdateNotificationSettingVariables & AppInfoVariables): Promise<GraphQLResult<UpdateNotificationSettingResponse>> => {
  const {input, appInfo} = variables
  const variables_: autoGenAPI.UpdateNotificationSettingMutationVariables & AppInfoVariables = {
    input: {
      id: `${input.accountId}.${input.teamId}`,
      mailReceivedNotice: input.mailReceivedNotice,
      threadMessageReceivedNotice: input.threadMessageReceivedNotice,
      joinRequestNotice: input.joinRequestNotice,
      teamMatchingNotice: input.teamMatchingNotice,
    },
    appInfo,
  }
  return execGqlRequest<UpdateNotificationSettingResponse>(autoGenMutations.updateNotificationSetting, variables_, accessToken)
}
export type UpdateNotificationSettingVariables = {
  input: Omit<autoGenAPI.UpdateNotificationSettingMutationVariables['input'], 'id'> & {
    accountId: string
    teamId: string
  }
}
export type UpdateNotificationSettingResponse = {
  updateNotificationSetting: {
    id: string
  }
}


/***
 * 3. お知らせ系
 ***/


/**
 * 3-1. サービス管理者からのお知らせ一覧API
 *
 * サービス管理者からのお知らせの一覧を作成日時の降順で取得します。
 * お知らせは、過去1週間分を取得します。
 */
export const listAdminNotice = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<ListAdminNoticeResponse>> => {
  return execGqlRequest<ListAdminNoticeResponse>(autoGenQueries.listAdminNotice, variables || {}, accessToken)
}
export type ListAdminNoticeResponse = autoGenAPI.ListAdminNoticeQuery


/**
 * 3-2. サービス管理者からのお知らせ取得API
 *
 * サービス管理者からのお知らせの詳細を取得します。
 */
export const getAdminNotice = (accessToken: string, variables: GetAdminNoticeVariables & AppInfoVariables): Promise<GraphQLResult<GetAdminNoticeResponse>> => {
  return execGqlRequest<GetAdminNoticeResponse>(autoGenQueries.getAdminNotice, variables, accessToken)
}
export const createAccountAdminNotice = (accessToken: string, variables: CreateAccountAdminNoticeMutationVariables & AppInfoVariables): Promise<GraphQLResult<GetAdminNoticeResponse>> => {
  return execGqlRequest<GetAdminNoticeResponse>(autoGenMutations.createAccountAdminNotice, variables, accessToken)
}
export type GetAdminNoticeVariables = autoGenAPI.GetAdminNoticeQueryVariables
export type GetAdminNoticeResponse = autoGenAPI.GetAdminNoticeQuery
export type CreateAccountAdminNoticeMutationVariables = autoGenAPI.CreateAccountAdminNoticeMutationVariables
export type accountAdminNoticeResponse = autoGenAPI.CreateAccountAdminNoticeMutation
/**
 * 3-3. 団体からのお知らせ一覧API
 *
 * 団体からのお知らせの一覧を公開日時の降順で取得します。
 * お知らせは、過去1週間分を取得します。
 */
export const listTeamNotice = (accessToken: string, variables: ListTeamNoticeVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamNoticeResponse>> => {
  const now = new Date()
  return execGqlRequest<ListTeamNoticeResponse>(customGql.listTeamNotice, variables, accessToken).then(res => {
    if (!res.data) return res

    const createdAtFromDateTime = getRelativeDate(now, { date: - 7 })
    const notices = res.data.getAccount.teamNotices.items
    res.data.getAccount.teamNotices.items = notices.filter(n => new Date(n.createdAt) > createdAtFromDateTime)
    return res
  })
}
export type ListTeamNoticeVariables = {
  accountId: string
}
export type ListTeamNoticeResponse = {
  getAccount: {
    teamNotices: {
      items: {
        id: string
        noticeType: TeamNoticeType
        title: string
        readFlg: boolean
        url: string
        team: {
          id: string
          name: string
          paidFunctionEnabled: boolean
        },
        teamMemberTeamNoticeId: string
        createdAt: string
      }[]
    }
  }
}


/**
 * 3-4. 団体からのお知らせ既読API
 *
 * 団体からのお知らせを既読処理します。
 */
export const markTeamNoticeAsRead = (accessToken: string, variables: MarkTeamNoticeAsReadVariables & AppInfoVariables): Promise<GraphQLResult<MarkTeamNoticeAsReadResponse>> => {
  const variables_ = {
    ...variables,
    readFlg: true
  }
  return execGqlRequest<MarkTeamNoticeAsReadResponse>(customGql.markTeamNoticeAsRead, variables_, accessToken)
}
export type MarkTeamNoticeAsReadVariables = {
  id: string
}
export type MarkTeamNoticeAsReadResponse = {
  id: string
}


/**
 * 3-5. 団体からのお知らせ一括削除API
 *
 * アカウントに紐づく団体からのお知らせをすべて削除します。
 */
export const deleteAllTeamNotice = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<DeleteAllTeamNoticeResponse>> => {
  return execGqlRequest<DeleteAllTeamNoticeResponse>(autoGenMutations.deleteAllTeamNotice, variables || {}, accessToken)
}
export type DeleteAllTeamNoticeResponse = autoGenAPI.DeleteAllTeamNoticeMutation


/***
 * 4. 団体系
 ***/


/**
 * 4-1. 団体一覧API
 *
 * アカウントに紐づく団体の一覧を以下順序ですべて取得します。
 * <順序>
 * 1. 代表者となっている団体（その中でも新しく作成した団体が上にくる）
 * 2. 一般メンバー（送信権限あり）となっている団体（その中でも新しく参加した団体が上にくる）
 * 3. 一般メンバー（送信権限なし）となっている団体（その中でも新しく参加した団体が上にくる）
 */
export const listTeam = (accessToken: string, variables: ListTeamVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamResponse>> => {
  const variables_ = {
    ...variables,
    notDeleteFlg: true
  }
  return execGqlRequest<ListTeamRawResponse>(customGql.listTeam, variables_, accessToken).then(res => {
    const teams = res.data?.getAccount?.teamMembers?.items || []
    const managerTeams = teams.filter(t => t.role === 'manager')
    const mailSenderTeams = teams.filter(t => t.role === 'mailSender')
    const generalTeams = teams.filter(t => t.role === 'general')

    const items: ListTeamResponse['listTeam']['items'] =
      managerTeams.sort((l, r) => l.team.createdAt > r.team.createdAt ? -1 : 1)
        .concat(mailSenderTeams.sort((l, r) => l.team.createdAt > r.team.createdAt ? -1 : 1))
        .concat(generalTeams.sort((l, r) => l.team.createdAt > r.team.createdAt ? -1 : 1))

    return {
      ...res,
      data: {
        listTeam: {
          items: items
        }
      }
    }
  })
}

export const getHaveOldRa9TeamNotJoin = (accessToken: string, variables: ListTeamVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamRawResponse>> => {
  const variables_ = {
    ...variables,
    notDeleteFlg: true
  }
  return execGqlRequest<ListTeamRawResponse>(customGql.listTeam, variables_, accessToken).then(res => {
    return {
      ...res
    }
  })
}


export type ListTeamVariables = {
  accountId: string
}
export type ListTeamResponse = {
  listTeam: {
    items: {
      id: string
      role: MemberRole
      memberSeq: number
      team: {
        id: string
        name: string
        invitationCode: string
        createdAt: string
        updatedAt: string
        migrationId?: number
        paidFunctionEnabled: boolean
        showAdsFlg: boolean
      }
    }[]
  }
}
export type ListTeamRawResponse = {
  getAccount: {
    haveOldRa9TeamNotJoin: boolean
    teamMembers: {
      items: ListTeamResponse['listTeam']['items']
    }
  }
}


//listAllMigratedTeams
export const listAllMigratedTeams = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<ListAllMigratedTeamsResponse>> => {
  return execGqlRequest<ListAllMigratedTeamsResponse>(autoGenQueries.listAllMigratedTeams, variables || {}, accessToken)
}
export type ListAllMigratedTeamsResponse = autoGenAPI.ListAllMigratedTeamsQuery

//listNotJoinMembers
export const listNotJoinMembers = (accessToken: string, variables: ListNotJoinMembersVariables & AppInfoVariables): Promise<GraphQLResult<ListNotJoinMembersResponse>> => {
  return execGqlRequest<ListNotJoinMembersResponse>(autoGenQueries.listNotJoinMembers, variables, accessToken)
}
export type ListNotJoinMembersVariables = autoGenAPI.ListNotJoinMembersQueryVariables
export type ListNotJoinMembersResponse = autoGenAPI.ListNotJoinMembersQuery


/**
 * 4-2. 仮団体ID取得API
 *
 * 団体作成前の、仮団体IDを取得します。
 * 仮団体IDはアカウント単位に発行され、未発行の場合は新規で発行されたIDを取得します。
 * このIDは、団体登録API実行時に削除されます。
 */
export const getOrCreateTempTeamId = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<GetOrCreateTempTeamIdResponse>> => {
  return execGqlRequest<GetOrCreateTempTeamIdResponse>(autoGenMutations.getOrCreateTempTeamId, variables || {}, accessToken)
}
export type GetOrCreateTempTeamIdResponse = autoGenAPI.GetOrCreateTempTeamIdMutation


/**
 * 4-3. 団体登録API
 *
 * 団体を新規に作成します。団体作成後、実行アカウントを管理者として団体メンバーに追加します。
 */
export const createTeam = (accessToken: string, variables: CreateTeamVariables & AppInfoVariables): Promise<GraphQLResult<CreateTeamResponse>> => {
  return execGqlRequest<CreateTeamResponse>(autoGenMutations.createTeam, variables, accessToken)
}
export type CreateTeamVariables = autoGenAPI.CreateTeamMutationVariables
export type CreateTeamResponse = autoGenAPI.CreateTeamMutation


/**
 * 4-4. 団体取得API
 *
 * 団体のプロフィールを取得します。
 */
export const getTeam = (accessToken: string, variables: GetTeamVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamResponse>> => {
  return execGqlRequest<GetTeamResponse>(customGql.getTeam, variables, accessToken)
}
export type GetTeamVariables = autoGenAPI.GetTeamQueryVariables
export type GetTeamResponse = {
  getTeam: {
    id: string
    name: string
    invitationCode: string
    category: {
      id: string
      label: string
    }
    activity: {
      id: string
      label: string
    }
    prefecture: {
      id: string
      label: string
    }
    city: {
      id: string
      label: string
    }
    description: string
    requirementForMemberProfile: string
    customQuestions: autoGenAPI.TeamCustomQuestion[]
    showMemberListFlg: boolean
    createdAt: string
    updatedAt: string
    migrationId: number
    paidFunctionEnabled: boolean
    showAdsFlg: boolean
  }
}


/**
 * 4-5. 団体更新API
 *
 * 団体のプロフィールを更新します。
 */
export const updateTeam = (accessToken: string, variables: UpdateTeamVariables & AppInfoVariables): Promise<GraphQLResult<UpdateTeamResponse>> => {
  return execGqlRequest<UpdateTeamResponse>(autoGenMutations.updateTeam, variables, accessToken)
}
export type UpdateTeamVariables = autoGenAPI.UpdateTeamMutationVariables
export type UpdateTeamResponse = autoGenAPI.UpdateTeamMutation


/**
 * 4-6. 団体を解約し、団体及び団体に紐づく全ての情報を削除します。
 */
export const deleteTeam = (accessToken: string, variables: DeleteTeamVariables & AppInfoVariables): Promise<GraphQLResult<DeleteTeamResponse>> => {
  return execGqlRequest<DeleteTeamResponse>(autoGenMutations.deleteTeam, variables, accessToken)
}
export type DeleteTeamVariables = autoGenAPI.DeleteTeamMutationVariables
export type DeleteTeamResponse = autoGenAPI.DeleteTeamMutation


/**
 * 4-7. 団体通報API
 *
 * 団体を通報します。
 */
export const reportTeam = (accessToken: string, variables: ReportTeamVariables & AppInfoVariables): Promise<GraphQLResult<ReportTeamResponse>> => {
  return execGqlRequest<ReportTeamResponse>(autoGenMutations.reportTeam, variables, accessToken)
}
export type ReportTeamVariables = autoGenAPI.ReportTeamMutationVariables
export type ReportTeamResponse = autoGenAPI.ReportTeamMutation


/***
 * 5. 団体参加系
 ***/


/**
 * 5-1. 団体招待情報取得API
 *
 * 団体への招待コードを取得します。
 */
export const getInvitationInfo = async (accessToken: string, variables: GetInvitationInfoVariables & AppInfoVariables): Promise<GraphQLResult<GetInvitationInfoResponse>> => {
  const res = await execGqlRequest<GetInvitationInfoRowResponse>(customGql.getInvitationInfo, variables, accessToken)
  const invitationCode = res?.data?.getTeam.invitationCode || ''

  return {
    ...res,
    data: {
      getInvitationInfo: {
        invitationCode: invitationCode,
      }
    }
  }
}
export type GetInvitationInfoVariables = {
  teamId: string
}
export type GetInvitationInfoResponse = {
  getInvitationInfo: {
    invitationCode: string
  }
}
type GetInvitationInfoRowResponse = {
  getTeam: {
    invitationCode: string
  }
}


/**
 * 5-2. 招待コード検証API
 *
 * 招待コードから団体IDを取得します。
 */
export const confirmInvitationCode = async (accessToken: string, variables: ConfirmInvitationCodeVariables & AppInfoVariables): Promise<GraphQLResult<ConfirmInvitationCodeResponse>> => {
  return execGqlRequest<GetTeamByInvitationCodeRawResponse>(customGql.getTeamByInvitationCode, variables, accessToken).then(res => {
    const teams = res?.data?.getTeamByInvitationCode.items || []
    const teamMembersList = (res?.data?.getAccount.teamMembers.items.map(item => item?.team?.id || "")) || []
    const preTeamMembersList = (res?.data?.getAccount.preTeamMembers.items.map(item => item?.team?.id || "")) || []
    const belongingTeamIds = [
      ...(teamMembersList.filter((item) => item !== "")),
      ...(preTeamMembersList.filter((item) => item !== ""))
    ]

    // itemsが複数になることはない
    const team: ConfirmInvitationCodeTeam | null = (teams.length > 0) ? teams[0] : null
    let errorMessage: string | undefined = undefined
    if (team == null) {
      errorMessage = 'エラーコード 007\n一致する団体が存在しません。\n正しい招待コードを確認してください。'
    } else if (belongingTeamIds.find(tid => tid === team.id)) {
      errorMessage = 'エラーコード 006\n既に参加申請済です。'
    }
    return {
      ...res,
      data: {
        confirmInvitationCode: {
          team: errorMessage == null ? team : null,
          errorMessage: errorMessage
        }
      }
    }
  })
}
export type ConfirmInvitationCodeVariables = {
  accountId: string
  invitationCode: string
}
type ConfirmInvitationCodeTeam = {
  id: string
  name: string
  requirementForMemberProfile?: string | null
  customQuestions?: TeamCustomQuestion[] | null
}
export type ConfirmInvitationCodeResponse = {
  confirmInvitationCode: {
    team?: ConfirmInvitationCodeTeam | null
    errorMessage?: string
  }
}
type GetTeamByInvitationCodeRawResponse = {
  getTeamByInvitationCode: {
    items: ConfirmInvitationCodeTeam[]
  }
  getAccount: {
    teamMembers: {
      items: {
        team: {
          id: string
        }
      }[]
    },
    preTeamMembers: {
      items: {
        team: {
          id: string
        }
      }[]
    }
  }
}


/**
 * 5-3. 期限付き招待IDから招待コード取得API
 *
 * 期限付き招待IDから招待コードを取得します。
 * 期限付き招待ID発行から15日以上経過している、または期限付き招待IDが存在しない(クレンジングバッチによって削除された場合を含む)場合は、エラーとなります。
 */
export const getInvitationCodeByVolatileId = (accessToken: string, variables: GetInvitationCodeByVolatileIdVariables & AppInfoVariables): Promise<GraphQLResult<GetInvitationCodeByVolatileIdResponse>> => {
  return execGqlRequest<GetInvitationCodeByVolatileIdResponse>(autoGenQueries.getInvitationCodeByVolatileId, variables, accessToken)
}
export type GetInvitationCodeByVolatileIdVariables = autoGenAPI.GetInvitationCodeByVolatileIdQueryVariables
export type GetInvitationCodeByVolatileIdResponse = autoGenAPI.GetInvitationCodeByVolatileIdQuery


/**
 * 5-4. 団体参加申請API
 *
 * 団体に参加申請を行い、承認待ち中のメンバーとして実行アカウントを追加します。申請先の団体管理者に対して、通知を送信します。
 * 初期画像にはアカウントのプロフィール画像が登録されます。
 */
export const sendJoinRequest = (accessToken: string, variables: SendJoinRequestVariables & AppInfoVariables): Promise<GraphQLResult<SendJoinRequestResponse>> => {
  return execGqlRequest<SendJoinRequestResponse>(autoGenMutations.sendJoinRequest, variables, accessToken)
}
export type SendJoinRequestVariables = autoGenAPI.SendJoinRequestMutationVariables
export type SendJoinRequestResponse = autoGenAPI.SendJoinRequestMutation


/**
 * 5-5. 承認待ちメンバー一覧API
 *
 * 承認待ち団体メンバーの一覧をすべて取得します。
 */
export const listPreTeamMember = async (accessToken: string, variables: ListPreTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<ListPreTeamMemberResponse>> => {
  let teamId: string | undefined = undefined
  let teamName: string | undefined = undefined

  const reader: PaginationInnerReader<PreTeamMember> = async (nextToken?: string | null) => {
    return execGqlRequest<ListPreTeamMemberRawResponse>(customGql.listPreTeamMember, {...variables, count: 300, nextToken: nextToken}, accessToken).then(res => {
      teamId = res.data?.getTeam.id
      teamName = res.data?.getTeam.name
      return {
        data: res.data?.getTeam.preTeamMembers?.items || [],
        nextToken: res.data?.getTeam.preTeamMembers?.nextToken
      }
    })
  }

  const preTeamMembers = await new PaginationReader(reader, 300).readAll()
  if (teamId == null || teamName == null) throw Error('Could not read teamId or teamName')

  return {
    data: {
      getTeam: {
        id: teamId,
        name: teamName,
        preTeamMembers: {
          items: preTeamMembers
        }
      }
    }
  }
}

export const listPreTeamMemberByPage = async (accessToken: string, variables: ListPreTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<ListPreTeamMemberResponse>> => {
  const variables_ = {
    teamId: variables.teamId,
    count: 50,
    nextToken: variables.nextToken,
  }
  
  const res = await execGqlRequest<ListPreTeamMemberRawResponse>(customGql.listPreTeamMember, variables_, accessToken)
  const teamId = res.data?.getTeam.id || undefined
  const teamName = res.data?.getTeam.name || undefined
  if (teamId == null || teamName == null) throw Error('Could not read teamId or teamName')
  return {
    data: {
      getTeam: {
        id: teamId,
        name: teamName,
        preTeamMembers: {
          items: res.data?.getTeam.preTeamMembers?.items || [],
          nextToken: res.data?.getTeam.preTeamMembers?.nextToken || undefined,
        }
      }
    }
  }
}

export type ListPreTeamMemberVariables = {
  teamId: string,
  nextToken?: string
}
type PreTeamMember = {
  id: string
  nickname: string
}

type ListPreTeamMemberRawResponse = {
  getTeam: {
    id: string
    name: string
    preTeamMembers?: {
      items: PreTeamMember[]
      nextToken?: string
    }
  }
}

export type ListPreTeamMemberResponse = {
  getTeam: {
    id: string
    name: string
    preTeamMembers: {
      items: PreTeamMember[]
      nextToken?: string
    }
  }
}

/**
 * 5-6. 承認待ちメンバー一括承認API
 *
 * 指定した承認待ち団体メンバーを一括承認します。
 * 承認されたメンバーは、参加申請時に登録した情報を元に団体メンバーとして登録されます。
 * preTeamMemberIdsの中で、承認待ち団体メンバーとして登録されていないメンバーIDは無視されます。
 */
export const approvePreTeamMember = (accessToken: string, variables: ApprovePreTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<ApprovePreTeamMemberResponse>> => {
  return execGqlRequest<ApprovePreTeamMemberResponse>(autoGenMutations.approvePreTeamMember, variables, accessToken)
}
export type ApprovePreTeamMemberVariables = autoGenAPI.ApprovePreTeamMemberMutationVariables
export type ApprovePreTeamMemberResponse = autoGenAPI.ApprovePreTeamMemberMutation


/**
 * 5-7. 承認待ちメンバー一括拒否API
 *
 * 指定した承認待ち団体メンバーを一括拒否します。
 * 拒否されたメンバーは、承認待ちメンバー一覧から除外されます。
 * preMemberIdsの中で、承認待ち団体メンバーとして登録されていないメンバーIDは無視されます。
 */
export const rejectPreTeamMember = (accessToken: string, variables: RejectPreTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<RejectPreTeamMemberResponse>> => {
  return execGqlRequest<RejectPreTeamMemberResponse>(autoGenMutations.rejectPreTeamMember, variables, accessToken)
}
export type RejectPreTeamMemberVariables = autoGenAPI.RejectPreTeamMemberMutationVariables
export type RejectPreTeamMemberResponse = autoGenAPI.RejectPreTeamMemberMutation


/**
 * 5-8. 団体メンバー招待メッセージ取得API
 *
 * 団体メンバー招待用のメッセージを取得します。
 * 本API実行時に期限付きの招待リンクが新規発行され、メッセージに組み込まれます。
 */
export const getTeamInvitationMessage = (accessToken: string, variables: GetTeamInvitationMessageVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamInvitationMessageResponse>> => {
  return execGqlRequest<GetTeamInvitationMessageResponse>(autoGenQueries.getTeamInvitationMessage, variables, accessToken)
}
export type GetTeamInvitationMessageVariables = autoGenAPI.GetTeamInvitationMessageQueryVariables
export type GetTeamInvitationMessageResponse = autoGenAPI.GetTeamInvitationMessageQuery


/**
 * 5-9. 一括メール招待API
 *
 * 指定した複数メールアドレスに対して、団体への招待メールを送信します。
 * 再送信/履歴取得用に、招待メールを送信したメールアドレスをDBで保持します。
 */
export const sendInvitationEmail = (accessToken: string, variables: SendInvitationEmailVariables & AppInfoVariables): Promise<GraphQLResult<SendInvitationEmailResponse>> => {
  return execGqlRequest<SendInvitationEmailResponse>(autoGenMutations.sendInvitationEmail, variables, accessToken)
}
export type SendInvitationEmailVariables = autoGenAPI.SendInvitationEmailMutationVariables
export type SendInvitationEmailResponse = autoGenAPI.SendInvitationEmailMutation


/**
 * 5-10. 招待メール再送信API
 *
 * 指定した招待メール送信状況に対して、招待メールを再送信します。
 */
export const resendInvitationEmail = (accessToken: string, variables: ResendInvitationEmailVariables & AppInfoVariables): Promise<GraphQLResult<ResendInvitationEmailResponse>> => {
  return execGqlRequest<ResendInvitationEmailResponse>(autoGenMutations.resendInvitationEmail, variables, accessToken)
}
export type ResendInvitationEmailVariables = autoGenAPI.ResendInvitationEmailMutationVariables
export type ResendInvitationEmailResponse = autoGenAPI.ResendInvitationEmailMutation

export const extendPaidVersionOrder = (accessToken: string,variables: ExtendPaidVersionOrderVariables & AppInfoVariables): Promise<GraphQLResult<ExtendPaidVersionOrderResponse>>  => {
  return execGqlRequest<ExtendPaidVersionOrderResponse>(autoGenMutations.extendPaidVersionOrder,variables, accessToken )
}

export type ExtendPaidVersionOrderVariables = autoGenAPI.ExtendPaidVersionOrderMutationVariables
export type ExtendPaidVersionOrderResponse = autoGenAPI.ExtendPaidVersionOrderMutation

export const resendEmailPaidVersionOrder = (accessToken: string,variables: ResendEmailPaidVersionOrderVariables & AppInfoVariables): Promise<GraphQLResult<ResendEmailPaidVersionOrderResponse>>  => {
  return execGqlRequest<ResendEmailPaidVersionOrderResponse>(autoGenMutations.resendEmailPaidVersionOrder,variables, accessToken )
}

export type ResendEmailPaidVersionOrderVariables = autoGenAPI.ResendEmailPaidVersionOrderMutationVariables
export type ResendEmailPaidVersionOrderResponse = autoGenAPI.ResendEmailPaidVersionOrderMutation

/**
 * 5-11. 招待メール送信状況一覧API
 *
 * 過去に送信した一括招待メールアドレスと送信状況を30件ずつ作成日時の降順順で取得します。
 * 過去30日分を取得します。
 */
export const listInvitationEmailStatus = async (accessToken: string, variables: ListInvitationEmailStatusVariables & AppInfoVariables): Promise<GraphQLResult<ListInvitationEmailStatusResponse>> => {
  const limit = variables.limit || 30
  const minCreatedAt = getRelativeDate(new Date(), { date: - 30 })
  const variables_ = {
    teamId: variables.teamId,
    limit,
    nextToken: variables.nextToken,
    appInfo: variables.appInfo,
  }
  const res = await execGqlRequest<ListInvitationEmailStatusResponse>(customGql.listInvitationEmailStatus, variables_, accessToken)
  if (res.data == null) return res

  const items = res.data.getTeam.invitationEmailStatuses?.items.filter(item => new Date(item.createdAt) >= minCreatedAt) || []
  const newNextToken = items.length >= limit ? res.data.getTeam.invitationEmailStatuses.nextToken : undefined

  return {
    data: {
      getTeam: {
        invitationEmailStatuses: {
          items: items,
          nextToken: newNextToken,
        }
      }
    }
  }
}
export const searchInvitationEmailStatus = async (accessToken: string, variables: autoGenAPI.SearchInvitationEmailStatusesQueryVariables & AppInfoVariables) => {
  const limit = variables.limit || 30
  const minCreatedAt = getRelativeDate(new Date(), { date: - 15 })
  const variables_ = {
    filter: {
      ...variables.filter,
      createdAt: {
        gte: minCreatedAt.toISOString()
      }
    },
    sort: { field: "createdAt", direction: "desc" },
    limit,
    nextToken: variables.nextToken,
    appInfo: variables.appInfo,
  }
  const res = await execGqlRequest<searchInvitationEmailStatus>(autoGenQueries.searchInvitationEmailStatuses, variables_, accessToken)
  if (res.data == null) return res
  const items = res.data.searchInvitationEmailStatuses.items || []
  const newNextToken = items.length >= limit ? res.data.searchInvitationEmailStatuses.nextToken : undefined
  return {
    data: {
      searchInvitationEmailStatuses: {
        items: items,
        nextToken: newNextToken,
        total: res.data.searchInvitationEmailStatuses.total,
      }
    }
  }
}
export type ListInvitationEmailStatusVariables = {
  teamId: string
  limit?: number
  nextToken?: string | null
}
export type ListInvitationEmailStatusResponse = {
  getTeam: {
    invitationEmailStatuses: {
      items: {
        id: string
        email: string
        status: EmailStatus
        createdAt: string
        updatedAt: string
      }[]
      nextToken?: string | null
    }
  }
}

export type searchInvitationEmailStatus = {
  searchInvitationEmailStatuses: {
    items: {
      id: string
      email: string
      status: EmailStatus
      createdAt: string
      updatedAt: string
    }[],
    nextToken?: string | null,
    total: number

  }

}


/***
 * 6. 団体メンバー系
 ***/


/**
 * 6-1. 団体メンバー一覧API
 *
 * 団体に紐づくメンバーの一覧をメンバー番号順ですべて取得します。
 */
export const listTeamMember = async (accessToken: string, variables: ListTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamMemberResponse>> => {
  const variables_ = {
    ...variables,
    notDeleteFlg: true
  }

  const reader: PaginationInnerReader<TeamMember> = async (nextToken?: string | null) => {
    return execGqlRequest<ListTeamMemberRawResponse>(customGql.listTeamMember, {...variables_, nextToken}, accessToken).then(res => {
      return {
        data: res.data?.getTeam.teamMembers?.items.map(item => ({
          id: item.id,
          memberSeq: item.memberSeq,
          nickname: item.nickname,
          role: item.role,
          groupLeaderFlg: item.groups.items.find(item => item.leaderFlg) != null,
          inGroupFlg: item.groups.items.length > 0,
          groupIds: item.groups.items.map(g => g.group.id)
        })) || [],
        nextToken: res.data?.getTeam.teamMembers?.nextToken
      }
    })
  }

  const teamMembers = (await new PaginationReader(reader, 300).readAll()).sort((l, r) => l.memberSeq < r.memberSeq ? -1 : 1)
  return {
    data: {
      listTeamMember: {
        items: teamMembers
      }
    }
  }
}
export type ListTeamMemberVariables = {
  teamId: string
}
type TeamMember = {
  id: string
  memberSeq: number
  nickname: string
  role: MemberRole
  groupLeaderFlg: boolean
  inGroupFlg: boolean
  groupIds: string[]
}

export type ListTeamMemberResponse = {
  listTeamMember: {
    items: TeamMember[]
  }
}
type ListTeamMemberRawResponse = {
  getTeam: {
    teamMembers: {
      items: {
        id: string
        memberSeq: number
        nickname: string
        role: MemberRole
        groups: {
          items: {
            id: string
            leaderFlg: boolean
            group: {
              id: string
            }
          }[]
        }
      }[]
       nextToken?: string | null
    } | null
  }
}


export const searchTeamMember = (accessToken: string, variables: SearchTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<SearchTeamMemberResponse>> => {
  return execGqlRequest<SearchTeamMemberResponse>(autoGenQueries.searchTeamMember, variables, accessToken)
}
export type SearchTeamMemberVariables = autoGenAPI.SearchTeamMemberQueryVariables
export type SearchTeamMemberResponse = autoGenAPI.SearchTeamMemberQuery

export const accountImages = (accessToken: string, variables: AccountImagesVariables): Promise<GraphQLResult<AccountImagesResponse>> => {
  return execGqlRequest<AccountImagesResponse>(autoGenQueries.multiAccountImageGetUrl, variables, accessToken)
}
export type AccountImagesVariables = autoGenAPI.MultiAccountImageGetUrlQueryVariables
export type AccountImagesResponse = autoGenAPI.MultiAccountImageGetUrlQuery


/**
 * 6-2. 団体メンバー取得API
 *
 * 団体メンバーの団体内プロフィール(個人設定)を取得します。
 */
export const getTeamMember = (accessToken: string, variables: GetTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamMemberResponse>> => {
  return execGqlRequest<GetTeamMemberResponse>(customGql.getTeamMember, variables, accessToken)
}
export type GetTeamMemberVariables = {
  id: string
}
export type GetTeamMemberResponse = {
  getTeamMember: {
    id: string
    memberSeq: number
    nickname: string
    nicknameKana: string
    role: MemberRole
    account: {
      email?: string | null
    }
    showEmailFlg: boolean
    phoneNumber?: string | null
    showPhoneNumberFlg: boolean
    belongs?: string | null
    showBelongsFlg: boolean
    grade?: number | null
    mailMigratedFlg?: boolean | null
  }
}


/**
 * 6-3. アカウント団体メンバー取得API
 *
 * 指定したアカウントの団体内プロフィール(個人設定)を取得します。
 */
export const getAccountTeamMember = (accessToken: string, variables: GetAccountTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<GetAccountTeamMemberResponse>> => {
  return execGqlRequest<GetAccountTeamMemberResponse>(customGql.getAccountTeamMember, variables, accessToken)
}
export type GetAccountTeamMemberVariables = {
  accountId: string
  teamId: string
}
export type GetAccountTeamMemberResponse = {
  getAccount: {
    teamMembers: {
      items: {
        team: {
          id: string
        }
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: MemberRole
        account: {
          email: string
        }
        showEmailFlg: boolean
        phoneNumber: string
        showPhoneNumberFlg: boolean
        belongs: string
        showBelongsFlg: boolean
        grade: number
        mailMigratedFlg?: boolean | null
      }[]
    }
  }
}


/**
 * 6-4. 団体メンバー更新API
 *
 * 団体メンバーの団体内個人設定を更新します。
 * 役割が団体管理者に変更された場合は、過去の連絡の中で「他の団体管理者の送信履歴に加える」フラグのある連絡をすべて送信済み連絡として紐づけます。
 * 逆に、団体管理者から別の権限に変更された場合は、自身が送信者でない送信済み連絡の紐づけを削除します。
 */
export const updateTeamMember = (accessToken: string, variables: UpdateTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<UpdateTeamMemberResponse>> => {
  return execGqlRequest<UpdateTeamMemberResponse>(autoGenMutations.updateTeamMember, variables, accessToken)
}
export type UpdateTeamMemberVariables = {
  input: Omit<autoGenAPI.UpdateTeamMemberMutationVariables['input'], 'role' | 'accountTeamMembersId' | 'teamTeamMembersId'> & {
    role?: MemberRole | null
  }
}
export type UpdateTeamMemberResponse = autoGenAPI.UpdateTeamMemberMutation


/**
 * 6-5. 団体メンバー削除API
 *
 * 団体メンバーを団体から除外します。
 */
export const deleteTeamMember = (accessToken: string, variables: DeleteTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<DeleteTeamMemberResponse>> => {
  return execGqlRequest<DeleteTeamMemberResponse>(autoGenMutations.deleteTeamMember, variables, accessToken)
}
export type DeleteTeamMemberVariables = autoGenAPI.DeleteTeamMemberMutationVariables
export type DeleteTeamMemberResponse = autoGenAPI.DeleteTeamMemberMutation


/**
 * 6-6. 団体メンバー件数取得API
 *
 * 団体に紐づくメンバーの件数を取得します。
 */
export const getTeamMemberCount = (accessToken: string, variables: GetTeamMemberCountVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamMemberCountResponse>> => {
  return execGqlRequest<GetTeamMemberCountResponse>(autoGenQueries.getTeamMemberCount, variables, accessToken)
}
export type GetTeamMemberCountVariables = autoGenAPI.GetTeamMemberCountQueryVariables
export type GetTeamMemberCountResponse = autoGenAPI.GetTeamMemberCountQuery


/***
 * 7. グループ系
 ***/


/**
 * 7-1. グループ一覧API
 *
 * 団体に紐づくグループの一覧を名前の昇順ですべて取得します。
 */
export const listGroup = (accessToken: string, variables: ListGroupVariables & AppInfoVariables): Promise<GraphQLResult<ListGroupResponse>> => {
  return execGqlRequest<ListGroupResponse>(autoGenQueries.listGroup, variables, accessToken)
}
export type ListGroupVariables = autoGenAPI.ListGroupQueryVariables
export type ListGroupResponse = autoGenAPI.ListGroupQuery


/**
 * 7-2. グループ登録API
 *
 * グループを新規作成し、所属メンバーを追加します。メンバーIDが団体に含まれていない場合は、無視されます。
 */
export const createGroup = (accessToken: string, variables: CreateGroupVariables & AppInfoVariables): Promise<GraphQLResult<CreateGroupResponse>> => {
  return execGqlRequest<CreateGroupResponse>(autoGenMutations.createGroup, variables, accessToken)
}
export type CreateGroupVariables = {
  input: Omit<autoGenAPI.CreateGroupMutationVariables['input'], 'teamGroupsId'>
}
export type CreateGroupResponse = autoGenAPI.CreateGroupMutation


/**
 * 7-3. グループ取得API
 *
 * グループ情報およびグループに所属するメンバーの一覧をXXX順ですべて取得します。
 */
export const getGroup = async (accessToken: string, variables: GetGroupVariables & AppInfoVariables): Promise<GraphQLResult<GetGroupResponse>> => {
  let groupId: string | undefined = undefined
  let groupName: string | undefined = undefined
  let createdAt: string | undefined = undefined
  let updatedAt: string | undefined = undefined

  const setValue = (groupId_: string, groupName_: string, createdAt_: string, updatedAt_: string) => {
    groupId = groupId_
    groupName = groupName_
    createdAt = createdAt_
    updatedAt = updatedAt_
  }

  const reader: PaginationInnerReader<GroupMember> = async (nextToken?: string | null) => {
    return execGqlRequest<GetGroupRawResponse>(customGql.getGroup, {...variables, nextToken}, accessToken).then(res => {
      if (res.data?.getGroup != null) {
        setValue(res.data.getGroup.id, res.data.getGroup.name, res.data.getGroup.createdAt, res.data.getGroup.updatedAt)
      }

      return {
        data: res.data?.getGroup.groupMembers.items || [],
        nextToken: res.data?.getGroup.groupMembers.nextToken
      }
    })
  }

  const groupMembers = (await new PaginationReader(reader, 300).readAll())
    .sort((l, r)  => l.teamMember.memberSeq < r.teamMember.memberSeq ? -1 : 1)

  if (groupId == null || groupName == null || createdAt == null || updatedAt == null) throw Error('Could not read some fileds')

  return {
    data: {
      getGroup: {
        id: groupId,
        name: groupName,
        groupMembers: {
          items: groupMembers,
        },
        createdAt,
        updatedAt,
      }
    }
  }
}
export type GetGroupVariables = autoGenAPI.GetGroupQueryVariables
export type GroupMember = {
  leaderFlg: boolean
  teamMember: {
    id: string
    nickname: string
    memberSeq: number
    role: MemberRole
  }
  createdAt: string
  updatedAt: string
}
type GetGroupRawResponse = {
  getGroup: {
    id: string
    name: string
    groupMembers: {
      items: GroupMember[]
      nextToken?: string | null
    }
    createdAt: string
    updatedAt: string
  }
}
export type GetGroupResponse = {
  getGroup: {
    id: string
    name: string
    groupMembers: {
      items: GroupMember[]
    }
    createdAt: string
    updatedAt: string
  }
}


/**
 * 7-4. グループ更新API
 *
 * グループ情報およびグループに所属するメンバーを更新します。メンバーの更新は上書き更新により行います。
 */
export const updateGroup = (accessToken: string, variables: UpdateGroupVariables & AppInfoVariables): Promise<GraphQLResult<UpdateGroupResponse>> => {
  return execGqlRequest<UpdateGroupResponse>(autoGenMutations.updateGroup, variables, accessToken)
}
export type UpdateGroupVariables = {
  input: Omit<autoGenAPI.UpdateGroupMutationVariables['input'], 'teamGroupsId'>
}
export type UpdateGroupResponse = autoGenAPI.UpdateGroupMutation


export const updateNameGroupAndGroupMembers = (accessToken: string, variables: UpdateNameGroupAndGroupMembers & AppInfoVariables): Promise<GraphQLResult<UpdateGroupResponse>> => {
  return execGqlRequest<UpdateGroupResponse>(autoGenMutations.updateNameGroupAndGroupMembers, variables, accessToken)
}

export type UpdateNameGroupAndGroupMembers = {
  input: Omit<autoGenAPI.UpdateNameGroupAndGroupMembersMutationVariables['input'], 'teamGroupsId'>
}


/**
 * 7-5. グループ削除API
 *
 * グループを削除し、グループと団体メンバーの紐づけを削除します。
 */
export const deleteGroup = (accessToken: string, variables: DeleteGroupVariables & AppInfoVariables): Promise<GraphQLResult<DeleteGroupResponse>> => {
  return execGqlRequest<DeleteGroupResponse>(autoGenMutations.deleteGroup, variables, accessToken)
}
export type DeleteGroupVariables = autoGenAPI.DeleteGroupMutationVariables
export type DeleteGroupResponse = autoGenAPI.DeleteGroupMutation

export const listUnreadMail = ((accessToken: string, variables: ListUnreadMailVariables & AppInfoVariables): Promise<GraphQLResult<any>> => {
  const filter: autoGenAPI.SearchableTeamMemberMailFilterInput = {
    teamMemberID: {
      eq: variables.teamMemberId
    },
    receivedFlg: {
      eq: true
    },
    trashedFlgReceived: {
      ne: true
    },
    deletedFlgReceived: {
      ne: true
    },
    deletedFlgSent: {
      ne: true
    },
      or: variables.mailIDs
  }
  const {count, nextToken, appInfo} = variables
  return _listTeamMemberMail(accessToken, { filter, count, nextToken, appInfo })
})
export type MailIDFilter = {
  mailID: {
    eq: string;
  };
}

export type ListUnreadMailVariables = {
  teamMemberId: string,
  mailIDs : MailIDFilter[],
  count?: number 
  nextToken?: string
}
/***
 * 8. 受信済み連絡系
 ***/


/**
 * 8-1. 受信済み連絡一覧API
 *
 * 団体メンバーの受信済み連絡の一覧を送信日時の降順でlimitの件数ずつ取得します。
 */
export const listReceivedMail = (accessToken: string, variables: ListReceivedMailVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamMemberMailResponse>> => {
  const filter: autoGenAPI.SearchableTeamMemberMailFilterInput = {
    teamMemberID: {
      eq: variables.teamMemberId
    },
    receivedFlg: {
      eq: true
    },
    trashedFlgReceived: {
      ne: true
    },
    deletedFlgReceived: {
      ne: true
    },
    
  }

  if (variables.filter?.keyword) filter.and = [{
    or: [
      { title: { wildcard: `*${variables.filter.keyword}*` } },
      { title: { matchPhrase: variables.filter.keyword } },
      { body: { wildcard: `*${variables.filter.keyword}*` } },
      { body: { matchPhrase: variables.filter.keyword } },
    ]
  }]
  if (variables.filter?.mailTypes) filter.and = [
    ...(filter.and || []),
    {
      or: variables.filter.mailTypes.map(mt => ({
        mailType: {
          eq: mt
        }
      }))
    }
  ]
  if (variables.filter?.unanswered) {
    filter.answerRequiredFlg = { eq: true }
    filter.answeredFlg = { eq: false }
    filter.responseDueDatetimeForFilter = { gte: new Date().toISOString() }
  }
  if (variables.filter?.favorite) filter.favoriteFlgReceived = { eq: true }
  if (variables.filter?.hasThread) filter.and = [
    { senderId: { ne: variables.teamMemberId } },
    { hasThread: { eq: true } }
  ]
  if (variables.filter?.hasAttachment) filter.hasAttachment = { eq: true }
  if (variables.filter?.isUnread) filter.readFlg = { ne: true }

  const {count, nextToken, appInfo} = variables
  return _listTeamMemberMail(accessToken, { filter, count, nextToken, appInfo })
}
export type ListReceivedMailVariables = {
  teamMemberId: string,
  filter?: {
    keyword?: string
    unanswered?: boolean
    favorite?: boolean
    hasThread?: boolean
    hasAttachment?: boolean
    isUnread?: boolean
    mailTypes?: MailType[]
  },
  count?: number // 取得件数
  nextToken?: string
}


/**
 * 8-2. 受信済み連絡お気に入り更新API
 *
 * 受信済み連絡のお気に入りフラグを更新します。
 */
export const updateReceivedMailFavoriteFlg = (accessToken: string, variables: UpdateReceivedMailFavoriteFlgVariables & AppInfoVariables): Promise<GraphQLResult<UpdateReceivedMailFavoriteFlgResponse>> => {
  const {input, appInfo} = variables
  const {favoriteFlg, ...key } = input
  const variables_: autoGenAPI.UpdateTeamMemberMailMutationVariables & AppInfoVariables = {
    input: {
      id: 'mailId' in key ? `${key.memberId}.${key.mailId}` : key.teamMemberMailId,
      favoriteFlgReceived: favoriteFlg
    },
    appInfo
  }
  return execGqlRequest<UpdateReceivedMailFavoriteFlgResponse>(customGql.updateTeamMemberMail, variables_, accessToken)
}
export type UpdateReceivedMailFavoriteFlgVariables = {
  input: {
    favoriteFlg: boolean
  } & ({
    mailId: string
    memberId: string
  } | {
    teamMemberMailId: string
  })
}
export type UpdateReceivedMailFavoriteFlgResponse = {
  updateReceivedMail: {
    id: string
  }
}


/**
 * 8-3. 受信済み連絡ゴミ箱移動API
 *
 * 受信済み連絡をゴミ箱に移動します。既読フラグ・お気に入りフラグも引き継がれます。
 */
export const trashReceivedMail = async (accessToken: string, variables: TrashReceivedMailVariables & AppInfoVariables): Promise<GraphQLResult<TrashReceivedMailResponse>> => {
  const {input, appInfo} = variables
  const {mailId, memberId} = input
  const variables_: autoGenAPI.UpdateTeamMemberMailMutationVariables & AppInfoVariables = {
    input: {
      id: `${memberId}.${mailId}`,
      trashedFlgReceived: true
    },
    appInfo,
  }
  return execGqlRequest<TrashReceivedMailResponse>(customGql.updateTeamMemberMail, variables_, accessToken)
}
export type TrashReceivedMailVariables = {
  input: {
    mailId: string
    memberId: string
  }
}
export type TrashReceivedMailResponse = {
  updateTeamMemberMail: {
    id: string
  }
}


/***
 * 9. 連絡登録系
 ***/


/**
 * 9-1. 連絡登録API
 *
 * 連絡を新規作成し、宛先メンバーに送付します。
 * 送信予約日時が登録されている場合は、送信予約連絡として情報を登録します。
 * 下書きIDが指定されている場合は、その下書きIDを削除し、下書きIDと同じIDを持つ連絡を新規作成します。
 */
export const createMail = (accessToken: string, variables: CreateMailVariables & AppInfoVariables): Promise<GraphQLResult<CreateMailResponse>> => {
  return execGqlRequest<CreateMailResponse>(autoGenMutations.createMail, variables, accessToken)
}
export type CreateMailVariables = {
  input: Omit<autoGenAPI.CreateMailMutationVariables['input'], 'mailSenderId'>
}
export type CreateMailResponse = autoGenAPI.CreateMailMutation


/**
 * 9-2. 連絡取得API
 *
 * 連絡の詳細情報を取得します。同時に、既読フラグを更新します。
 */
export const getMail = async (accessToken: string, variables: GetMailVariables & AppInfoVariables): Promise<GraphQLResult<GetMailResponse>> => {
  const {input, appInfo} = variables
  const {memberId, mailId} = input
  const variables_ = {
    teamMemberMailId: `${memberId}.${mailId}`,
    appInfo
  }
  const mail = await execGqlRequest<GetMailRawResponse>(customGql.getMail, variables_, accessToken).then(res => {
    const m = res.data?.getTeamMemberMail?.mail || undefined
    const teamMemberMail = res.data?.getTeamMemberMail || undefined
    return {
      data: teamMemberMail && m && {
        getMail: {
          id: m.id,
          teamId: m.teamId,
          mailType: m.mailType as MailType,
          title: m.title,
          sender: {
            id: m.sender?.id || '',
            nickname: m.sender?.nickname || '',
            deleteFlg: m.sender?.deleteFlg || undefined
          },
          body: m.body,
          sendTo: {
            allMembersFlg: !!m.sendTo.allMembersFlg,
            managersFlg: !!m.sendTo.managersFlg,
            groupIds: m.sendTo.groupIds  || [],
            memberIds: m.sendTo.memberIds || [],
          },
          attachmentFileNames: m.attachmentFileNames || [],
          questionnaires: m.questionnaires?.map(q => ({
            answerSelectType: q?.answerSelectType || AnswerSelectType.single,
            question: q?.question || '',
            choices: q?.choices || []
          })) || undefined,
          candidateDatetimes: m.candidateDatetimes?.map(cd => ({
            startDate: cd?.startDate || '',
            startTime: cd?.startTime || undefined,
            endDate: cd?.endDate || undefined,
            endTime: cd?.endTime || undefined,
          })) || undefined,
          shareManagerFlg: m.shareManagerFlg != null && m.shareManagerFlg > 0,
          showAnswerFlg: !!m.showAnswerFlg,
          responseDueDatetime: m.responseDueDatetime && new Date(m.responseDueDatetime) || undefined,
          remindDatetime: m.remindDatetime && new Date(m.remindDatetime) || undefined,
          sentAt: new Date(m.sentAt),
          createdAt: new Date(m.createdAt),
          updatedAt: new Date(m.updatedAt),
          favoriteFlgReceived: teamMemberMail.favoriteFlgReceived,
          favoriteFlgSent: teamMemberMail.favoriteFlgSent,
          readFlg: teamMemberMail.readFlg,
          attendanceAnswer: teamMemberMail.attendanceAnswer,
          questionnaireAnswers: teamMemberMail.questionnaireAnswers,
          scheduleAnswers: teamMemberMail.scheduleAnswers,
        }
      },
      errors: res.errors,
      extensions: res.extensions,
    }
  })
  if (mail.data && !mail.data.getMail.readFlg) {
    // 既読フラグをonにする
    const markMailAsReadVariables: autoGenAPI.MarkMailAsReadMutationVariables = {
      input: {
        mailId: mailId,
        memberId: memberId,
      }
    }
    await execGqlRequest(autoGenMutations.markMailAsRead, markMailAsReadVariables, accessToken)
  }

  return mail
}
export type GetMailVariables = {
  input: {
    mailId: string
    memberId: string
  }
}
type GetMailRawResponse = {
  getTeamMemberMail?: {
    favoriteFlgReceived: boolean
    favoriteFlgSent: boolean
    readFlg: boolean
    attendanceAnswer: YesNoAnswer
    questionnaireAnswers: {
      answer: number[]
    }[]
    scheduleAnswers: YesNoAnswer[]
    mail: autoGenAPI.GetMailQuery['getMail']
  } | null
}
export type GetMailResponse = {
  getMail: {
    id: string
    teamId: string
    mailType: MailType
    title: string
    sender: {
      id: string
      nickname: string
      deleteFlg?: boolean
    }
    body: string
    sendTo: {
      allMembersFlg: boolean,
      managersFlg: boolean,
      groupIds: string[],
      memberIds: string[],
    }
    attachmentFileNames: string[]
    questionnaires?: {
      answerSelectType: AnswerSelectType
      question: string
      choices: string[]
    }[]
    candidateDatetimes?: {
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }[] | null
    shareManagerFlg: boolean
    showAnswerFlg: boolean
    responseDueDatetime?: Date
    remindDatetime?: Date
    remindExecDatetime?: Date
    sendScheduledAt?: Date
    sentAt: Date
    createdAt: Date
    updatedAt: Date
    favoriteFlgReceived: boolean
    favoriteFlgSent: boolean
    readFlg: boolean
    attendanceAnswer?: YesNoAnswer
    questionnaireAnswers?: {
      answer: number[]
    }[]
    scheduleAnswers?: YesNoAnswer[]
  }
}


/**
 * 9-3. 連絡更新API
 *
 * 連絡を更新し、各受信済メンバーから参照される情報を変更します。
 */
export const updateMail = (accessToken: string, variables: UpdateMailVariables & AppInfoVariables): Promise<GraphQLResult<UpdateMailResponse>> => {
  return execGqlRequest<UpdateMailResponse>(autoGenMutations.updateMail, variables, accessToken)
}
export type UpdateMailVariables = {
  input: Omit<autoGenAPI.UpdateMailMutationVariables['input'], 'mailSenderId'>
}
export type UpdateMailResponse = {
  updateMail: {
    id: string
  }
}


/**
 * 9-4. 連絡削除API
 *
 * 連絡を削除し、各受信済メンバーから参照できなくします。
 * idに送信予約連絡のidが指定された場合は、送信予約連絡を削除します。
 */
export const deleteMail = (accessToken: string, variables: DeleteMailVariables & AppInfoVariables): Promise<GraphQLResult<DeleteMailResponse>> => {
  return execGqlRequest<DeleteMailResponse>(autoGenMutations.deleteMail, variables, accessToken)
}
export type DeleteMailVariables = autoGenAPI.DeleteMailMutationVariables
export type DeleteMailResponse = autoGenAPI.DeleteMailMutation


/**
 * 9-5. 回答催促API
 *
 * 出欠確認、アンケート、日程調整連絡に対して、未回答のメンバーに回答の催促通知を送信します。
 */
export const sendReminder = (accessToken: string, variables: SendReminderVariables & AppInfoVariables): Promise<GraphQLResult<SendReminderResponse>> => {
  return execGqlRequest<SendReminderResponse>(autoGenMutations.sendReminder, variables, accessToken)
}
export type SendReminderVariables = autoGenAPI.SendReminderMutationVariables
export type SendReminderResponse = autoGenAPI.SendReminderMutation


/**
 * 9-6. 仮連絡ID取得API
 *
 * 連絡作成前の、仮連絡IDを取得します。
 * 仮連絡IDは団体メンバー単位(アカウント×団体)に発行され、未発行の場合は新規で発行されたIDを取得します。
 * このIDは、連絡登録API実行時に削除されます。
 */
export const getOrCreateTempMailId = (accessToken: string, variables: GetOrCreateTempMailIdVariables & AppInfoVariables): Promise<GraphQLResult<GetOrCreateTempMailIdResponse>> => {
  return execGqlRequest<GetOrCreateTempMailIdResponse>(autoGenMutations.getOrCreateTempMailId, variables, accessToken)
}
export type GetOrCreateTempMailIdVariables = autoGenAPI.GetOrCreateTempMailIdMutationVariables
export type GetOrCreateTempMailIdResponse = autoGenAPI.GetOrCreateTempMailIdMutation


/***
 * 10. 連絡回答・既読状況系
 ***/

/**
 * 10-1. 出欠確認回答更新API
 *
 * 出欠確認連絡への回答を更新します。
 */
export const updateAttendanceAnswer = (accessToken: string, variables: UpdateAttendanceAnswerVariables & AppInfoVariables): Promise<GraphQLResult<UpdateAttendanceAnswerResponse>> => {
  return execGqlRequest<UpdateAttendanceAnswerResponse>(autoGenMutations.updateAttendanceAnswer, variables, accessToken)
}
export type UpdateAttendanceAnswerVariables = autoGenAPI.UpdateAttendanceAnswerMutationVariables
export type UpdateAttendanceAnswerResponse = autoGenAPI.UpdateAttendanceAnswerMutation

/**
 * 10-2. アンケート回答更新API
 *
 * アンケート連絡への回答を更新します。
 */
export const updateQuestionnaireAnswer = (accessToken: string, variables: UpdateQuestionnaireAnswerVariables & AppInfoVariables): Promise<GraphQLResult<UpdateQuestionnaireAnswerResponse>> => {
  return execGqlRequest<UpdateQuestionnaireAnswerResponse>(autoGenMutations.updateQuestionnaireAnswer, variables, accessToken)
}
export type UpdateQuestionnaireAnswerVariables = autoGenAPI.UpdateQuestionnaireAnswerMutationVariables
export type UpdateQuestionnaireAnswerResponse = autoGenAPI.UpdateQuestionnaireAnswerMutation

/**
 * 10-3. 日程調整回答更新API
 *
 * 日程調整への回答を更新します。
 */
export const updateScheduleAnswer = (accessToken: string, variables: UpdateScheduleAnswerVariables & AppInfoVariables): Promise<GraphQLResult<UpdateScheduleAnswerResponse>> => {
  return execGqlRequest<UpdateScheduleAnswerResponse>(autoGenMutations.updateScheduleAnswer, variables, accessToken)
}
export type UpdateScheduleAnswerVariables = autoGenAPI.UpdateScheduleAnswerMutationVariables
export type UpdateScheduleAnswerResponse = autoGenAPI.UpdateScheduleAnswerMutation

/**
 * 10-4. 団体メンバー連絡一覧API
 *
 * 1つの連絡に対する、受信メンバーに紐づく情報の一覧をメンバー番号順ですべて取得します。
 * 閲覧権限がないメンバー情報は取得不可となります。
 */
export const listReceivedTeamMemberMail = (accessToken: string, variables: ListReceivedTeamMemberMailVariables & AppInfoVariables): Promise<GraphQLResult<ListReceivedTeamMemberMailResponse>> => {
  return execGqlRequest<ListReceivedTeamMemberMailResponse>(autoGenQueries.listReceivedTeamMemberMail, variables, accessToken)
}
export type ListReceivedTeamMemberMailVariables = autoGenAPI.ListReceivedTeamMemberMailQueryVariables
export type ListReceivedTeamMemberMailResponse = autoGenAPI.ListReceivedTeamMemberMailQuery

export const listReservedTeamMemberMail = (accessToken: string, variables: ListReservedTeamMemberMailVariables & AppInfoVariables): Promise<GraphQLResult<ListReservedTeamMemberMailResponse>> => {
  return execGqlRequest<ListReservedTeamMemberMailResponse>(autoGenQueries.listReservedTeamMemberMail, variables, accessToken)
}
export type ListReservedTeamMemberMailVariables = autoGenAPI.ListReservedTeamMemberMailQueryVariables
export type ListReservedTeamMemberMailResponse = autoGenAPI.ListReservedTeamMemberMailQuery
/***
 * 11. 送信済み連絡系
 ***/


/**
 * 11-1. 送信済み連絡一覧API
 *
 * 自身が送信した連絡、自身が管理者の場合は「他の団体管理者の送信履歴に加える」フラグのある連絡の一覧を送信日時の降順でlimitの件数ずつ取得します。
 */
export const listSentMail = (accessToken: string, variables: ListSentMailVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamMemberMailResponse>> => {
  const filter: autoGenAPI.SearchableTeamMemberMailFilterInput = {
    teamMemberID: {
      eq: variables.teamMemberId
    },
    sentFlg: {
      eq: true
    },
    deletedFlgSent: {
      ne: true
    },
  }
  // teamMemberRoleが書き換えらた場合にバックエンド側の認可バリデーションで検出
  if (variables.teamMemberRole !== 'manager') filter.senderId = { eq: variables.teamMemberId }

  if (variables.filter?.keyword) filter.and = [{
    or: [
      { title: { wildcard: `*${variables.filter.keyword}*` } },
      { title: { matchPhrase: variables.filter.keyword } },
      { body: { wildcard: `*${variables.filter.keyword}*` } },
      { body: { matchPhrase: variables.filter.keyword } },
    ]
  }]
  if (variables.filter?.mailTypes) filter.and = [
    ...(filter.and || []),
    {
      or: variables.filter.mailTypes.map(mt => ({
        mailType: {
          eq: mt
        }
      }))
    }
  ]
  if (variables.filter?.favorite) filter.favoriteFlgSent = { eq: true }
  if (variables.filter?.hasThread) filter.and = [
    { senderId: { eq: variables.teamMemberId } },
    { hasThread: { eq: true } }
  ]
  if (variables.filter?.hasAttachment) filter.hasAttachment = { eq: true }

  const {count, nextToken, appInfo} = variables
  return _listTeamMemberMail(accessToken, { filter, count, nextToken, appInfo })
}

type ListSentMailFilter = {
  keyword?: string
  favorite?: boolean
  hasThread?: boolean
  hasAttachment?: boolean
  mailTypes?: MailType[]
}
export type ListSentMailVariables = {
  teamMemberId: string,
  teamMemberRole: MemberRole,
  filter?: ListSentMailFilter,
  count?: number // 取得件数
  nextToken?: string
}


/**
 * 11-2. 送信予約連絡一覧API
 *
 * 送信予約かつ未送信の送信予約連絡の一覧を送信予定日時の降順で全て取得します。
 */
export const listReservedMail = (accessToken: string, variables: ListReservedMailVariables & AppInfoVariables): Promise<GraphQLResult<ListReservedMailResponse>> => {
  const variables_ = {
    teamMemberId: variables.teamMemberId,
    appInfo: variables.appInfo
  }
  return execGqlRequest<ListReservedMailResponse>(customGql.listReservedMail, variables_, accessToken).then(res => {
    if (res.data?.getTeamMember.reservedMails != null) {
      const reservedMails = res.data?.getTeamMember.reservedMails
      const predicate = getReservedMailsPredicate(variables.filter || {})
      reservedMails.items = reservedMails.items.filter(predicate).sort((l, r) => l.sendScheduledAt > r.sendScheduledAt ? -1 : 1)
    }
    return res
  })
}
export type ListReservedMailVariables = {
  teamMemberId: string
  filter?: ListSentMailFilter
}
type ReservedMailItem = {
  id: string
  mailType: MailType
  title: string
  body: string
  attachmentFileNames: string[]
  sendScheduledAt: string
  createdAt: string
  updatedAt: string
}
export type ListReservedMailResponse = {
  getTeamMember: {
    id: string
    nickname: string
    reservedMails: {
      items: ReservedMailItem[]
    }
  }
}
const getReservedMailsPredicate = (filter: ListSentMailFilter) => (reservedMail: ReservedMailItem): boolean => {
  if (filter.keyword && !reservedMail.title.includes(filter.keyword) && !(reservedMail.body.includes(filter.keyword))) return false
  if (filter.favorite) return false
  if (filter.hasThread) return false
  if (filter.hasAttachment && reservedMail.attachmentFileNames.length === 0) return false
  if (filter.mailTypes && filter.mailTypes.length > 0 && !filter.mailTypes.includes(reservedMail.mailType)) return false

  return true
}


/**
 * 11-3. 送信済み連絡お気に入り更新API
 *
 * 送信済み連絡のお気に入りフラグを更新します。
 */
export const updateSentMailFavoriteFlg = (accessToken: string, variables: UpdateSentMailFavoriteFlgVariables & AppInfoVariables): Promise<GraphQLResult<UpdateSentMailFavoriteFlgResponse>> => {
  const {input, appInfo} = variables
  const {mailId, memberId, favoriteFlg} = input
  const variables_: autoGenAPI.UpdateTeamMemberMailMutationVariables & AppInfoVariables = {
    input: {
      id: `${memberId}.${mailId}`,
      favoriteFlgSent: favoriteFlg
    },
    appInfo
  }
  return execGqlRequest<UpdateSentMailFavoriteFlgResponse>(customGql.updateTeamMemberMail, variables_, accessToken)
}
export type UpdateSentMailFavoriteFlgVariables = {
  input: {
    mailId: string
    memberId: string
    favoriteFlg: boolean
  }
}
export type UpdateSentMailFavoriteFlgResponse = {
  updateSentMail: {
    id: string
  }
}


/**
 * 11-4. 送信予約連絡取得API
 *
 * 送信予約連絡の詳細情報を取得します。
 */
export const getReservedMail = async (accessToken: string, variables: GetReservedMailVariables & AppInfoVariables): Promise<GraphQLResult<GetReservedMailResponse>> => {
  return execGqlRequest<GetReservedMailRawResponse>(autoGenQueries.getReservedMail, variables, accessToken).then(res => {
    const {data, ...rest} = res
    if (!data?.getReservedMail) return {
      data: undefined,
      ...rest
    }

    const m = data.getReservedMail
    return {
      data: {
        getReservedMail: {
          id: m.id,
          teamId: m.teamMember?.teamTeamMembersId || "",
          mailType: m.mailType as MailType,
          title: m.title,
          sender: {
            id: m.teamMember?.id || '',
            nickname: m.teamMember?.nickname || '',
          },
          body: m.body,
          sendTo: {
            allMembersFlg: !!m.sendTo.allMembersFlg,
            managersFlg: !!m.sendTo.managersFlg,
            groupIds: m.sendTo.groupIds || [],
            memberIds: m.sendTo.memberIds || [],
          },
          attachmentFileNames: m.attachmentFileNames || [],
          questionnaires: m.questionnaires?.map(q => ({
            answerSelectType: q?.answerSelectType || AnswerSelectType.single,
            question: q?.question || '',
            choices: q?.choices || []
          })) || undefined,
          candidateDatetimes: m.candidateDatetimes?.map(cd => ({
            startDate: cd?.startDate || '',
            startTime: cd?.startTime || undefined,
            endDate: cd?.endDate || undefined,
            endTime: cd?.endTime || undefined,
          })) || undefined,
          shareManagerFlg: m.shareManagerFlg != null && m.shareManagerFlg > 0,
          showAnswerFlg: !!m.showAnswerFlg,
          responseDueDatetime: m.responseDueDatetime && new Date(m.responseDueDatetime) || undefined,
          remindDatetime: m.remindDatetime && new Date(m.remindDatetime) || undefined,
          sendScheduledAt: m.sendScheduledAt && new Date(m.sendScheduledAt) || new Date(),
          createdAt: new Date(m.createdAt),
          updatedAt: new Date(m.updatedAt),
          favoriteFlgReceived: false,
          favoriteFlgSent: false,
          readFlg: false,
        }
      }
    }
  })
}
export type GetReservedMailVariables = autoGenAPI.GetReservedMailQueryVariables
type GetReservedMailRawResponse = autoGenAPI.GetReservedMailQuery
export type GetReservedMailResponse = {
  getReservedMail: Omit<GetMailResponse['getMail'], 'sentAt'> & {
    sendScheduledAt: Date
  }
}


/**
 * 11-5. 送信予約連絡更新API
 *
 * 送信予約連絡を更新します。
 */
export const updateReservedMail = (accessToken: string, variables: UpdateReservedMailVariables & AppInfoVariables): Promise<GraphQLResult<UpdateReservedMailResponse>> => {
  return execGqlRequest<UpdateReservedMailResponse>(customGql.updateReservedMail, variables, accessToken)
}
export type UpdateReservedMailVariables = autoGenAPI.UpdateReservedMailMutationVariables
export type UpdateReservedMailResponse = {
  updateReservedMail: {
    id: string
  }
}


/**
 * 送信予約連絡削除API
 *
 * 送信予約連絡を削除します。すでに連絡が送信されてしまった場合はエラーとなります。
 */
export const deleteReservedMail = (accessToken: string, variables: DeleteReservedMailVariables & AppInfoVariables): Promise<GraphQLResult<DeleteReservedMailResponse>> => {
  return execGqlRequest<DeleteReservedMailResponse>(customGql.deleteReservedMail, variables, accessToken)
}
export type DeleteReservedMailVariables = {
  input: {
    id: string
  }
}
export type DeleteReservedMailResponse = {
  deleteReservedMail: {
    id: string
  }
}


/**
 * 11-6. 送信予約連絡削除API
 *
 * 1団体メンバーに紐づく送信予約連絡を削除します。
 */
export const deleteSentMail = async (accessToken: string, variables: DeleteSentMailVariables & AppInfoVariables): Promise<GraphQLResult<DeleteSentMailResponse>> => {
  const {input, appInfo} = variables
  const {mailId, memberId} = input
  const variables_: autoGenAPI.UpdateTeamMemberMailMutationVariables & AppInfoVariables = {
    input: {
      id: `${memberId}.${mailId}`,
      deletedFlgSent: true
    },
    appInfo
  }
  return execGqlRequest<DeleteSentMailResponse>(customGql.updateTeamMemberMail, variables_, accessToken)
}
export type DeleteSentMailVariables = {
  input: {
    mailId: string
    memberId: string
  }
}
export type DeleteSentMailResponse = {
  updateTeamMemberMail: {
    id: string
  }
}


/**
 * 11-7. 送信済み連絡一括削除API
 *
 * 指定した送信済み連絡を一括で削除します。
 */
export const multiDeleteSentMail = (accessToken: string, variables: MultiDeleteSentMailVariables & AppInfoVariables): Promise<GraphQLResult<MultiDeleteSentMailResponse>> => {
  return execGqlRequest<MultiDeleteSentMailResponse>(autoGenMutations.multiDeleteSentMail, variables, accessToken)
}
export type MultiDeleteSentMailVariables = autoGenAPI.MultiDeleteSentMailMutationVariables
export type MultiDeleteSentMailResponse = autoGenAPI.MultiDeleteSentMailMutation


/**
 * 送信済み連絡・送信予約連絡一覧API
 *
 * 送信済み連絡及び送信予約連絡の一覧を送信予定/送信日時の降順でlimitの件数ずつして取得します。
 * 送信予約連絡については、全て取得します。
 */
export const listSentAndReservedMail = async (accessToken: string, variables: ListSentAndReservedMailVariables & AppInfoVariables): Promise<GraphQLResult<ListSentAndReservedMailResponse>> => {
  let reservedMails: ListSentAndReservedMailItem[]
  let sentMailsResult: GraphQLResult<ListTeamMemberMailResponse>
  if (variables.nextToken == null) {
    const [reservedMailsResult, _sentMailsResult] = await Promise.all([
      listReservedMail(accessToken, {teamMemberId: variables.teamMemberId, filter: variables.filter, appInfo: variables.appInfo}),
      listSentMail(accessToken, variables)
    ])
    sentMailsResult = _sentMailsResult

    if ((reservedMailsResult.errors || []).length > 0 || (sentMailsResult.errors || []).length > 0) return {
      data: undefined,
      errors: [...(reservedMailsResult.errors || []), ...(sentMailsResult.errors || [])]
    }

    if (reservedMailsResult.data?.getTeamMember == null || sentMailsResult.data?.searchTeamMemberMails == null) return {
      data: undefined,
      errors: []
    }

    const getTeamMember = reservedMailsResult.data?.getTeamMember
    const sender = {id: getTeamMember.id, nickname: getTeamMember.nickname}
    reservedMails = reservedMailsResult.data.getTeamMember.reservedMails.items
      .map(item => convertReservedMail(sender, item))
  } else {
    reservedMails = []
    sentMailsResult = await listSentMail(accessToken, variables)
  }

  const sentMails: ListSentAndReservedMailItem[] = sentMailsResult.data?.searchTeamMemberMails.items.map(item => ({
    isReserved: false,
    ...item
  })) || []

  return {
    data: {
      searchTeamMemberMails: {
        items: [...reservedMails, ...sentMails],
        total: sentMailsResult.data?.searchTeamMemberMails.total,
        nextToken: sentMailsResult.data?.searchTeamMemberMails.nextToken
      },
    }
  }
}

const convertReservedMail = (sender: {id: string, nickname: string}, reservedMail: ReservedMailItem): ListSentAndReservedMailItem => {
  return {
    isReserved: true,
    id: reservedMail.id,
    readFlg: false,
    favoriteFlgReceived: false,
    favoriteFlgSent: false,
    mail: {
      id: reservedMail.id,
      mailType: reservedMail.mailType,
      title: reservedMail.title,
      sender: sender,
      attachmentFileNames: reservedMail.attachmentFileNames,
      sentAt: undefined,
      sendScheduledAt: reservedMail.sendScheduledAt,
      responseDueDatetime: undefined,
      createdAt: reservedMail.createdAt,
      updatedAt: reservedMail.updatedAt,
    }
  }
}
export type ListSentAndReservedMailVariables = ListSentMailVariables
type ListSentAndReservedMailItem = {
  isReserved: boolean
} & Omit<ListTeamMemberMailItem, 'mail'> & {
  mail: Omit<ListTeamMemberMailItem['mail'], 'sentAt'> & {
    sentAt?: string
    sendScheduledAt?: string
  }
}
export type ListSentAndReservedMailResponse = {
  searchTeamMemberMails: {
    total?: number | null
    items: ListSentAndReservedMailItem[]
    nextToken?: string
  }
}


/***
 * 12. ゴミ箱系
 ***/


/**
 * 12-1. ゴミ箱一覧API
 *
 * ゴミ箱内連絡の一覧を送信日時の降順でlimitの件数ずつ取得します。
 */
export const listTrashedMail = (accessToken: string, variables: ListTrashedMailVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamMemberMailResponse>> => {
  const filter: autoGenAPI.SearchableTeamMemberMailFilterInput = {
    teamMemberID: {
      eq: variables.teamMemberId
    },
    trashedFlgReceived: {
      eq: true
    },
    deletedFlgReceived: {
      ne: true
    },
  }

  const {count, nextToken, appInfo} = variables
  return _listTeamMemberMail(accessToken, { filter, count, nextToken, appInfo })
}
export type ListTrashedMailVariables = {
  teamMemberId: string,
  count?: number // 取得件数
  nextToken?: string
}

const _listTeamMemberMail = (accessToken: string, variables: ListTeamMemberMailVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamMemberMailResponse>> => {
  const variables_ = {
    filter: variables.filter,
    count: variables.count || 30,
    nextToken: variables.nextToken,
    appInfo: variables.appInfo,
  }
  return execGqlRequest<ListTeamMemberMailResponse>(customGql.listTeamMemberMail, variables_, accessToken)
}

type ListTeamMemberMailVariables = {
  filter: autoGenAPI.SearchableTeamMemberMailFilterInput
  count?: number
  nextToken?: string
}

type ListTeamMemberMailItem = {
  id: string
  readFlg: boolean
  favoriteFlgReceived: boolean
  favoriteFlgSent: boolean
  answeredFlg?: boolean | null
  answerRequiredFlg?: boolean | null
  mail: {
    id: string
    mailType: MailType
    title: string
    sender: {
      id: string
      nickname: string
      deleteFlg?: boolean
    }
    attachmentFileNames?: string[] | null
    sentAt: string
    responseDueDatetime?: string | null
    createdAt: string
    updatedAt: string
  }
}

export type ListTeamMemberMailResponse = {
  searchTeamMemberMails: {
    total?: number | null
    items: ListTeamMemberMailItem[]
    nextToken?: string
  }
}


export const listTeamMemberMailByAccount = (accessToken: string, variables: ListTeamMemberMailByAccountVariables & AppInfoVariables): Promise<GraphQLResult<ListTeamMemberMailByAccountResponse>> => {
  const variables_ = {
    input : {
      limit: variables.limit || 3,
    }
  }
  return execGqlRequest<ListTeamMemberMailByAccountResponse>(customGql.listTeamMemberMailByAccount, variables_, accessToken)
}

type ListTeamMemberMailByAccountVariables = {
  limit: number
}

type ListTeamMemberMailByAccountItem = {
  id: string
  readFlg: boolean
  favoriteFlgReceived: boolean
  favoriteFlgSent: boolean
  answeredFlg?: boolean | null
  answerRequiredFlg?: boolean | null
  mail: {
    id: string
    mailType: MailType
    title: string
    sender: {
      id: string
      nickname: string
      deleteFlg?: boolean
    }
    attachmentFileNames?: string[] | null
    sentAt: string
    responseDueDatetime?: string | null
    createdAt: string
    updatedAt: string
  }
}

export type ListTeamMemberMailByAccountResponse = {
  listTeamMemberMailByAccount: {
    accountId: string
    teamMemberMailByAccount: TeamMemberMailByAccountResponse[]
  }
}

type TeamMemberMailByAccountResponse = {
    teamId: string
    teamMemberMail: ListTeamMemberMailByAccountItem[]
}


/**
 * 12-2. ゴミ箱復元API
 *
 * ゴミ箱内の連絡を受信済み連絡に戻します。既読フラグ・お気に入りフラグも引き継がれます。
 */
export const restoreTrashedMail = async (accessToken: string, variables: RestoreTrashedMailVariables & AppInfoVariables): Promise<GraphQLResult<RestoreTrashedMailResponse>> => {
  const {input, appInfo} = variables
  const {mailId, memberId} = input
  const variables_: autoGenAPI.UpdateTeamMemberMailMutationVariables & AppInfoVariables = {
    input: {
      id: `${memberId}.${mailId}`,
      trashedFlgReceived: false
    },
    appInfo,
  }
  return execGqlRequest<RestoreTrashedMailResponse>(customGql.updateTeamMemberMail, variables_, accessToken)
}
export type RestoreTrashedMailVariables = {
  input: {
    mailId: string
    memberId: string
  }
}
export type RestoreTrashedMailResponse = {
  updateTeamMemberMail: {
    id: string
  }
}


/**
 * 12-3. ゴミ箱削除API
 *
 * 受信済み連絡のゴミ箱内の1連絡を削除します。
 */
export const deleteTrashedMail = async (accessToken: string, variables: DeleteTrashedMailVariables & AppInfoVariables): Promise<GraphQLResult<DeleteTrashedMailResponse>> => {
  const {input, appInfo} = variables
  const {mailId, memberId} = input
  const variables_: autoGenAPI.UpdateTeamMemberMailMutationVariables & AppInfoVariables = {
    input: {
      id: `${memberId}.${mailId}`,
      deletedFlgReceived: true
    },
    appInfo
  }
  return execGqlRequest<DeleteTrashedMailResponse>(customGql.updateTeamMemberMail, variables_, accessToken)
}
export type DeleteTrashedMailVariables = {
  input: {
    mailId: string
    memberId: string
  }
}
export type DeleteTrashedMailResponse = {
  updateTeamMemberMail: {
    id: string
  }
}


/**
 * 12-4. 連絡一括ゴミ箱移動API
 *
 * 指定した受信済み連絡を一括でゴミ箱に移動します。
 */
export const multiTrashReceivedMail = (accessToken: string, variables: MultiTrashReceivedMailVariables & AppInfoVariables): Promise<GraphQLResult<MultiTrashReceivedMailResponse>> => {
  return execGqlRequest<MultiTrashReceivedMailResponse>(autoGenMutations.multiTrashReceivedMail, variables, accessToken)
}
export type MultiTrashReceivedMailVariables = autoGenAPI.MultiTrashReceivedMailMutationVariables
export type MultiTrashReceivedMailResponse = autoGenAPI.MultiTrashReceivedMailMutation


/**
 * 12-5. ゴミ箱一括復元API
 *
 * 指定したゴミ箱を一括で受信済み連絡に復元します。
 */
export const multiRestoreTrashedMail = (accessToken: string, variables: MultiRestoreTrashedMailVariables & AppInfoVariables): Promise<GraphQLResult<MultiRestoreTrashedMailResponse>> => {
  return execGqlRequest<MultiRestoreTrashedMailResponse>(autoGenMutations.multiRestoreTrashedMail, variables, accessToken)
}
export type MultiRestoreTrashedMailVariables = autoGenAPI.MultiRestoreTrashedMailMutationVariables
export type MultiRestoreTrashedMailResponse = autoGenAPI.MultiRestoreTrashedMailMutation


/**
 * 12-6. ゴミ箱一括削除API
 *
 * 指定したゴミ箱を一括で削除します。
 */
export const multiDeleteTrashedMail = (accessToken: string, variables: MultiDeleteTrashedMailVariables & AppInfoVariables): Promise<GraphQLResult<MultiDeleteTrashedMailResponse>> => {
  return execGqlRequest<MultiDeleteTrashedMailResponse>(autoGenMutations.multiDeleteTrashedMail, variables, accessToken)
}
export type MultiDeleteTrashedMailVariables = autoGenAPI.MultiDeleteTrashedMailMutationVariables
export type MultiDeleteTrashedMailResponse = autoGenAPI.MultiDeleteTrashedMailMutation



/***
 * 13. マスタ系
 ***/

/**
 * 13-1. 職業マスタ一覧API
 *
 * 職業マスタの一覧を取得します。
 */
export const listJobMaster = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<ListJobMasterResponse>> => {
  return execGqlRequest<ListJobMasterResponse>(customGql.listJobMaster, variables || {}, accessToken).then(res => {
    (res?.data?.listJobMaster.items || []).sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    return res
  })
}
export type ListJobMasterResponse = {
  listJobMaster: {
    items: {
      id: string
      label: string
      type: string
      displayNumber: number
      displayFlg: number
    }[]
  }
}

/**
 * 13-2. 学校マスタ一覧API
 *
 * 学校マスタの一覧を取得します。
 */
export const listSchoolMaster = async (accessToken: string, variables: ListSchoolMasterVariable & AppInfoVariables): Promise<ListSchoolMasterResponse> => {
  const {input, appInfo} = variables
  const getPartialList = (nextToken?: string): Promise<{ items: SchoolMaster[], nextToken?: string | null }> => execGqlRequest<ListSchoolMasterRowResponse>(
    customGql.listSchoolMaster,
    {...input, nextToken, appInfo},
    accessToken
  ).then(res => res?.data?.getSchoolMasterByType || {items: []})

  let {items: result, nextToken} = await getPartialList()
  while (nextToken) {
    const {items, nextToken: next} = await getPartialList(nextToken)
    result = result.concat(items.filter(i => i.displayFlg === 1))
    nextToken = next
  }

  return {
    data: {
      listSchoolMaster: {
        items: result.sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
      }
    }
  }
}
export type ListSchoolMasterVariable = {
  input: {
    type: string
  }
}
type SchoolMaster = {
  id: string
  label: string
  labelKana: string
  displayNumber: number
  displayFlg: number
}
type ListSchoolMasterRowResponse = {
  getSchoolMasterByType: {
    items: SchoolMaster[],
    nextToken?: string | null
  }
}
export type ListSchoolMasterResponse = {
  data: {
    listSchoolMaster: {
      items: SchoolMaster[]
    }
  }
}

/**
 * 13-3. 学部マスタ一覧API
 *
 * 学部マスタの一覧を取得します。
 */
export const listSchoolDepartmentMaster = (accessToken: string, variables: ListSchoolDepartmentMasterVariables & AppInfoVariables): Promise<GraphQLResult<ListSchoolDepartmentMasterResponse>> => {
  return execGqlRequest<ListSchoolDepartmentMasterResponse>(customGql.listSchoolDepartmentMaster, variables, accessToken).then(res => {
    (res?.data?.getSchoolMaster.departments.items || []).sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    return res
  })
}
export type ListSchoolDepartmentMasterVariables = {
  schoolId: string
}
export type ListSchoolDepartmentMasterResponse = {
  getSchoolMaster: {
    departments: {
      items: {
        id: string
        label: string
        displayNumber: number
        displayFlg: number
      }[]
    }
  }
}

/**
 * 13-4. 都道府県マスタ一覧API
 *
 * 都道府県マスタの一覧を取得します。
 */
export const listPrefectureMaster = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<ListPrefectureMasterResponse>> => {
  return execGqlRequest<ListPrefectureMasterResponse>(customGql.listPrefectureMaster, variables || {}, accessToken).then(res => {
    (res?.data?.listPrefectureMaster.items || []).sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    return res
  })
}
export type ListPrefectureMasterResponse = {
  listPrefectureMaster: {
    items: {
      id: string
      label: string
      displayNumber: number
      displayFlg: number
    }[]
  }
}

/**
 * 13-5. 市区町村マスタ一覧API
 *
 * 市区町村マスタの一覧を取得します。
 */
export const listCityMaster = (accessToken: string, variables: ListCityMasterVariables & AppInfoVariables): Promise<GraphQLResult<ListCityMasterResponse>> => {
  return execGqlRequest<ListCityMasterResponse>(customGql.listCityMaster, variables, accessToken).then(res => {
    (res?.data?.getPrefectureMaster.cities.items || []).sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    return res
  })
}
export type ListCityMasterVariables = {
  prefectureId: string
}
export type ListCityMasterResponse = {
  getPrefectureMaster: {
    cities: {
      items: {
        id: string
        label: string
        displayNumber: number
        displayFlg: number
      }[]
    }
  }
}

/**
 * 13-6. 団体カテゴリマスタ一覧API
 *
 * 団体カテゴリマスタの一覧を取得します。
 */
export const listTeamCategoryMaster = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<ListTeamCategoryMasterResponse>> => {
  return execGqlRequest<ListTeamCategoryMasterResponse>(customGql.listTeamCategoryMaster, variables || {}, accessToken).then(res => {
    const teamCategories = res?.data?.listTeamCategoryMaster.items || []
    teamCategories.sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    teamCategories.forEach(c => {
      c.activities.items.sort((l, r) => l.teamActivityMaster.displayNumber > r.teamActivityMaster.displayNumber ? 1 : -1)
    })

    return res
  })
}
export type ListTeamCategoryMasterResponse = {
  listTeamCategoryMaster: {
    items: {
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities: {
        items: {
          teamActivityMaster: {
            id: string
            label: string
            displayNumber: number
            displayFlg: number
          }
        }[]
      }
    }[]
  }
}

/**
 * 13-7. アカウント質問マスタ一覧API
 *
 * アカウント質問マスタの一覧を取得します。
 */
export const listAccountQuestionMaster = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<ListAccountQuestionMasterResponse>> => {
  return execGqlRequest<ListAccountQuestionMasterResponse>(customGql.listAccountQuestionMaster, variables || {}, accessToken).then(res => {
    const questions = res?.data?.listAccountQuestionMaster.items || []
    questions.sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    questions.forEach(q => {
      q.choices.items.sort((l, r) => l.displayNumber > r.displayNumber ? 1 : -1)
    })

    return res
  })
}
export type ListAccountQuestionMasterResponse = {
  listAccountQuestionMaster: {
    items: {
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      choices: {
        items: {
          id: string
          label: string
          displayNumber: number
          displayFlg: number
        }[]
      }
    }[]
  }
}


/***
 * 14. ファイル系
 ***/


/**
 * 14-1. アカウント画像取得用URL取得API
 *
 * アカウント画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 * accountIdに対する画像が登録されていな場合は、デフォルト画像のURLを取得します。
 * デフォルト画像保存先のキー:
 * 通常:   default/account/image/profile.png
 * サムネ: default/account/image/profile.thumbnail.png
 */
export const getAccountImageGetUrl = (accessToken: string, variables: GetAccountImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetAccountImageGetUrlResponse>> => {
  return execGqlRequest<GetAccountImageGetUrlResponse>(autoGenQueries.getAccountImageGetUrl, variables, accessToken)
}
export type GetAccountImageGetUrlVariables = autoGenAPI.GetAccountImageGetUrlQueryVariables
export type GetAccountImageGetUrlResponse = autoGenAPI.GetAccountImageGetUrlQuery


/**
 * 14-2. アカウント画像保存用URL取得API
 *
 * アカウント画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * 通常:   account/${accountId}/image/profile.${extension}
 * サムネ: account/${accountId}/image/profile_thumbnail.${extension}
 * ※拡張子違いで複数のファイルが保存されている場合は、取得時に最新のファイルを取得
 */
export const getAccountImagePutUrl = (accessToken: string, variables: GetAccountImagePutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetAccountImagePutUrlResponse>> => {
  return execGqlRequest<GetAccountImagePutUrlResponse>(autoGenQueries.getAccountImagePutUrl, variables, accessToken)
}
export type GetAccountImagePutUrlVariables = autoGenAPI.GetAccountImagePutUrlQueryVariables
export type GetAccountImagePutUrlResponse = autoGenAPI.GetAccountImagePutUrlQuery


/**
 * 14-3. アカウント画像削除API
 *
 * アカウント画像を削除します。
 */
export const deleteAccountImage = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<DeleteAccountImageResponse>> => {
  return execGqlRequest<DeleteAccountImageResponse>(autoGenMutations.deleteAccountImage, variables || {}, accessToken)
}
export type DeleteAccountImageResponse = autoGenAPI.DeleteAccountImageMutation


/**
 * 14-4. 団体画像取得用URL取得API
 *
 * 団体画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 * teamIdが未指定、またはteamIdに対する画像が登録されていな場合は、デフォルト画像のURLを取得します。
 * デフォルト画像保存先のキー:
 * 通常:   default/team/image/profile.jpg
 * サムネ: default/team/image/profile.thumbnail.jpg
 */
export const getTeamImageGetUrl = (accessToken: string, variables: GetTeamImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamImageGetUrlResponse>> => {
  return execGqlRequest<GetTeamImageGetUrlResponse>(autoGenQueries.getTeamImageGetUrl, variables, accessToken)
}
export type GetTeamImageGetUrlVariables = autoGenAPI.GetTeamImageGetUrlQueryVariables
export type GetTeamImageGetUrlResponse = autoGenAPI.GetTeamImageGetUrlQuery


export const multiTeamImageGetUrl = (accessToken: string, variables: MultiTeamImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<MultiTeamImageGetUrlResponse>> => {
  const input: autoGenAPI.MultiTeamImageGetUrlInput = {
    teamImages: variables.input.teamImages
  }
  const { appInfo } = variables
  return execGqlRequest<MultiTeamImageGetUrlResponse>(autoGenQueries.multiTeamImageGetUrl, { input, appInfo }, accessToken)
}
export type MultiTeamImageGetUrlVariables = autoGenAPI.MultiTeamImageGetUrlQueryVariables
export type MultiTeamImageGetUrlResponse = autoGenAPI.MultiTeamImageGetUrlQuery


/**
 * 14-5. 団体画像保存用URL取得API
 *
 * 団体画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * 通常:   team/${teamId}/image/profile.${extension}
 * サムネ: team/${teamId}/image/profile.thumbnail.${extension}
 * ※拡張子違いで複数のファイルが保存されている場合は、取得時に最新のファイルを取得
 */
export const getTeamImagePutUrl = (accessToken: string, variables: GetTeamImagePutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamImagePutUrlResponse>> => {
  return execGqlRequest<GetTeamImagePutUrlResponse>(autoGenQueries.getTeamImagePutUrl, variables, accessToken)
}
export type GetTeamImagePutUrlVariables = autoGenAPI.GetTeamImagePutUrlQueryVariables
export type GetTeamImagePutUrlResponse = autoGenAPI.GetTeamImagePutUrlQuery


/**
 * 14-6. 団体画像削除API
 *
 * 団体画像を削除します。
 */
export const deleteTeamImage = (accessToken: string, variables: DeleteTeamImageVariables & AppInfoVariables): Promise<GraphQLResult<DeleteTeamImageResponse>> => {
  return execGqlRequest<DeleteTeamImageResponse>(autoGenMutations.deleteTeamImage, variables, accessToken)
}
export type DeleteTeamImageVariables = autoGenAPI.DeleteTeamImageMutationVariables
export type DeleteTeamImageResponse = autoGenAPI.DeleteTeamImageMutation


/**
 * 14-7. 団体メンバー画像取得用URL取得API
 *
 * 団体メンバー画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 * teamId・teamMemberIdが未指定、またはteamId・teamMemberIdに対する画像が登録されていな場合は、デフォルト画像のURLを取得します。
 * デフォルト画像保存先のキー:
 * 通常:   default/team/member/image/profile.jpg
 * サムネ: default/team/member/image/profile.thumbnail.jpg
 */
export const getTeamMemberImageGetUrl = (accessToken: string, variables: GetTeamMemberImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamMemberImageGetUrlResponse>> => {
  return execGqlRequest<GetTeamMemberImageGetUrlResponse>(autoGenQueries.getTeamMemberImageGetUrl, variables, accessToken)
}
export type GetTeamMemberImageGetUrlVariables = autoGenAPI.GetTeamMemberImageGetUrlQueryVariables
export type GetTeamMemberImageGetUrlResponse = autoGenAPI.GetTeamMemberImageGetUrlQuery

export const multiTeamMemberImageGetUrl = (accessToken: string, variables: multiTeamMemberImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<multiTeamMemberImageGetUrlResponse>> => {
  return execGqlRequest<multiTeamMemberImageGetUrlResponse>(autoGenQueries.multiTeamMemberImageGetUrl, variables, accessToken)
}
export type multiTeamMemberImageGetUrlVariables = autoGenAPI.MultiTeamMemberImageGetUrlQueryVariables
export type multiTeamMemberImageGetUrlResponse = autoGenAPI.MultiTeamMemberImageGetUrlQuery

/**
 * 14-8. 団体メンバー画像保存用URL取得API
 *
 * @deprecated 団体メンバー画像はアカウント画像を使用するため、本APIは使用しない
 * 団体メンバー画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * 通常:   team/${teamId}/member/${teamMemberId}/image/profile.${extension}
 * サムネ: team/${teamId}/member/${teamMemberId}/image/profile.thumbnail.${extension}
 * ※拡張子違いで複数のファイルが保存されている場合は、取得時に最新のファイルを取得
 */
export const getTeamMemberImagePutUrl = (accessToken: string, variables: GetTeamMemberImagePutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamMemberImagePutUrlResponse>> => {
  return execGqlRequest<GetTeamMemberImagePutUrlResponse>(autoGenQueries.getTeamMemberImagePutUrl, variables, accessToken)
}
export type GetTeamMemberImagePutUrlVariables = autoGenAPI.GetTeamMemberImagePutUrlQueryVariables
export type GetTeamMemberImagePutUrlResponse = autoGenAPI.GetTeamMemberImagePutUrlQuery


/**
 * @deprecated 団体メンバー画像はアカウント画像を使用するため、本APIは使用しない
 *
 * 団体メンバー画像削除API
 *
 * 団体メンバー画像を削除します。
 */
export const deleteTeamMemberImage = (accessToken: string, variables: DeleteTeamMemberImageVariables & AppInfoVariables): Promise<GraphQLResult<DeleteTeamMemberImageResponse>> => {
  return execGqlRequest<DeleteTeamMemberImageResponse>(autoGenMutations.deleteTeamMemberImage, variables, accessToken)
}
export type DeleteTeamMemberImageVariables = autoGenAPI.DeleteTeamMemberImageMutationVariables
export type DeleteTeamMemberImageResponse = autoGenAPI.DeleteTeamMemberImageMutation


/**
 * 14-9. 連絡添付ファイル取得用URL取得API
 *
 * 連絡添付ファイル取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、ファイルを取得します。
 */
export const getMailAttachmentGetUrl = (accessToken: string, variables: GetMailAttachmentGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMailAttachmentGetUrlResponse>> => {
  return execGqlRequest<GetMailAttachmentGetUrlResponse>(autoGenQueries.getMailAttachmentGetUrl, variables, accessToken)
}
export type GetMailAttachmentGetUrlVariables = autoGenAPI.GetMailAttachmentGetUrlQueryVariables
export type GetMailAttachmentGetUrlResponse = autoGenAPI.GetMailAttachmentGetUrlQuery


/**
 * 14-10. 連絡添付ファイル保存用URL取得API
 *
 * 連絡添付ファイル保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * team/${teamId}/mail/${mailId}/attachments/${index}/${fileName}
 * ※ {i}は添付ファイル数に応じた0始まりのインデックス(同じファイル名のケースを考慮)
 */
export const getMailAttachmentPutUrl = (accessToken: string, variables: GetMailAttachmentPutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMailAttachmentPutUrlResponse>> => {
  return execGqlRequest<GetMailAttachmentPutUrlResponse>(autoGenQueries.getMailAttachmentPutUrl, variables, accessToken)
}
export type GetMailAttachmentPutUrlVariables = autoGenAPI.GetMailAttachmentPutUrlQueryVariables
export type GetMailAttachmentPutUrlResponse = autoGenAPI.GetMailAttachmentPutUrlQuery


/**
 * 14-11. 連絡添付サムネ画像取得用URL取得API
 *
 * 連絡添付ファイルのサムネイル画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 */
export const getMailAttachmentThumbnailGetUrl = (accessToken: string, variables: GetMailAttachmentThumbnailGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMailAttachmentThumbnailGetUrlResponse>> => {
  return execGqlRequest<GetMailAttachmentThumbnailGetUrlResponse>(autoGenQueries.getMailAttachmentThumbnailGetUrl, variables, accessToken)
}
export type GetMailAttachmentThumbnailGetUrlVariables = autoGenAPI.GetMailAttachmentThumbnailGetUrlQueryVariables
export type GetMailAttachmentThumbnailGetUrlResponse = autoGenAPI.GetMailAttachmentThumbnailGetUrlQuery


/**
 * 14-12. 連絡添付サムネ画像保存用URL取得API
 *
 * 連絡添付ファイルのサムネイル画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * team/${teamId}/mail/${mailId}/attachments/${index}/thumbnail.${extension}
 * ※ {extension}は添付ファイルの拡張子と同じ
 */
export const getMailAttachmentThumbnailPutUrl = (accessToken: string, variables: GetMailAttachmentThumbnailPutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMailAttachmentThumbnailPutUrlResponse>> => {
  return execGqlRequest<GetMailAttachmentThumbnailPutUrlResponse>(autoGenQueries.getMailAttachmentThumbnailPutUrl, variables, accessToken)
}
export type GetMailAttachmentThumbnailPutUrlVariables = autoGenAPI.GetMailAttachmentThumbnailPutUrlQueryVariables
export type GetMailAttachmentThumbnailPutUrlResponse = autoGenAPI.GetMailAttachmentThumbnailPutUrlQuery


/**
 * 14-13. 団体マッチング画像取得用URL取得API
 *
 * 団体マッチング画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 * teamIdが未指定、またはteamIdに対する画像が登録されていな場合は、デフォルト画像のURLを取得します。
 * デフォルト画像保存先のキー:
 * 通常:   default/team/image/matching.png
 * サムネ: default/team/image/matching_thumbnail.png
 */
export const getMatchingTeamImageGetUrl = (accessToken: string, variables: GetMatchingTeamImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingTeamImageGetUrlResponse>> => {
  return execGqlRequest<GetMatchingTeamImageGetUrlResponse>(autoGenQueries.getMatchingTeamImageGetUrl, variables, accessToken)
}
export type GetMatchingTeamImageGetUrlVariables = autoGenAPI.GetMatchingTeamImageGetUrlQueryVariables
export type GetMatchingTeamImageGetUrlResponse = autoGenAPI.GetMatchingTeamImageGetUrlQuery


/**
 * 14-14. 団体マッチング画像保存用URL取得API
 *
 * 団体マッチング画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * 通常:   team/${teamId}/image/matching.${extension}
 * サムネ: team/${teamId}/image/matching_thumbnail.${extension}
 * ※拡張子違いで複数のファイルが保存されている場合は、取得時に最新のファイルを取得
 */
export const getMatchingTeamImagePutUrl = (accessToken: string, variables: GetMatchingTeamImagePutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingTeamImagePutUrlResponse>> => {
  return execGqlRequest<GetMatchingTeamImagePutUrlResponse>(autoGenQueries.getMatchingTeamImagePutUrl, variables, accessToken)
}
export type GetMatchingTeamImagePutUrlVariables = autoGenAPI.GetMatchingTeamImagePutUrlQueryVariables
export type GetMatchingTeamImagePutUrlResponse = autoGenAPI.GetMatchingTeamImagePutUrlQuery


/**
 * 14-15. 団体マッチング画像削除API
 *
 * 団体マッチング画像を削除します。
 */
export const deleteMatchingTeamImage = (accessToken: string, variables: DeleteMatchingTeamImageVariables & AppInfoVariables): Promise<GraphQLResult<DeleteMatchingTeamImageResponse>> => {
  return execGqlRequest<DeleteMatchingTeamImageResponse>(autoGenMutations.deleteMatchingTeamImage, variables, accessToken)
}
export type DeleteMatchingTeamImageVariables = autoGenAPI.DeleteMatchingTeamImageMutationVariables
export type DeleteMatchingTeamImageResponse = autoGenAPI.DeleteMatchingTeamImageMutation


/**
 * 14-16. 団体マッチング背景画像取得用URL取得API
 *
 * 団体マッチング背景画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 * teamIdが未指定、またはteamIdに対する画像が登録されていな場合は、デフォルト画像のURLを取得します。
 * デフォルト画像保存先のキー:
 * 通常:   default/team/image/matching_bg.jpg
 */
export const getMatchingTeamBgImageGetUrl = (accessToken: string, variables: GetMatchingTeamBgImageGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingTeamBgImageGetUrlResponse>> => {
  return execGqlRequest<GetMatchingTeamBgImageGetUrlResponse>(autoGenQueries.getMatchingTeamBgImageGetUrl, variables, accessToken)
}
export type GetMatchingTeamBgImageGetUrlVariables = autoGenAPI.GetMatchingTeamBgImageGetUrlQueryVariables
export type GetMatchingTeamBgImageGetUrlResponse = autoGenAPI.GetMatchingTeamBgImageGetUrlQuery


/**
 * 14-17. 団体マッチング背景画像保存用URL取得API
 *
 * 団体マッチング背景画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * 通常:   team/${teamId}/image/matching.${extension}
 * ※拡張子違いで複数のファイルが保存されている場合は、取得時に最新のファイルを取得
 */
export const getMatchingTeamBgImagePutUrl = (accessToken: string, variables: GetMatchingTeamBgImagePutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingTeamBgImagePutUrlResponse>> => {
  return execGqlRequest<GetMatchingTeamBgImagePutUrlResponse>(autoGenQueries.getMatchingTeamBgImagePutUrl, variables, accessToken)
}
export type GetMatchingTeamBgImagePutUrlVariables = autoGenAPI.GetMatchingTeamBgImagePutUrlQueryVariables
export type GetMatchingTeamBgImagePutUrlResponse = autoGenAPI.GetMatchingTeamBgImagePutUrlQuery


/**
 * 14-18. 団体マッチング背景画像削除API
 *
 * 団体マッチング背景画像を削除します。
 */
export const deleteMatchingTeamBgImage = (accessToken: string, variables: DeleteMatchingTeamBgImageVariables & AppInfoVariables): Promise<GraphQLResult<DeleteMatchingTeamBgImageResponse>> => {
  return execGqlRequest<DeleteMatchingTeamBgImageResponse>(autoGenMutations.deleteMatchingTeamBgImage, variables, accessToken)
}
export type DeleteMatchingTeamBgImageVariables = autoGenAPI.DeleteMatchingTeamBgImageMutationVariables
export type DeleteMatchingTeamBgImageResponse = autoGenAPI.DeleteMatchingTeamBgImageMutation

/**
 * 14-19. 団体マッチング募集添付ファイル取得用URL取得API
 *
 * 団体マッチング募集添付ファイル取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、ファイルを取得します。
 */
export const getMatchingOfferAttachmentGetUrl = (accessToken: string, variables: GetMatchingOfferAttachmentGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingOfferAttachmentGetUrlResponse>> => {
  return execGqlRequest<GetMatchingOfferAttachmentGetUrlResponse>(autoGenQueries.getMatchingOfferAttachmentGetUrl, variables, accessToken)
}
export type GetMatchingOfferAttachmentGetUrlVariables = autoGenAPI.GetMatchingOfferAttachmentGetUrlQueryVariables
export type GetMatchingOfferAttachmentGetUrlResponse = autoGenAPI.GetMatchingOfferAttachmentGetUrlQuery


/**
 * 14-20. 団体マッチング募集添付ファイル保存用URL取得API
 *
 * 団体マッチング募集添付ファイル保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * team/${teamId}/matching/offer/${offerId}/attachments/${i}/${fileName}
 * ※ {i}は添付ファイル数に応じた0始まりのインデックス(同じファイル名のケースを考慮)
 */
export const getMatchingOfferAttachmentPutUrl = (accessToken: string, variables: GetMatchingOfferAttachmentPutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingOfferAttachmentPutUrlResponse>> => {
  return execGqlRequest<GetMatchingOfferAttachmentPutUrlResponse>(autoGenQueries.getMatchingOfferAttachmentPutUrl, variables, accessToken)
}
export type GetMatchingOfferAttachmentPutUrlVariables = autoGenAPI.GetMatchingOfferAttachmentPutUrlQueryVariables
export type GetMatchingOfferAttachmentPutUrlResponse = autoGenAPI.GetMatchingOfferAttachmentPutUrlQuery


/**
 * 14-21. 団体マッチング募集添付サムネ画像取得用URL取得API
 *
 * 団体マッチング募集添付ファイルのサムネイル画像取得用の一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像を取得します。
 */
export const getMatchingOfferAttachmentThumbnailGetUrl = (accessToken: string, variables: GetMatchingOfferAttachmentThumbnailGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingOfferAttachmentThumbnailGetUrlResponse>> => {
  return execGqlRequest<GetMatchingOfferAttachmentThumbnailGetUrlResponse>(autoGenQueries.getMatchingOfferAttachmentThumbnailGetUrl, variables, accessToken)
}
export type GetMatchingOfferAttachmentThumbnailGetUrlVariables = autoGenAPI.GetMatchingOfferAttachmentThumbnailGetUrlQueryVariables
export type GetMatchingOfferAttachmentThumbnailGetUrlResponse = autoGenAPI.GetMatchingOfferAttachmentThumbnailGetUrlQuery


/**
 * 14-22. 団体マッチング募集添付サムネ画像保存用URL取得API
 *
 * 団体マッチング募集添付ファイルのサムネイル画像保存用の一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像を保存します。
 * ファイル保存先のキー:
 * team/${teamId}/matching/offer/${offerId}/attachments/${i}/thumbnail.${extension}
 * ※ {extension}は添付ファイルの拡張子と同じ
 */
export const getMatchingOfferAttachmentThumbnailPutUrl = (accessToken: string, variables: GetMatchingOfferAttachmentThumbnailPutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingOfferAttachmentThumbnailPutUrlResponse>> => {
  return execGqlRequest<GetMatchingOfferAttachmentThumbnailPutUrlResponse>(autoGenQueries.getMatchingOfferAttachmentThumbnailPutUrl, variables, accessToken)
}
export type GetMatchingOfferAttachmentThumbnailPutUrlVariables = autoGenAPI.GetMatchingOfferAttachmentThumbnailPutUrlQueryVariables
export type GetMatchingOfferAttachmentThumbnailPutUrlResponse = autoGenAPI.GetMatchingOfferAttachmentThumbnailPutUrlQuery


/**
 * リソース(画像/添付ファイル)取得用URL取得API
 *
 * 指定したリソースの一時発行URLを取得します。
 * このURLに対して、HTTPのGETを実行することで、画像や添付ファイルを取得します。
 * リソースが画像の場合、idが未指定、またはidに対するリソースが登録されていない場合は、デフォルト画像のURLを取得します。
 * リソースが添付ファイルの場合、idが未指定、またはidに対するリソースが登録されていない場合は、undefinedを取得します。
 */
export const getResourceGetUrl = (accessToken: string, variables: GetResourceGetUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetResourceGetUrlResponse>> => {
  const { appInfo } = variables

  switch (variables.type) {
    case 'accountImage':
      return getAccountImageGetUrl(accessToken, {
        input: {
          thumbnail: variables.size === 'thumbnail'
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getAccountImageGetUrl,
          ...rest
        }
      })
    case 'teamImage':
      return getTeamImageGetUrl(accessToken, {
        input: {
          teamId: variables.id,
          thumbnail: variables.size === 'thumbnail'
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getTeamImageGetUrl,
          ...rest
        }
      })
    case 'teamMemberImage':
      return getTeamMemberImageGetUrl(accessToken, {
        input: {
          teamMemberId: variables.id,
          thumbnail: variables.size === 'thumbnail'
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getTeamMemberImageGetUrl,
          ...rest
        }
      })
    case 'preTeamMemberImage':
      return getTeamMemberImageGetUrl(accessToken, {
        input: {
          teamMemberId: variables.id,
          thumbnail: variables.size === 'thumbnail',
          isPreTeamMember: true
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getTeamMemberImageGetUrl,
          ...rest
        }
      })
    case 'attachmentFile':
      return getMailAttachmentGetUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          mailId: variables.mailId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMailAttachmentGetUrl,
          ...rest
        }
      })
    case 'attachmentThumbnail':
      return getMailAttachmentThumbnailGetUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          mailId: variables.mailId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMailAttachmentThumbnailGetUrl,
          ...rest
        }
      })
    case 'matchingTeamImage':
      return getMatchingTeamImageGetUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          thumbnail: variables.size === 'thumbnail'
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingTeamImageGetUrl,
          ...rest
        }
      })
    case 'matchingTeamBgImage':
      return getMatchingTeamBgImageGetUrl(accessToken, {
        input: {
          teamId: variables.teamId,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingTeamBgImageGetUrl,
          ...rest
        }
      })
    case 'matchingOfferAttachmentFile':
      return getMatchingOfferAttachmentGetUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          offerId: variables.offerId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingOfferAttachmentGetUrl,
          ...rest
        }
      })
    case 'matchingOfferAttachmentThumbnail':
      return getMatchingOfferAttachmentThumbnailGetUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          offerId: variables.offerId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingOfferAttachmentThumbnailGetUrl,
          ...rest
        }
      })
  }
}
type AccountImageParams = {
  type: 'accountImage'
  size: 'normal' | 'thumbnail'
}

type TeamImageParams = {
  type: 'teamImage'
  id: string
  size: 'normal' | 'thumbnail'
}

type TeamMemberImageParams = {
  type: 'teamMemberImage'
  id: string
  size: 'normal' | 'thumbnail'
}

type PreTeamMemberImageParams = {
  type: 'preTeamMemberImage'
  id: string
  size: 'normal' | 'thumbnail'
}

type AttachmentFileParams = {
  type: 'attachmentFile'
  teamId: string
  mailId: string
  index: number
  fileName: string
}

type AttachmentThumbnailParams = {
  type: 'attachmentThumbnail'
  teamId: string
  mailId: string
  index: number
  fileName: string
}

type MatchingTeamImageParams = {
  type: 'matchingTeamImage'
  teamId: string
  size: 'normal' | 'thumbnail'
}

type MatchingTeamBgImageParams = {
  type: 'matchingTeamBgImage'
  teamId: string
  size: 'normal' | 'thumbnail'
}

type MatchingOfferAttachmentFileParams = {
  type: 'matchingOfferAttachmentFile'
  teamId: string
  offerId: string
  index: number
  fileName: string
}

type MatchingOfferAttachmentThumbnailParams = {
  type: 'matchingOfferAttachmentThumbnail'
  teamId: string
  offerId: string
  index: number
  fileName: string
}

export type ResourceGetUrlParams =
  AccountImageParams |
  TeamImageParams |
  TeamMemberImageParams |
  PreTeamMemberImageParams |
  AttachmentFileParams |
  AttachmentThumbnailParams |
  MatchingTeamImageParams |
  MatchingTeamBgImageParams |
  MatchingOfferAttachmentFileParams |
  MatchingOfferAttachmentThumbnailParams

export type GetResourceGetUrlVariables = ResourceGetUrlParams
export type GetResourceGetUrlResponse = {
  url?: string
  isDefault?: boolean
}

/**
 * リソース(画像/添付ファイル)保存用URL取得API
 *
 * 指定したリソースを保存するための一時発行URLを取得します。
 * このURLに対して、HTTPのPUTを実行することで、画像や添付ファイルを保存します。
 */
export const getResourcePutUrl = (accessToken: string, variables: GetResourcePutUrlVariables & AppInfoVariables): Promise<GraphQLResult<GetResourcePutUrlResponse>> => {
  const { appInfo } = variables

  switch (variables.type) {
    case 'accountImage':
      return getAccountImagePutUrl(accessToken, {
        input: {
          extension: variables.extension
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getAccountImagePutUrl,
          ...rest
        }
      })
    case 'teamImage':
      return getTeamImagePutUrl(accessToken, {
        input: {
          teamId: variables.id,
          extension: variables.extension
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getTeamImagePutUrl,
          ...rest
        }
      })
    case 'attachmentFile':
      return getMailAttachmentPutUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          mailId: variables.mailId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMailAttachmentPutUrl,
          ...rest
        }
      })
    case 'attachmentThumbnail':
      return getMailAttachmentThumbnailPutUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          mailId: variables.mailId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMailAttachmentThumbnailPutUrl,
          ...rest
        }
      })
    case 'matchingTeamImage':
      return getMatchingTeamImagePutUrl(accessToken, {
        input: {
          teamId: variables.id,
          extension: variables.extension
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingTeamImagePutUrl,
          ...rest
        }
      })
    case 'matchingTeamBgImage':
      return getMatchingTeamBgImagePutUrl(accessToken, {
        input: {
          teamId: variables.id,
          extension: variables.extension
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingTeamBgImagePutUrl,
          ...rest
        }
      })
    case 'matchingOfferAttachmentFile':
      return getMatchingOfferAttachmentPutUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          offerId: variables.offerId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingOfferAttachmentPutUrl,
          ...rest
        }
      })
    case 'matchingOfferAttachmentThumbnail':
      return getMatchingOfferAttachmentThumbnailPutUrl(accessToken, {
        input: {
          teamId: variables.teamId,
          offerId: variables.offerId,
          index: variables.index,
          fileName: variables.fileName,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.getMatchingOfferAttachmentThumbnailPutUrl,
          ...rest
        }
      })
  }
}
type AccountImagePutParams = {
  type: 'accountImage'
  extension: ImageExtension
}

type TeamImagePutParams = {
  type: 'teamImage'
  extension: ImageExtension
  id: string
}

type AttachmentFilePutParams = {
  type: 'attachmentFile'
  teamId: string
  mailId: string
  index: number
  fileName: string
}

type AttachmentThumbnailPutParams = {
  type: 'attachmentThumbnail'
  teamId: string
  mailId: string
  index: number
  fileName: string
}

type MatchingTeamImagePutParams = {
  type: 'matchingTeamImage'
  extension: ImageExtension
  id: string
}

type MatchingTeamBgImagePutParams = {
  type: 'matchingTeamBgImage'
  extension: ImageExtension
  id: string
}

type MatchingOfferAttachmentFilePutParams = {
  type: 'matchingOfferAttachmentFile'
  teamId: string
  offerId: string
  index: number
  fileName: string
}

type MatchingOfferAttachmentThumbnailPutParams = {
  type: 'matchingOfferAttachmentThumbnail'
  teamId: string
  offerId: string
  index: number
  fileName: string
}

export type ResourcePutUrlParams =
  AccountImagePutParams |
  TeamImagePutParams |
  AttachmentFilePutParams |
  AttachmentThumbnailPutParams |
  MatchingTeamImagePutParams |
  MatchingTeamBgImagePutParams |
  MatchingOfferAttachmentFilePutParams |
  MatchingOfferAttachmentThumbnailPutParams

export type GetResourcePutUrlVariables = ResourcePutUrlParams
export type GetResourcePutUrlResponse = {
  url: string
  thumbnailUrl?: string
}


/**
 * リソース(画像/添付ファイル)削除API
 *
 * 指定したリソースを削除します。
 */
export const deleteResource = (accessToken: string, variables: DeleteResourceVariables & AppInfoVariables): Promise<GraphQLResult<DeleteResourceResponse>> => {
  const { appInfo } = variables

  switch (variables.type) {
    case 'accountImage':
      return deleteAccountImage(accessToken, { appInfo }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.deleteAccountImage,
          ...rest
        }
      })
    case 'teamImage':
      return deleteTeamImage(accessToken, {
        input: {
          teamId: variables.id,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.deleteTeamImage,
          ...rest
        }
      })
    case 'matchingTeamImage':
      return deleteMatchingTeamImage(accessToken, {
        input: {
          teamId: variables.teamId,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.deleteMatchingTeamImage,
          ...rest
        }
      })
    case 'matchingTeamBgImage':
      return deleteMatchingTeamBgImage(accessToken, {
        input: {
          teamId: variables.teamId,
        },
        appInfo
      }).then(res => {
        const {data, ...rest} = res
        return {
          data: data && data.deleteMatchingTeamBgImage,
          ...rest
        }
      })
  }
}
type DeleteAccountImageParams = {
  type: 'accountImage'
}

type DeleteTeamImageParams = {
  type: 'teamImage'
  id: string
}

type DeleteMatchingTeamImageParams = {
  type: 'matchingTeamImage'
  teamId: string
}

type DeleteMatchingTeamImageBgParams = {
  type: 'matchingTeamBgImage'
  teamId: string
}

export type ResourceDeleteParams =
  DeleteAccountImageParams |
  DeleteTeamImageParams |
  DeleteMatchingTeamImageParams |
  DeleteMatchingTeamImageBgParams

export type DeleteResourceVariables = ResourceDeleteParams
export type DeleteResourceResponse = {
  id: string
}


/***
 * 16. 下書き系
 ***/


/**
 * 16-1. 下書き登録API
 *
 * 下書きを新規作成します。
 */
export const createDraft = (accessToken: string, variables: CreateDraftVariables & AppInfoVariables): Promise<GraphQLResult<CreateDraftResponse>> => {
  return execGqlRequest<CreateDraftResponse>(autoGenMutations.createDraft, variables, accessToken)
}
export type CreateDraftVariables = autoGenAPI.CreateDraftMutationVariables
export type CreateDraftResponse = autoGenAPI.CreateDraftMutation


/**
 * 16-2. 下書き一覧API
 *
 * 下書きの一覧を更新日時の降順ですべて取得します。
 */
export const listDraft = (accessToken: string, variables: ListDraftVariables & AppInfoVariables): Promise<GraphQLResult<ListDraftResponse>> => {
  return execGqlRequest<ListDraftResponse>(customGql.listDraft, variables, accessToken).then(res => {
    const items = res.data?.getTeamMember.drafts.items
    if (items) items.sort((l, r) => l.updatedAt > r.updatedAt ? -1 : 1)
    return res
  })
}
export type ListDraftVariables = {
  teamMemberId: string
}
export type ListDraftResponse = {
  getTeamMember: {
    drafts: {
      items: {
        id: string
        mailType: MailType
        title: string
        body: string
        attachmentFileNames?: string[] | null
        updatedAt: string
      }[]
    }
  }
}

/**
 * 16-3. 下書き取得API
 *
 * 下書きの詳細を取得します。
 */
export const getDraft = (accessToken: string, variables: GetDraftVariables & AppInfoVariables): Promise<GraphQLResult<GetDraftResponse>> => {
  return execGqlRequest<GetDraftResponse>(customGql.getDraft, variables, accessToken)
}
export type GetDraftVariables = autoGenAPI.GetDraftQueryVariables
export type GetDraftResponse = {
  getDraft: {
    id: string
    mailType: MailType
    title: string
    teamMember: {
      id: string
      nickname: string
    }
    body: string
    sendTo:  {
      allMembersFlg: boolean
      managersFlg: boolean
      groupIds: string[]
      memberIds: string[]
    }
    attachmentFileNames: string[]
    questionnaires?: {
      answerSelectType: AnswerSelectType
      question: string
      choices: string[]
    }[]
    candidateDatetimes?: {
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }[] | null
    shareManagerFlg: number
    showAnswerFlg: boolean
    responseDueDatetime?: string
    remindDatetime?: string
    sendScheduledAt?: string
    updatedAt: boolean
  }
}


/**
 * 16-4. 下書き更新API
 *
 * 下書きを更新します。
 */
export const updateDraft = (accessToken: string, variables: UpdateDraftVariables & AppInfoVariables): Promise<GraphQLResult<UpdateDraftResponse>> => {
  return execGqlRequest<UpdateDraftResponse>(customGql.updateDraft, variables, accessToken)
}
export type UpdateDraftVariables = autoGenAPI.UpdateDraftMutationVariables
export type UpdateDraftResponse = {
  id: string
}


/**
 * 16-5. 下書き削除API
 *
 * 下書きを物理削除します。
 */
export const deleteDraft = (accessToken: string, variables: DeleteDraftVariables & AppInfoVariables): Promise<GraphQLResult<DeleteDraftResponse>> => {
  return execGqlRequest<DeleteDraftResponse>(customGql.deleteDraft, variables, accessToken)
}
export type DeleteDraftVariables = autoGenAPI.DeleteDraftMutationVariables
export type DeleteDraftResponse = {
  deleteDraft: {
    id: string
  }
}


/**
 * 16-6. 下書き一括削除API
 *
 * 指定した下書きを一括で削除します。
 */
export const multiDeleteDraft = (accessToken: string, variables: MultiDeleteDraftVariables & AppInfoVariables): Promise<GraphQLResult<MultiDeleteDraftResponse>> => {
  return execGqlRequest<MultiDeleteDraftResponse>(autoGenMutations.multiDeleteDraft, variables, accessToken)
}
export type MultiDeleteDraftVariables = autoGenAPI.MultiDeleteDraftMutationVariables
export type MultiDeleteDraftResponse = autoGenAPI.MultiDeleteDraftMutation


/***
 * 17. テンプレート系
 ***/


/**
 * 17-1. テンプレート登録API
 *
 * 作成済みの連絡または送信予約連絡を元にテンプレートを新規作成します。
 */
export const createTemplate = (accessToken: string, variables: CreateTemplateVariables & AppInfoVariables): Promise<GraphQLResult<CreateTemplateResponse>> => {
  return execGqlRequest<CreateTemplateResponse>(autoGenMutations.createTemplate, variables, accessToken)
}
export type CreateTemplateVariables = autoGenAPI.CreateTemplateMutationVariables
export type CreateTemplateResponse = autoGenAPI.CreateTemplateMutation


/**
 * 17-2. テンプレート一覧API
 *
 * テンプレートの一覧を更新日時の降順ですべて取得します。
 */
export const listTemplate = (accessToken: string, variables: ListTemplateVariables & AppInfoVariables): Promise<GraphQLResult<ListTemplateResponse>> => {
  return execGqlRequest<ListTemplateResponse>(customGql.listTemplate, variables, accessToken).then(res => {
    const items = res.data?.getTeamMember.templates.items
    if (items) items.sort((l, r) => l.createdAt > r.createdAt ? -1 : 1)
    return res
  })
}
export type ListTemplateVariables = {
  teamMemberId: string
}
export type ListTemplateResponse = {
  getTeamMember: {
    templates: {
      items: {
        id: string
        mailType: MailType
        title: string
        body: string
        favoriteFlg: boolean
        updatedAt: string
        createdAt: string
      }[]
    }
  }
}


/**
 * 17-3. テンプレート取得API
 *
 * テンプレートの詳細を取得します。
 */
export const getTemplate = (accessToken: string, variables: GetTemplateVariables & AppInfoVariables): Promise<GraphQLResult<GetTemplateResponse>> => {
  return execGqlRequest<GetTemplateResponse>(customGql.getTemplate, variables, accessToken)
}
export type GetTemplateVariables = autoGenAPI.GetTemplateQueryVariables
export type GetTemplateResponse = {
  getTemplate: {
    id: string
    mailType: MailType
    title: string
    teamMember: {
      id: string
      nickname: string
    }
    body: string
    sendTo:  {
      allMembersFlg: boolean
      managersFlg: boolean
      groupIds: string[]
      memberIds: string[]
    }
    questionnaires?: {
      answerSelectType: AnswerSelectType
      question: string
      choices: string[]
    }[]
    candidateDatetimes?: {
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }[] | null
    shareManagerFlg: boolean
    showAnswerFlg: boolean
    favoriteFlg: boolean
    updatedAt: boolean
  }
}


/**
 * 17-4. テンプレートお気に入り更新API
 *
 * テンプレートのお気に入りフラグを更新します。
 */
export const updateTemplateFavoriteFlg = (accessToken: string, variables: UpdateTemplateFavoriteFlgVariables & AppInfoVariables): Promise<GraphQLResult<UpdateTemplateFavoriteFlgResponse>> => {
  return execGqlRequest<UpdateTemplateFavoriteFlgResponse>(customGql.updateTemplateFavoriteFlg, variables, accessToken)
}
export type UpdateTemplateFavoriteFlgVariables = {
  input: {
    id: string
    favoriteFlg: boolean
  }
}
export type UpdateTemplateFavoriteFlgResponse = {
  updateTemplate: {
    id: string
  }
}


/**
 * 17-5. テンプレート削除API
 *
 * テンプレートを物理削除します。
 */
export const deleteTemplate = (accessToken: string, variables: DeleteTemplateVariables & AppInfoVariables): Promise<GraphQLResult<DeleteTemplateResponse>> => {
  return execGqlRequest<DeleteTemplateResponse>(customGql.deleteTemplate, variables, accessToken)
}
export type DeleteTemplateVariables = autoGenAPI.DeleteTemplateMutationVariables
export type DeleteTemplateResponse = {
  deleteTemplate: {
    id: string
  }
}


/**
 * 17-6. テンプレート一括削除API
 *
 * 指定したテンプレートを一括で削除します。
 */
export const multiDeleteTemplate = (accessToken: string, variables: MultiDeleteTemplateVariables & AppInfoVariables): Promise<GraphQLResult<MultiDeleteTemplateResponse>> => {
  return execGqlRequest<MultiDeleteTemplateResponse>(autoGenMutations.multiDeleteTemplate, variables, accessToken)
}
export type MultiDeleteTemplateVariables = autoGenAPI.MultiDeleteTemplateMutationVariables
export type MultiDeleteTemplateResponse = autoGenAPI.MultiDeleteTemplateMutation


/***
 * 18. 返信スレッド系
 ***/

/**
 * 18-1. 返信スレッドメッセージ登録API
 *
 * 返信スレッドにメッセージを登録します。
 * スレッドが作成されていない場合は、新規でスレッドを作成します。
 */
export const createThreadMessage = (accessToken: string, variables: CreateThreadVariables & AppInfoVariables): Promise<GraphQLResult<CreateThreadResponse>> => {
  return execGqlRequest<CreateThreadResponse>(autoGenMutations.createThreadMessage, variables, accessToken)
}
export type CreateThreadVariables = autoGenAPI.CreateThreadMessageMutationVariables
export type CreateThreadResponse = autoGenAPI.CreateThreadMessageMutation


/**
 * 18-2. 返信スレッド一覧(連絡受信者用)API
 *
 * 連絡受信者向けの返信スレッドの一覧を最新メッセージの更新日時の降順ですべて取得します。
 */
export const listThreadByMailRecipient = async (accessToken: string, variables: ListThreadVariables & AppInfoVariables): Promise<GraphQLResult<ListThreadResponse>> => {
  return _listThread(accessToken, variables, customGql.listThreadByMailRecipient)
}

/**
 * 18-3. 返信スレッド一覧(連絡送信者用)API
 *
 * 連絡送信者向けの返信スレッドの一覧を最新メッセージの更新日時の降順ですべて取得します。
 */
export const listThreadByMailSender = async (accessToken: string, variables: ListThreadVariables & AppInfoVariables): Promise<GraphQLResult<ListThreadResponse>> => {
  return _listThread(accessToken, variables, customGql.listThreadByMailSender)
}

const _listThread = async (accessToken: string, variables: ListThreadVariables & AppInfoVariables, gql: string): Promise<GraphQLResult<ListThreadResponse>> => {
  const {accountId, ...listThreadVariables} = variables
  const threadRes = await execGqlRequest<ListThreadRawResponse>(gql, listThreadVariables, accessToken)
  if (threadRes.errors && threadRes.errors.length > 0) return {
    ...threadRes,
    data: undefined,
  }
  if (threadRes.data?.getMail?.threads == null || threadRes.data.getMail.threads.items.length === 0) return {
    data: {
      listThread: {
        items: []
      }
    }
  }

  const getThreadSummary = async (threadId: string) => {
    const variables_ = { threadId, notDeleteFlg: true }
    const latestMessageRes = await execGqlRequest<GetLatestMessageRawResponse>(customGql.getLatestThreadMessage, variables_, accessToken)
    const unreadMessageCountRes = await getUnreadThreadMessageCount(accessToken, { aggrUnit: 'thread', accountId, threadId })

    const latestMessage: LatestMessage | undefined = latestMessageRes.data?.searchThreadMessages.items[0]
  
    const unreadMessageCount = unreadMessageCountRes.data?.searchThreadMessages.aggregateItems[0].result.value || 0

    return { latestMessage, unreadMessageCount }
  }
  const threads = (await Promise.all((threadRes.data?.getMail.threads.items || []).map(async item => {
    const { latestMessage, unreadMessageCount } = await getThreadSummary(item.id)
    const timeLastMessage = latestMessage?.createdAt
    if (!latestMessage) return undefined
    return { ...item, latestMessage, unreadMessageCount, timeLastMessage }
  }))).filter(notNull).sort((a, b) => {
    if (a.timeLastMessage && b.timeLastMessage) {
      return new Date(b.timeLastMessage).getTime() - new Date(a.timeLastMessage).getTime()
    }
    return 0
  })

  return {
    data: {
      listThread: {
        items: threads
      }
    }
  }
}

export type ListThreadVariables = {
  accountId: string
  mailId: string
  myMemberId: string
}
export type ThreadMember = {
  id: string
  nickname: string
  deleteFlg?: boolean | null
}
type ListThreadRawResponse = {
  getMail?: {
    threads: {
      items: {
        id: string,
        mailSender: ThreadMember,
        mailRecipient: ThreadMember,
      }[]
    } | null
  } | null
}

type LatestMessage = {
  text: string,
  messageSenderId: string,
  createdAt: string,
  updatedAt: string,
}
type GetLatestMessageRawResponse = {
  searchThreadMessages: {
    items: LatestMessage[]
  }
}

export type ListThreadItem = {
  id: string
  mailSender: ThreadMember,
  mailRecipient: ThreadMember,
  latestMessage: LatestMessage
  unreadMessageCount: number
}
export type ListThreadResponse = {
  listThread: {
    items: ListThreadItem[]
  }
}

/**
 * 18-4. 返信スレッドメッセージ一覧API
 *
 * 返信スレッドのメッセージ一覧を更新日時の降順で100件取得します。
 */
export const listThreadMessage = async (accessToken: string, variables: ListThreadMessageVariables & AppInfoVariables): Promise<GraphQLResult<ListThreadMessageResponse>> => {
  const mailId = variables.threadId.split('.')[0]
  const getTeamMemberIdVariables: autoGenAPI.GetTeamMemberIdQueryVariables = {
    input: { mailId: mailId },
  }
  const myMemberId: string = await execGqlRequest<autoGenAPI.GetTeamMemberIdQuery>(autoGenQueries.getTeamMemberId, getTeamMemberIdVariables, accessToken).then(res => {
    if (!res.data) throw Error('団体メンバーIDの取得に失敗しました')
    return res.data.getTeamMemberId.id
  })

  const res = await execGqlRequest<ListThreadMessageRawResponse>(customGql.listThreadMessage, variables, accessToken)

  // エラーがある場合はそのまま返却
  if (res.errors && res.errors.length > 0) return {
    ...res,
    data: undefined
  }

  // スレッドが作成されていない場合は空のスレッドを返却
  if (res.data == null || res.data.getThread == null) {
    const getEmptyThreadVariables: autoGenAPI.GetEmptyThreadQueryVariables = {
      input: { threadId: variables.threadId }
    }
    const emptyThread = await execGqlRequest<autoGenAPI.GetEmptyThreadQuery>(autoGenQueries.getEmptyThread, getEmptyThreadVariables, accessToken)
    if (emptyThread.data == null) return {
      ...res,
      data: undefined
    }
    
    const data = emptyThread.data.getEmptyThread
    return {
      ...res,
      data: {
        getThread: {
          myMemberId: myMemberId,
          mail: data.mail,
          mailSender: data.mailSender,
          mailRecipient: data.mailRecipient,
          messages: {
            items: []
          }
        }
      }
    }
  }

  // スレッドが存在する場合は、取得した情報を整形して返却
  const members: {[memberId: string]: {id: string, nickname: string}} = {
    [res.data.getThread.mailSender.id]: res.data.getThread.mailSender,
    [res.data.getThread.mailRecipient.id]: res.data.getThread.mailRecipient,
  }
  const getMember = (memberId: string): {id: string, nickname: string} => {
    const member = members[memberId]
    if (!member) throw Error('Unexpected error')
    return member
  }
  const getThread = res.data.getThread
  if(myMemberId !== getThread.mailRecipient.id && myMemberId !== getThread.mailSender.id) {
    throw gqlError(errorMessageNotInThread);
  }
  return {
    ...res,
    data: res.data ? {
      getThread: {
        myMemberId: myMemberId,
        mail: res.data.getThread.mail,
        mailSender: res.data.getThread.mailSender,
        mailRecipient: res.data.getThread.mailRecipient,
        messages: {
          items: res.data.getThread.messages.items.map(item => ({
            id: item.id,
            member: getMember(item.messageSenderId),
            message: {
              text: item.text,
              readFlg: item.unreadFlg === 0,
              deleteFlg: item.deleteFlg,
              createdAt: item.createdAt,
              updatedAt: item.contentUpdatedAt,
              isEdited: item.createdAt != item.contentUpdatedAt
            }
          })),
          nextToken: res.data.getThread.messages.nextToken
        }
      }
    } : undefined
  }
}
export type ListThreadMessageVariables = {
  threadId: string
  nextToken?: string
}
export type ListThreadMessageRawResponse = {
  getThread: {
    mail: {
      id: string
      title: string
    }
    mailSender: ThreadMember
    mailRecipient: ThreadMember
    messages: {
      items: {
        id: string
        messageSenderId: string
        text?: string
        unreadFlg: number
        deleteFlg: boolean
        createdAt: string
        contentUpdatedAt: string
      }[]
      nextToken?: string | null
    }
  }
}
export type ThreadMessage = {
  id: string
  member: ThreadMember
  message: {
    text?: string
    readFlg: boolean
    deleteFlg: boolean
    createdAt: string
    updatedAt: string
    isEdited: boolean
  }
}
export type ListThreadMessageResponse = {
  getThread: {
    myMemberId: string
    mail: {
      id: string
      title: string
    }
    mailSender: ThreadMember
    mailRecipient: ThreadMember
    messages: {
      items: ThreadMessage[]
      nextToken?: string | null
    }
  }
}

/**
 * 18-5. 返信スレッドメッセージ更新API
 *
 * 返信スレッドメッセージを更新します。
 */
export const updateThreadMessage = (accessToken: string, variables: UpdateThreadMessageVariables & AppInfoVariables): Promise<GraphQLResult<UpdateThreadMessageResponse>> => {
  const {input, appInfo} = variables
  const variables_ = {
    input: removeUndefined({
      id: input.id,
      text: input.text,
      deleteFlg: input.deleteFlg,
      unreadFlg: input.readFlg != null ? (variables.input.readFlg ? 0 : 1) : undefined,
    }),
    appInfo
  }
  return execGqlRequest<UpdateThreadMessageResponse>(autoGenMutations.updateThreadMessage, variables_, accessToken)
}
export type UpdateThreadMessageVariables = {
  input: {
    id: string
    text?: string | null
    deleteFlg?: boolean | null
    readFlg?: boolean
  }
}
export type UpdateThreadMessageResponse = {
  updateThreadMessage: {
    id: string
  }
}


/**
 * 18-6. 返信スレッド未読メッセージ件数取得API
 *
 * 返信スレッドの未読メッセージ件数を取得します。
 */
export const getUnreadThreadMessageCount = async (accessToken: string, variables: GetUnreadThreadMessageCountVariables & AppInfoVariables): Promise<GraphQLResult<GetUnreadThreadMessageCountResponse>> => {
  const filter: autoGenAPI.SearchableThreadMessageFilterInput = {
    messageRecipientAccountId: {
      eq: variables.accountId,
    }
  }
  if (variables.aggrUnit === 'mail') {
    filter.mailId = {
      eq: variables.mailId
    }
  }
  if (variables.aggrUnit === 'thread') {
    filter.threadMessagesId = {
      eq: variables.threadId
    }
  }

  const aggregates: autoGenAPI.SearchableThreadMessageAggregationInput[] = [{
    name: 'unreadMessageCount',
    type: SearchableAggregateType.sum,
    field: SearchableThreadMessageAggregateField.unreadFlg
  }]

  const { appInfo } = variables

  return execGqlRequest<GetUnreadThreadMessageCountResponse>(customGql.getUnreadThreadMessageCount, { filter, aggregates, appInfo }, accessToken)
}
export type GetUnreadThreadMessageCountVariables = {
  accountId: string
} & (
  {
    aggrUnit: 'account'
  } | {
  aggrUnit: 'mail',
  mailId: string,
} | {
  aggrUnit: 'thread',
  threadId: string,
}
  )

export type GetUnreadThreadMessageCountResponse = {
  searchThreadMessages: {
    aggregateItems: [
      {
        name: 'unreadMessageCount',
        result: {
          value: number
        }
      }
    ]
  }
}

export const getUnreadThreadMessageCountByIds = async (accessToken: string, variables: GetUnreadThreadMessageCountByIdsVariables & AppInfoVariables): Promise<GraphQLResult<GetUnreadThreadMessageCountByIdsResponse>> => {
  const filter: autoGenAPI.SearchableThreadMessageFilterInput = {
    messageRecipientAccountId: {
      eq: variables.accountId,
    }
  }
  if (variables.teamId) {
    filter.teamId = {
      eq: variables.teamId
    }
  }
  filter.unreadFlg = {
    eq: 1
  }
  if (variables.aggrUnit === 'mail') {
    filter.or = variables.mailIds.map(mailId => ({mailId: {eq: mailId}}));
  }
  if (variables.aggrUnit === 'thread') {
    filter.or = variables.threadIds.map(threadId => ({threadMessagesId: {eq: threadId}}));
  }

  const aggregates: autoGenAPI.SearchableThreadMessageAggregationInput[] = [{
    name: 'unreadMessageCount',
    type: SearchableAggregateType.terms,
    field: SearchableThreadMessageAggregateField.mailId
  }]

  const { appInfo } = variables
  return execGqlRequest<GetUnreadThreadMessageCountByIdsResponse>(customGql.getUnreadThreadMessageCountByIds, { filter, aggregates, appInfo }, accessToken)
}
export type GetUnreadThreadMessageCountByIdsVariables = {
  accountId: string
  teamId?: string
} & (
  {
    aggrUnit: 'account'
  } | {
  aggrUnit: 'mail',
  mailIds: string[],
} | {
  aggrUnit: 'thread',
  threadIds: string[],
}
  )

export type GetUnreadThreadMessageCountByIdsResponse = {
  searchThreadMessages: {
    aggregateItems: [
      {
        name: 'unreadMessageCount',
        result: {
          buckets: Buckets[]
        }
      }
    ]
  }
}

export type Buckets = {
  doc_count: number,
  key: string
}


/***
 * 19. 旧らくらく連絡網移行
 ***/


/**
 * 19-1. 団体移行API
 *
 * 旧らくらく連絡網から団体を移行します。
 */
export const migrateTeam = (accessToken: string, variables: MigrateTeamVariables & AppInfoVariables): Promise<GraphQLResult<MigrateTeamResponse>> => {
  return execGqlRequest<MigrateTeamResponse>(autoGenMutations.migrateTeam, variables, accessToken)
}
export type MigrateTeamVariables = autoGenAPI.MigrateTeamMutationVariables
export type MigrateTeamResponse = autoGenAPI.MigrateTeamMutation


/**
 * 19-2. 連絡移行API
 *
 * 旧らくらく連絡網から連絡を移行します。
 * すでに移行済みの連絡が存在する場合は、一度全て削除し再度移行を実施します。
 */
export const migrateMail = (accessToken: string, variables: MigrateMailVariables & AppInfoVariables): Promise<GraphQLResult<MigrateMailResponse>> => {
  return execGqlRequest<MigrateMailResponse>(autoGenMutations.migrateMail, variables, accessToken)
}
export type MigrateMailVariables = autoGenAPI.MigrateMailMutationVariables
export type MigrateMailResponse = autoGenAPI.MigrateMailMutation


/**
 * 19-3. 移行済み送信連絡一覧API
 *
 * 連絡移行APIにより移行された連絡の一覧を送信日時の降順で30件ずつ取得します。
 */
export const listMigratedMail = (accessToken: string, variables: ListMigratedMailVariables & AppInfoVariables): Promise<GraphQLResult<ListMigratedMailResponse>> => {
  const filter: autoGenAPI.SearchableMigratedMailFilterInput = {
    teamMemberMigratedMailsId: {
      eq: variables.teamMemberId
    }
  }
  if (variables.filter?.keyword) {
    filter.or = [
      { title: { wildcard: `*${variables.filter.keyword}*` } },
      { title: { matchPhrase: variables.filter.keyword } },
      { body: { wildcard: `*${variables.filter.keyword}*` } },
      { body: { matchPhrase: variables.filter.keyword } },
    ]
  }
  if (variables.filter?.favorite) {
    filter.favoriteFlg = {
      eq: true
    }
  }
  const variables_ = {
    filter,
    nextToken: variables.nextToken,
    appInfo: variables.appInfo
  }

  return execGqlRequest<ListMigratedMailResponse>(customGql.listMigratedMail, variables_, accessToken)
}
export type ListMigratedMailVariables = {
  teamMemberId: string
  filter?: {
    keyword?: string
    favorite?: boolean
  }
  nextToken?: string
}
export type ListMigratedMailResponse = {
  searchMigratedMails: {
    items: {
      id: string
      title: string
      sender: string
      favoriteFlg: boolean
      sentDatetime: string
    }[]
    total?: number | null
    nextToken?: string | null
  }
}


/**
 * 19-4. 移行済み送信連絡取得API
 *
 * 移行済み送信連絡の詳細を取得します。
 */
export const getMigratedMail = (accessToken: string, variables: GetMigratedMailVariables & AppInfoVariables): Promise<GraphQLResult<GetMigratedMailResponse>> => {
  return execGqlRequest<GetMigratedMailResponse>(autoGenQueries.getMigratedMail, variables, accessToken)
}
export type GetMigratedMailVariables = autoGenAPI.GetMigratedMailQueryVariables
export type GetMigratedMailResponse = autoGenAPI.GetMigratedMailQuery


/**
 * 19-5. 移行済み送信連絡更新API
 *
 * 移行済み送信連絡を更新します。更新可能な項目は、お気に入りフラグのみです。
 */
export const updateMigratedMail = (accessToken: string, variables: UpdateMigratedMailVariables & AppInfoVariables): Promise<GraphQLResult<UpdateMigratedMailResponse>> => {
  return execGqlRequest<UpdateMigratedMailResponse>(customGql.updateMigratedMail, variables, accessToken)
}
export type UpdateMigratedMailVariables = {
  input: {
    id: string
    favoriteFlg: boolean
  }
}
export type UpdateMigratedMailResponse = {
  updateMigratedMail: {
    id: string
  }
}


/**
 * 19-6. 移行済み送信連絡削除API
 *
 * 移行済み送信連絡を削除します。
 */
export const deleteMigratedMail = (accessToken: string, variables: DeleteMigratedMailVariables & AppInfoVariables): Promise<GraphQLResult<DeleteMigratedMailResponse>> => {
  return execGqlRequest<DeleteMigratedMailResponse>(customGql.deleteMigratedMail, variables, accessToken)
}
export type DeleteMigratedMailVariables = {
  input: {
    id: string
  }
}
export type DeleteMigratedMailResponse = {
  deleteMigratedMail: {
    id: string
  }
}


/**
 * 19-7. 団体メンバー事前設定取得API
 *
 * 団体メンバー事前設定を取得します。
 */
export const getTeamMemberPreset = (accessToken: string, variables: GetTeamMemberPresetVariables & AppInfoVariables): Promise<GraphQLResult<GetTeamMemberPresetResponse>> => {
  return execGqlRequest<GetTeamMemberPresetResponse>(autoGenQueries.getTeamMemberPreset, variables, accessToken)
}
export type GetTeamMemberPresetVariables = autoGenAPI.GetTeamMemberPresetQueryVariables
export type GetTeamMemberPresetResponse = autoGenAPI.GetTeamMemberPresetQuery


/**
 * 19-8. 事前設定からの団体メンバー作成API
 *
 * 団体メンバー事前設定を使用して、団体メンバーを作成します。
 */
export const createTeamMemberFromPreset = (accessToken: string, variables: CreateTeamMemberFromPresetVariables & AppInfoVariables): Promise<GraphQLResult<CreateTeamMemberFromPresetResponse>> => {
  return execGqlRequest<CreateTeamMemberFromPresetResponse>(autoGenMutations.createTeamMemberFromPreset, variables, accessToken)
}
export type CreateTeamMemberFromPresetVariables = autoGenAPI.CreateTeamMemberFromPresetMutationVariables
export type CreateTeamMemberFromPresetResponse = autoGenAPI.CreateTeamMemberFromPresetMutation


/**
 * 19-9. 移行状況一括取得API
 *
 * 指定した旧らくらく連絡網の団体IDについて、らくらく連絡網+への移行状況を取得します。
 */
export const multiGetMigrationStatus = (accessToken: string, variables: MultiGetMigrationStatusVariables & AppInfoVariables): Promise<GraphQLResult<MultiGetMigrationStatusResponse>> => {
  return execGqlRequest<MultiGetMigrationStatusResponse>(autoGenQueries.multiGetMigrationStatus, variables, accessToken)
}
export type MultiGetMigrationStatusVariables = autoGenAPI.MultiGetMigrationStatusQueryVariables
export type MultiGetMigrationStatusResponse = autoGenAPI.MultiGetMigrationStatusQuery


/***
 * 20. バッジ系
 ***/


/**
 * 20-1. 未読連絡件数取得API
 *
 * 団体ごとの未読連絡の件数を取得します。
 */
export const getUnreadMailCount = async (accessToken: string, variables: GetUnreadMailCountVariables & AppInfoVariables): Promise<GraphQLResult<GetUnreadMailCountResponse>> => {
  const teams = await listTeam(accessToken, variables).then(res => res.data?.listTeam.items.map(item => ({
    teamMemberId: item.id,
    teamId: item.team.id
  })) || [])

  const aggregates: autoGenAPI.SearchableTeamMemberMailAggregationInput[] = [{
    name: 'unreadMailCount',
    type: SearchableAggregateType.terms,
    field: SearchableTeamMemberMailAggregateField.teamMemberID
  }]

  const getFilter = (orFilter: autoGenAPI.SearchableTeamMemberMailFilterInput[]): autoGenAPI.SearchableTeamMemberMailFilterInput => ({
    or: orFilter,
    readFlg: {
      ne: true
    },
    receivedFlg: {
      eq: true
    },
    trashedFlgReceived: {
      ne: true
    },
  })
  const orFilter: autoGenAPI.SearchableTeamMemberMailFilterInput[] = teams.map(team => ({
    teamMemberID: {
      eq: team.teamMemberId
    }
  }))
  const variables_ = { aggregates, filter: getFilter(orFilter) }
  const listUnreadMailCountRawResponseGraphQLResult = await execGqlRequest<ListUnreadMailCountRawResponse>(customGql.listUnreadMailCount, variables_, accessToken)
  if(!listUnreadMailCountRawResponseGraphQLResult.data || listUnreadMailCountRawResponseGraphQLResult.data?.searchTeamMemberMails.aggregateItems[0].result.buckets.length == 0) {
    const unreadMailCountTeams = teams.map(team => ({
      id: team.teamId,
      unreadMailCount: 0
    }))
    return {
      data: {
        getUnreadMailCount: {
          teams: unreadMailCountTeams
        }
      }
    }
  } else {
    const buckets = listUnreadMailCountRawResponseGraphQLResult.data.searchTeamMemberMails.aggregateItems[0].result.buckets
    const unreadMailCountTeams = teams.map(team => ({
      id: team.teamId,
      unreadMailCount: buckets.find(({key}) => key == team.teamMemberId)?.doc_count ?? 0
    }))
    return {
      data: {
        getUnreadMailCount: {
          teams: unreadMailCountTeams
        }
      }
    }
  }
}
export type GetUnreadMailCountVariables = {
  accountId: string
}
export type GetUnreadMailCountResponse = {
  getUnreadMailCount: {
    teams: {
      id: string
      unreadMailCount: number
    }[]
  }
}

type ListUnreadMailCountRawResponse = {
  searchTeamMemberMails: {
    aggregateItems: [
      {
        name: 'unreadMailCount',
        result: {
          "buckets": {
            "doc_count": number,
            "key": string
          }[]
        }
      }
    ]
  }
}

/**
 * 20-2. 承認待ちメンバー有無一覧API
 *
 * アカウントに紐づく団体ごとの承認待ちメンバーの有無を取得します。
 */
export const listHasPreTeamMember = (accessToken: string, variables: ListHasPreTeamMemberVariables & AppInfoVariables): Promise<GraphQLResult<ListHasPreTeamMemberResponse>> => {
  const variables_ = {
    ...variables,
    notDeleteFlg: true,
    role: "manager"
  }
  return execGqlRequest<GetFirstPreTeamMemberIdsResponse>(customGql.getFirstPreTeamMemberIds, variables_, accessToken).then(res => {
    if (!res.data) return {
      data: undefined,
      errors: res.errors,
      extensions: res.extensions,
    }

    return {
      data: {
        items: res.data.getAccount.teamMembers.items.map(teamMember => ({
          teamId: teamMember.team.id,
          hasPreTeamMember: teamMember.team.preTeamMembers.items.length > 0 && teamMember.team.teamMembers.items.length > 0
        }))
      }
    }
  })
}
export type ListHasPreTeamMemberVariables = {
  accountId: string
}
export type ListHasPreTeamMemberResponse = {
  items: {
    teamId: string
    hasPreTeamMember: boolean
  }[]
}

type GetFirstPreTeamMemberIdsResponse = {
  getAccount: {
    teamMembers: {
      items: {
        team: {
          id: string
          teamMembers: {
            items: {
              id: string
            }[]
          }
          preTeamMembers: {
            items: {
              id: string
            }[]
          }
        }
      }[]
    }
  }
}

/**
 * 20-3. 未読スレッドメッセージ有無一覧API
 *
 * 指定した団体の未読スレッドメッセージの有無を取得します。
 */
export const hasUnreadThreadMessage = (accessToken: string, variables: HasUnreadThreadMessageVariables & AppInfoVariables): Promise<GraphQLResult<HasUnreadThreadMessageResponse>> => {
  const variables_ = {
    ...variables,
    unreadFlg: 1,
    notDeleteFlg: true
  }
  return execGqlRequest<HasUnreadThreadMessageRawResponse>(customGql.hasUnreadThreadMessage, variables_, accessToken).then(res => {
    if (!res.data) return {
      data: undefined,
      errors: res.errors,
      extensions: res.extensions,
    }

    return {
      data: {
        hasUnreadThreadMessage: res.data.searchThreadMessages.items.length > 0
      }
    }
  })
}
export type HasUnreadThreadMessageVariables = {
  accountId: string
  teamId: string
}
type HasUnreadThreadMessageRawResponse = {
  searchThreadMessages: {
    items: {
      id: string
    }[]
  }
}
export type HasUnreadThreadMessageResponse = {
  hasUnreadThreadMessage: boolean
}

/***
 * 21. お問い合わせ系
 ***/


/**
 * 21-1. お問い合わせAPI
 *
 * お問い合わせを受け取り、アカウント情報などを追加して、メールディーラーにメール送信します。
 */
export const createInquiry = (accessToken: string, variables: CreateInquiryVariables & AppInfoVariables): Promise<GraphQLResult<CreateInquiryResponse>> => {
  return execGqlRequest<CreateInquiryResponse>(autoGenMutations.createInquiry, variables, accessToken)
}
export type CreateInquiryVariables = autoGenAPI.CreateInquiryMutationVariables
export type CreateInquiryResponse = autoGenAPI.CreateInquiryMutation


/***
 * 22. 規約系
 ***/


/**
 * 22-1. 最新規約取得API
 *
 * らくらく連絡網+で使用している規約の最新バージョンを取得します。
 */
export const getLatestTerms = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<GetLatestTermsResponse>> => {
  return execGqlRequest<GetLatestTermsResponse>(autoGenQueries.getLatestTerms, variables || {}, accessToken)
}
export type GetLatestTermsResponse = autoGenAPI.GetLatestTermsQuery

/**
 * 22-2. 利用規約同意API
 *
 * アカウントが、らくらく連絡網+の利用規約へ同意します。
 * 利用規約への同意状況は、getAccountにより取得します。
 */
export const agreeToTerms = (accessToken: string, variables: AgreeToTermsVariables & AppInfoVariables): Promise<GraphQLResult<AgreeToTermsResponse>> => {
  const {input, appInfo} = variables
  const variables_: autoGenAPI.AgreeToTermsMutationVariables & AppInfoVariables = {
    input: {
      type: TermsType.service,
      version: input.version
    },
    appInfo
  }
  return execGqlRequest<AgreeToTermsResponse>(autoGenMutations.agreeToTerms, variables_, accessToken)
}
export type AgreeToTermsVariables = {
  input: {
    version: string
  }
}
export type AgreeToTermsResponse = {
  id: string
}


/**
 * 22-3. マッチング利用規約同意API
 *
 * アカウントが、団体マッチングへの利用規約へ同意します。
 * 利用規約への同意状況は、getAccountにより取得します。
 */
export const agreeToMatchingTerms = (accessToken: string, variables: AgreeToMatchingTermsVariables & AppInfoVariables): Promise<GraphQLResult<AgreeToMatchingTermsResponse>> => {
  const {input, appInfo} = variables
  const variables_: autoGenAPI.AgreeToTermsMutationVariables & AppInfoVariables = {
    input: {
      type: TermsType.matching,
      version: input.version
    },
    appInfo
  }
  return execGqlRequest<AgreeToMatchingTermsResponse>(autoGenMutations.agreeToTerms, variables_, accessToken)
}
export type AgreeToMatchingTermsVariables = {
  input: {
    version: string
  }
}
export type AgreeToMatchingTermsResponse = {
  id: string
}


/***
 * 23. CSVダウンロード用データ系
 ***/


const _listMemberGroup = async (accessToken: string, variables: ListMemberGroupVariable): Promise<ListMemberGroupResponse> => {
  const { teamId, groupId } = variables
  const reader: PaginationInnerReader<{memberId: string, groups: string[], leaderGroups: string[]}> = async (nextToken?: string | null) => {
    return execGqlRequest<ListMemberGroupRawResponse>(customGql.listMemberGroup, {teamId, nextToken}, accessToken).then(res => {
      if (res.data == null) throw Error('データの取得に失敗しました。')
      return {
        data: res.data.getTeam.teamMembers.items.filter(item => {
          return groupId == null || item.groups.items.some(g => g.group.id === groupId)
        }).map(item => {
          const groups = sort(item.groups.items, g => g.group.name, 'ASC')
          return {
            memberId: item.id,
            groups: groups.map(g => g.group.name),
            leaderGroups: groups.filter(g => g.leaderFlg).map(g => g.group.name)
          }
        }),
        nextToken: res.data.getTeam.teamMembers.nextToken
      }
    })
  }

  return Object.fromEntries((await new PaginationReader(reader, 300).readAll()).map(item => {
    const {memberId, ...rest} = item
    return [memberId, rest]
  }))
}
type ListMemberGroupVariable = {
  teamId: string
  groupId?: string
}
type ListMemberGroupRawResponse = {
  getTeam: {
    teamMembers: {
      items: {
        id: string
        groups: {
          items: {
            leaderFlg: boolean
            group: {
              id: string
              name: string
            }
          }[]
        }
      }[]
      nextToken: string
    }
  }
}
type ListMemberGroupResponse = {
  [memberId: string]: {
    groups: string[]
    leaderGroups: string[]
  }
}

/**
 * 23-1. 出欠確認回答CSVデータ取得API
 *
 * CSVダウンロード用の出欠確認回答一覧をメンバー番号順で全て取得します。
 * 権限のないレコードは出力されません。
 */
export const getAttendanceAnswerCsvData = async (accessToken: string, variables: GetAttendanceAnswerCsvDataVariables): Promise<GetAttendanceAnswerCsvDataResponse> => {
  const { teamId, mailId } = variables.input
  const members = await listReceivedTeamMemberMail(accessToken, { input: { mailId } }).then(res => {
    if (res.data == null) throw Error('データの取得に失敗しました。')
    return res.data.listReceivedTeamMemberMail?.teamMemberMails.map(m => ({
      memberId: m.teamMember?.id || '',
      memberSeq: m.teamMember?.memberSeq || '',
      nickname: m.teamMember?.nickname || '',
      attendanceAnswer: m.attendanceAnswer,
      answeredAt: m.answeredAt
    })) || []
  })

  const memberGroups = await _listMemberGroup(accessToken, { teamId })

  const items = members.map(m => ({
    ...m,
    groups: memberGroups[m.memberId]?.groups || []
  }))

  return { items }
}
export type GetAttendanceAnswerCsvDataVariables = {
  input: {
    teamId: string
    mailId: string
  }
}
export type GetAttendanceAnswerCsvDataResponse = {
  items: {
    memberId: string
    memberSeq: string
    nickname: string
    groups: string[]
    attendanceAnswer?: YesNoAnswer | null
    answeredAt?: string | null
  }[]
}


/**
 * 23-2. アンケート回答CSVデータ取得API
 *
 * CSVダウンロード用のアンケート回答一覧をメンバー番号順で全て取得します。
 * 権限のないレコードは出力されません。
 */
export const getQuestionnaireAnswerCsvData = async (accessToken: string, variables: GetQuestionnaireAnswerCsvDataVariables): Promise<GetQuestionnaireAnswerCsvDataResponse> => {
  const { teamId, mailId, memberId } = variables.input
  const mail = await getMail(accessToken, { input: { mailId, memberId } })
  if (mail.data == null) throw Error('データの取得に失敗しました。')

  const questions = mail.data.getMail?.questionnaires?.map(q => ({
    answerSelectType: q.answerSelectType,
    question: q.question,
    formatted: `${q.question}の回答（${q.answerSelectType === 'single' ? '単一' : '複数'}回答）`
  })) || []

  const choices = mail.data.getMail?.questionnaires?.map(q => q.choices) || []

  const members = await listReceivedTeamMemberMail(accessToken, { input: { mailId } }).then(res => {
    if (res.data == null) throw Error('データの取得に失敗しました。')
    return res.data.listReceivedTeamMemberMail?.teamMemberMails.map(m => ({
      memberId: m.teamMember?.id || '',
      memberSeq: m.teamMember?.memberSeq || '',
      nickname: m.teamMember?.nickname || '',
      answers: m.questionnaireAnswers?.map((qa, questionIndex) => {
        const choice = choices[questionIndex] || []
        return qa.answer.map(choiceIndex => choice[choiceIndex]).filter(notNull)
      }) || [],
      answeredAt: m.answeredAt
    })) || []
  })

  const memberGroups = await _listMemberGroup(accessToken, { teamId })

  const items = members.map(m => ({
    ...m,
    groups: memberGroups[m.memberId]?.groups || []
  }));
  items.sort((a, b) => Number(a.memberSeq) - Number(b.memberSeq));
  return { questions, items }
}
export type GetQuestionnaireAnswerCsvDataVariables = {
  input: {
    teamId: string
    mailId: string
    memberId: string
  }
}
export type GetQuestionnaireAnswerCsvDataResponse = {
  questions: {
    answerSelectType: AnswerSelectType
    question: string
    formatted: string
  }[]
  items: {
    memberId: string
    memberSeq: string
    nickname: string
    groups: string[]
    answers: string[][]
    answeredAt?: string | null
  }[]
}

/**
 * 23-3. 日程調整回答CSVデータ取得API
 *
 * CSVダウンロード用の日程調整回答一覧をメンバー番号順で全て取得します。
 * 権限のないレコードは出力されません。
 */
export const getScheduleAnswerCsvData = async (accessToken: string, variables: GetScheduleAnswerCsvDataVariables): Promise<GetScheduleAnswerCsvDataResponse> => {
  const { teamId, mailId, memberId } = variables.input
  const mail = await getMail(accessToken, { input: { mailId, memberId } })
  if (mail.data == null) throw Error('データの取得に失敗しました。')

  const candidates = mail.data.getMail?.candidateDatetimes?.map(c => ({
    startDate: c.startDate,
    startTime: c.startTime,
    endDate: c.endDate,
    endTime: c.endTime,
    formatted: formatCandidate({
      startDate: new Date(c.startDate),
      startTime: c.startTime,
      endDate: c.endDate ? new Date(c.endDate) : undefined,
      endTime: c.endTime,
    })
  })) || []

  const members = await listReceivedTeamMemberMail(accessToken, { input: { mailId } }).then(res => {
    if (res.data == null) throw Error('データの取得に失敗しました。')
    return res.data.listReceivedTeamMemberMail?.teamMemberMails?.map(m => ({
      memberId: m.teamMember?.id || '',
      memberSeq: m.teamMember?.memberSeq || '',
      nickname: m.teamMember?.nickname || '',
      answers: m.scheduleAnswers || [],
      answeredAt: m.answeredAt
    })) || []
  })

  const memberGroups = await _listMemberGroup(accessToken, { teamId })

  const items = members.map(m => ({
    ...m,
    groups: memberGroups[m.memberId]?.groups || []
  }));
  items.sort((a, b) => Number(a.memberSeq) - Number(b.memberSeq));

  return { candidates, items }
}

const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]
const _formatDate = (d: Date) => `${d.getMonth() + 1}/${d.getDate()} (${dayOfWeek[d.getDay()]})`
type EventDateCandidate = {
  startDate: Date
  startTime?: string | null
  endDate?: Date | null
  endTime?: string | null
}
export const formatCandidate = (c: EventDateCandidate) => {
  if (!c.startTime && !c.endDate && !c.endTime) { // AllDay
    return _formatDate(c.startDate)
  } else if (c.startTime && !c.endDate && !c.endTime) { // AllDayAfter
    return `${_formatDate(c.startDate)} ${c.startTime} ～`
  } else if (
    c.startTime &&
    (!c.endDate || dateEqual(c.startDate, c.endDate))&&
    c.endTime
  ) { // TimeRange
    return `${_formatDate(c.startDate)} ${c.startTime} ～ ${c.endTime}`
  } else if (!c.startTime && c.endDate && !c.endTime) { // DateRange
    return `${_formatDate(c.startDate)} ～ ${_formatDate(c.endDate)}`
  } else if (c.startTime && c.endDate && c.endTime) { // DateTimeRange
    return `${_formatDate(c.startDate)} ${c.startTime} ～ ${_formatDate(c.endDate)} ${c.endTime}`
  } else {
    return _formatDate(c.startDate)
  }
}
export type GetScheduleAnswerCsvDataVariables = {
  input: {
    teamId: string
    mailId: string
    memberId: string
  }
}
export type GetScheduleAnswerCsvDataResponse = {
  candidates: {
    startDate: string
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    formatted: string
  }[]
  items: {
    memberId: string
    memberSeq: string
    nickname: string
    groups: string[]
    answers: YesNoAnswer[]
    answeredAt?: string | null
  }[]
}

/**
 * 23-4. 団体メンバーCSVデータ取得API
 *
 * CSVダウンロード用の団体メンバー一覧をメンバー番号順で全て取得します。
 * 権限のないレコードは出力されません。
 * 所属グループで絞り込みをする場合は、groupIdを指定します。
 */
export const getTeamMemberCsvData = async (accessToken: string, variables: GetTeamMemberCsvDataVariables): Promise<GetTeamMemberCsvDataResponse> => {
  const { teamId, groupId } = variables.input
  const notDeleteFlg = true
  const reader: PaginationInnerReader<TeamMemberCsvItem> = async (nextToken?: string | null) => {
    return execGqlRequest<ListTeamMemberCsvDataRawResponse>(customGql.listTeamMemberCsvData, { teamId, nextToken, notDeleteFlg }, accessToken).then(res => {
      if (res.data == null) throw Error('データの取得に失敗しました。')
      return {
        data: res.data.getTeam.teamMembers.items,
        nextToken: res.data.getTeam.teamMembers.nextToken
      }
    })
  }

  const memberGroups = await _listMemberGroup(accessToken, { teamId, groupId })

  const members = await new PaginationReader(reader, 300).readAll()

  const items = members.map(m => {
    const groups = memberGroups[m.id]
    return {
      ...m,
      groups: groups?.groups || [],
      leaderGroups: groups?.leaderGroups || []
    }
  }).filter(m => groupId == null || m.groups.length > 0)

  return { items }
}

export type GetTeamMemberCsvDataVariables = {
  input: {
    teamId: string
    groupId?: string
  }
}
type TeamMemberCsvItem = {
  id: string
  memberSeq: string
  nickname: string
  nicknameKana?: string | null
  role: MemberRole
}
type ListTeamMemberCsvDataRawResponse = {
  getTeam: {
    teamMembers: {
      items: TeamMemberCsvItem[]
      nextToken: string
    }
  }
}
export type GetTeamMemberCsvDataResponse = {
  items: (TeamMemberCsvItem & {
    groups: string[]
    leaderGroups: string[]
  })[]
}


/***
 * 以下、団体マッチング系API
 ***/


/***
 * M1. マッチング団体プロフィール系
 ***/


/**
 * M1-1. マッチング団体プロフィール登録API
 *
 * 団体マッチングに登録している団体のプロフィールを登録します。
 */
export const createMatchingTeamProfile = (accessToken: string, variables: CreateMatchingTeamProfileVariables & AppInfoVariables): Promise<GraphQLResult<CreateMatchingTeamProfileResponse>> => {
  const {input, appInfo} = variables
  const { teamId, ...rest } = input
  const variables_: autoGenAPI.CreateMatchingTeamProfileMutationVariables & AppInfoVariables = {
    input: {
      id: teamId,
      ...rest
    },
    appInfo
  }
  return execGqlRequest<CreateMatchingTeamProfileResponse>(autoGenMutations.createMatchingTeamProfile, variables_, accessToken)
}
export type CreateMatchingTeamProfileVariables = {
  input: Omit<autoGenAPI.CreateMatchingTeamProfileInput, 'id'> & {
    teamId: string
  }
}
export type CreateMatchingTeamProfileResponse = autoGenAPI.CreateMatchingTeamProfileMutation


/**
 * M1-2. マッチング団体プロフィール取得API
 *
 * 団体マッチングに登録している団体のプロフィールを取得します。
 */
export const getMatchingTeamProfile = (accessToken: string, variables: GetMatchingTeamProfileVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingTeamProfileResponse>> => {
  return execGqlRequest<GetMatchingTeamProfileResponse>(autoGenQueries.getMatchingTeamProfile, variables, accessToken)
}
export type GetMatchingTeamProfileVariables = autoGenAPI.GetMatchingTeamProfileQueryVariables
export type GetMatchingTeamProfileResponse = autoGenAPI.GetMatchingTeamProfileQuery


/**
 * M1-3. マッチング団体プロフィール更新API
 *
 * 団体マッチングに登録している団体のプロフィールを更新します。
 */
export const updateMatchingTeamProfile = (accessToken: string, variables: UpdateMatchingTeamProfileVariables & AppInfoVariables): Promise<GraphQLResult<UpdateMatchingTeamProfileResponse>> => {
  return execGqlRequest<UpdateMatchingTeamProfileResponse>(autoGenMutations.updateMatchingTeamProfile, variables, accessToken)
}
export type UpdateMatchingTeamProfileVariables = autoGenAPI.UpdateMatchingTeamProfileMutationVariables
export type UpdateMatchingTeamProfileResponse = autoGenAPI.UpdateMatchingTeamProfileMutation


/***
 * M2. マッチング募集
 ***/


/**
 * M2-1. マッチング募集一覧API
 *
 * 団体マッチングの募集を更新日時順で30件ずつ取得します。
 * 絞り込み条件を設定することで、データの絞り込みが可能です。
 */
export const listMatchingOffer = async (accessToken: string, variables: ListMatchingOfferVariables & AppInfoVariables): Promise<PaginationReader<MatchingOfferListItem>> => {
  const blockingTeamIds: Set<string> = variables.type === 'otherOffers' ? new Set(await _listBlockingMatchingTeamId(accessToken, { teamId: variables.teamId })) : new Set()
  const reader: PaginationInnerReader<MatchingOfferListItem> = async (nextToken?: string | null) => {
    const showAll = variables.type === 'myOffers'
    const res = await _listMatchingOffer({accessToken, variables, showAll, nextToken})
    if (res.errors && res.errors.length > 0) throw Error(res.errors[0].message)
    const offers = res.data?.searchMatchingOffers.items.filter(item => !blockingTeamIds.has(item.teamId)) || []
    if (variables.type === 'otherOffers') await _attachMatchingOfferFavoriteFlg(accessToken, variables.teamMemberId, offers)
    if (variables.type === 'myOffers') await _attachMatchingOfferApplication(accessToken, offers)
    return {
      data: offers,
      total: res.data?.searchMatchingOffers.total,
      nextToken: res.data?.searchMatchingOffers.nextToken
    }
  }
  const size = variables.limit != null ? variables.limit : 30
  return new PaginationReader(reader, size)
}
export const listMatchingOfferSp = async (accessToken: string, variables: ListMatchingOfferVariables & AppInfoVariables): Promise<MatchingOfferListResponse> => {
  let blockingTeamIds: Set<string> = new Set()
  
  if (variables.type === 'otherOffers') {
    const myBlockTeamIds = await _listBlockingMatchingTeamId(accessToken, { teamId: variables.teamId });
    const otherTeamIdsBlockMyTeam = await _listOtherTeamsBlockMyTeamId(accessToken, { teamId: variables.teamId });
    blockingTeamIds = new Set([...myBlockTeamIds, ...otherTeamIdsBlockMyTeam])
  }
  if (variables.filter && blockingTeamIds.size > 0) {
    const andCond = Array.from(blockingTeamIds).map(id => ({ teamId: { ne: id } } as AndFilterTeam))
    variables.filter.and = andCond
  }
    const showAll = variables.type === 'myOffers'
    
    const res = await _listMatchingOffer({accessToken, variables, showAll, nextToken: variables.nextToken })
    if (res.errors && res.errors.length > 0) throw Error(res.errors[0].message)
    const offers = res.data?.searchMatchingOffers.items.filter(item => !blockingTeamIds.has(item.teamId)) || []
    if (variables.type === 'otherOffers') await _attachMatchingOfferFavoriteFlg(accessToken, variables.teamMemberId, offers)
    if (variables.type === 'myOffers') await _attachMatchingOfferApplication(accessToken, offers)
    return {
      data: offers,
      total: res.data?.searchMatchingOffers.total ?? 0,
      nextToken: res.data?.searchMatchingOffers.nextToken
    }
}
type MatchingOfferListResponse = {
  data: MatchingOfferListItem[];
  total: number;
  nextToken: string | null | undefined;
};
type ListMatchingOfferParams = {
  accessToken: string
  variables: ListMatchingOfferVariables & AppInfoVariables
  showAll: boolean
  nextToken?: string | null
}

const _listMatchingOffer = ({accessToken, variables, showAll, nextToken}: ListMatchingOfferParams): Promise<GraphQLResult<ListMatchingOfferResponse>> => {
  const filter: autoGenAPI.SearchableMatchingOfferFilterInput = {}

  if (!showAll) {
    filter.publishEndDate = {
      gte: new Date().toISOString().split('T')[0]
    }
    filter.deleteFlg = {
      ne: true
    }
  }

  if (variables.type === 'myOffers') {
    filter.teamMatchingOffersId = { eq: variables.teamId }
  }
  if (variables.filter?.purpose) filter.purpose = { eq: variables.filter.purpose }
  if (variables.filter?.activityId) filter.matchingOfferActivityId = { eq: variables.filter.activityId }
  if (variables.filter?.dateFrom) {
    if (variables.filter?.dateTo) {
      filter.and = [
        { date: { gte: variables.filter.dateFrom } },
        { date: { lte: variables.filter.dateTo } },
      ]
    } else {
      filter.date = { gte: variables.filter.dateFrom }
    }
  } else {
    if (variables.filter?.dateTo) filter.date = { lte: variables.filter.dateTo }
  }
  if (variables.filter?.prefectureId) filter.matchingOfferPrefectureId = { eq: variables.filter.prefectureId }
  if (variables.filter?.cityId) filter.matchingOfferCityId = { eq: variables.filter.cityId }
  if (variables.filter?.ageFrom) filter.ageFrom = { gte: variables.filter.ageFrom }
  if (variables.filter?.ageTo) filter.ageTo = { lte: variables.filter.ageTo }
  if (variables.filter?.keyword) filter.or = [
    { title: { wildcard: `*${variables.filter.keyword}*` } },
    { title: { matchPhrase: variables.filter.keyword } },
    { body: { wildcard: `*${variables.filter.keyword}*` } },
    { body: { matchPhrase: variables.filter.keyword } },
    { teamName: { wildcard: `*${variables.filter.keyword}*` } },
    { teamName: { matchPhrase: variables.filter.keyword } },
  ]

  if(variables.filter?.and) filter.and = [...filter.and ?? [], ...variables.filter.and]

  const limit = variables.limit != null ? variables.limit : 30
  const variables_ = {
    filter,
    limit,
    nextToken: nextToken,
    appInfo: variables.appInfo
  }

  return execGqlRequest<ListMatchingOfferResponse>(customGql.listMatchingOffer, variables_, accessToken)
}

type AndFilterTeam = { teamId: { ne: string } }

export type ListMatchingOfferVariables = {
  teamId: string
  teamMemberId: string
  type: 'myOffers' | 'otherOffers'
  filter?: {
    purpose?: autoGenAPI.MatchingOfferPurpose
    activityId?: string
    dateFrom?: string
    dateTo?: string
    prefectureId?: string
    cityId?: string
    ageFrom?: number
    ageTo?: number
    keyword?: string
    and?: AndFilterTeam[]
  }
  limit?: number
  nextToken?:string
}
type MatchingOfferListItem = {
  id: string
  purpose: autoGenAPI.MatchingOfferPurpose
  title: string
  body: string
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefecture: {
    id: string
    label: string
  }
  city: {
    id: string
    label: string
  } | null
  ageFrom?: string | null
  ageTo?: string | null
  publishEndDate: string
  activity: {
    id: string
    label: string
  }
  teamId: string
  teamName: string
  deleteFlg?: boolean | null
  // listMatchingOfferにてtype === 'otherOffers' を選択した際のみ取得
  favoriteFlg?: boolean
  // listMatchingOfferにてtype === 'myOffers' を選択した際のみ取得
  applications?: {
    id: string
    messages: {
      items: {
        text: string
        messageSenderTeamId: string
        createdAt: string
      }[]
    }
  }[]
  createdAt: string
  updatedAt: string
}
export type ListMatchingOfferResponse = {
  searchMatchingOffers: {
    total?: number
    items: MatchingOfferListItem[]
    nextToken?: string | null
  }
}

const _multiGetFavoriteOfferId = (accessToken: string, variables: MultiGetFavoriteOfferIdVariables): Promise<GraphQLResult<MultiGetFavoriteOfferIdResponse>> => {
  return execGqlRequest<MultiGetFavoriteOfferIdResponse>(autoGenQueries.multiGetFavoriteOfferId, variables, accessToken)
}
export type MultiGetFavoriteOfferIdVariables = autoGenAPI.MultiGetFavoriteOfferIdQueryVariables
export type MultiGetFavoriteOfferIdResponse = autoGenAPI.MultiGetFavoriteOfferIdQuery

const _attachMatchingOfferFavoriteFlg = async (accessToken: string, teamMemberId: string, offers: Pick<MatchingOfferListItem, 'id' | 'favoriteFlg'>[]): Promise<void> => {
  if (offers.length === 0) return

  const favoriteOfferIds = await _multiGetFavoriteOfferId(accessToken, {
    input: {
      offerIds: offers.map(o => o.id),
      teamMemberId: teamMemberId
    }
  }).then(res => res.data?.multiGetFavoriteOfferId.favoriteOfferIds || [])

  offers.forEach(o => {
    o.favoriteFlg = favoriteOfferIds.includes(o.id)
  })
}

const _multiGetApplication = (accessToken: string, variables: MultiGetApplicationVariables): Promise<GraphQLResult<MultiGetApplicationResponse>> => {
  return execGqlRequest<MultiGetApplicationResponse>(customGql.multiGetApplication, variables, accessToken)
}
export type MultiGetApplicationVariables = autoGenAPI.MultiGetApplicationQueryVariables
export type MultiGetApplicationResponse = {
  multiGetApplication: {
    items: {
      offerId: string
      applications: {
        id: string
        messages: {
          text: string
          messageSenderTeamId: string
          createdAt: string
        }[]
      }[]
    }[]
  }
}

const _attachMatchingOfferApplication = async (accessToken: string, offers: Pick<MatchingOfferListItem, 'id' | 'applications'>[]): Promise<void> => {
  if (offers.length === 0) return

  const data = await _multiGetApplication(accessToken, {
    input: {
      offerIds: offers.map(o => o.id)
    }
  }).then(res => res.data?.multiGetApplication.items.map(item => ({
    offerId: item.offerId,
    applications: item.applications.map(application => ({
      id: application.id,
      messages: {
        items: application.messages
      }
    }))
  })) || [])

  offers.forEach(o => o.applications = data.find(d => d.offerId === o.id)?.applications || [])
}

/**
 * M2-2. マッチング仮募集ID取得API
 *
 * 募集作成前の、仮募集IDを取得します。仮募集IDは団体単位に発行され、未発行の場合は新規で発行されたIDを取得します。
 * このIDは、募集登録API実行時に募集が作成されたタイミングで削除されます。
 */
export const getOrCreateTempMatchingOfferId = (accessToken: string, variables: GetOrCreateTempMatchingOfferIdVariables & AppInfoVariables): Promise<GraphQLResult<GetOrCreateTempMatchingOfferIdResponse>> => {
  return execGqlRequest<GetOrCreateTempMatchingOfferIdResponse>(autoGenMutations.getOrCreateTempMatchingOfferId, variables, accessToken)
}
export type GetOrCreateTempMatchingOfferIdVariables = autoGenAPI.GetOrCreateTempMatchingOfferIdMutationVariables
export type GetOrCreateTempMatchingOfferIdResponse = autoGenAPI.GetOrCreateTempMatchingOfferIdMutation


/**
 * M2-3. マッチング募集登録API
 *
 * 団体マッチングの募集を登録します。
 */
export const createMatchingOffer = (accessToken: string, variables: CreateMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<CreateMatchingOfferResponse>> => {
  return execGqlRequest<CreateMatchingOfferResponse>(autoGenMutations.createMatchingOffer, variables, accessToken)
}
export type CreateMatchingOfferVariables = autoGenAPI.CreateMatchingOfferMutationVariables
export type CreateMatchingOfferResponse = autoGenAPI.CreateMatchingOfferMutation


/**
 * M2-4. マッチング募集取得API
 *
 * 団体マッチングの募集の詳細を取得します。
 */
export const getMatchingOffer = async (accessToken: string, variables: GetMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingOfferResponse>> => {
  const {id, type, teamMemberId, appInfo} = variables
  const res = await execGqlRequest<GetMatchingOfferResponse>(customGql.getMatchingOffer, { id, appInfo }, accessToken)
  if (res.data) {
    const offer = res.data.getMatchingOffer
    if (type === 'otherOffer') await _attachMatchingOfferFavoriteFlg(accessToken, teamMemberId, [offer])
    await _attachMatchingOfferApplication(accessToken, [offer])
  }
  return res
}
export type GetMatchingOfferVariables = {
  id: string,
  type: 'myOffer' | 'otherOffer'
  teamMemberId: string
}
type MatchingOffer = {
  id: string
  purpose: autoGenAPI.MatchingOfferPurpose
  teamId: string
  teamName: string
  deleteFlg?: boolean | null
  activity: {
    id: string
    label: string
  }
  title: string
  body: string
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefecture: {
    id: string
    label: string
  }
  city?: {
    id: string
    label: string
  } | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate: string
  attachmentFileNames?: string[] | null
  // type === 'otherOffer' を選択した際のみ取得
  favoriteFlg?: boolean
  applications?: {
    id: string
    messages: {
      items: {
        text: string
        messageSenderTeamId: string
        createdAt: string
      }[]
    }
  }[]
  createdAt: string
  updatedAt: string
}
export type GetMatchingOfferResponse = {
  getMatchingOffer: MatchingOffer
}


/**
 * M2-5. マッチング募集更新API
 *
 * 団体マッチングの募集を更新します。
 */
export const updateMatchingOffer = (accessToken: string, variables: UpdateMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<UpdateMatchingOfferResponse>> => {
  const {input, appInfo} = variables
  const {prefectureId, cityId, ...rest} = input
  const variables_: autoGenAPI.UpdateMatchingOfferMutationVariables & AppInfoVariables= {
    input: {
      matchingOfferPrefectureId: prefectureId,
      matchingOfferCityId: cityId,
      ...rest
    },
    appInfo
  }
  return execGqlRequest<UpdateMatchingOfferResponse>(customGql.updateMatchingOffer, variables_, accessToken)
}
export type UpdateMatchingOfferVariables = {
  input: Omit<autoGenAPI.UpdateMatchingOfferInput, 'teamMatchingOffersId' | 'matchingOfferPrefectureId' | 'matchingOfferCityId'> & {
    prefectureId?: string | null
    cityId?: string | null
  }
}
export type UpdateMatchingOfferResponse = {
  updateMatchingOffer: {
    id: string
  }
}


/**
 * M2-6. マッチング募集削除API
 *
 * 団体マッチングの募集を削除します。
 */
export const deleteMatchingOffer = (accessToken: string, variables: DeleteMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<DeleteMatchingOfferResponse>> => {
  return updateMatchingOffer(accessToken, {
    input: {
      id: variables.input.id,
      deleteFlg: true
    },
    appInfo: variables.appInfo
  }).then(res => {
    if (!res.data) return {
      data: undefined,
      errors: res.errors,
      extensions: res.extensions
    }

    return {
      data: {
        deleteMatchingOffer: res.data.updateMatchingOffer
      }
    }
  })
}
export type DeleteMatchingOfferVariables = {
  input: {
    id: string
  }
}
export type DeleteMatchingOfferResponse = {
  deleteMatchingOffer: {
    id: string
  }
}


/***
 * M3. マッチング募集下書き
 ***/


/**
 * M3-1. マッチング募集下書き一覧API
 *
 * 団体マッチングの募集の下書きの一覧を更新日時順で降順ですべて取得します。
 */
export const listMatchingOfferDraft = (accessToken: string, variables: ListMatchingOfferDraftVariables & AppInfoVariables): Promise<GraphQLResult<ListMatchingOfferDraftResponse>> => {
  return execGqlRequest<ListMatchingOfferDraftResponse>(customGql.listMatchingOfferDraft, variables, accessToken)
}
export type ListMatchingOfferDraftVariables = {
  teamId: string
}
export type ListMatchingOfferDraftResponse = {
  getTeam: {
    activity: {
      id: string
      label: string
    }
    matchingOfferDrafts: {
      items: (Partial<Omit<MatchingOfferListItem, 'id' | 'title' | 'activity'>> & {
        id: string
        title: string
      })[]
    }
  }
}


/**
 * M3-2. マッチング募集下書き登録API
 *
 * 団体マッチングの募集の下書きを登録します。
 */
export const createMatchingOfferDraft = (accessToken: string, variables: CreateMatchingOfferDraftVariables & AppInfoVariables): Promise<GraphQLResult<CreateMatchingOfferDraftResponse>> => {
  return execGqlRequest<CreateMatchingOfferDraftResponse>(autoGenMutations.createMatchingOfferDraft, variables, accessToken)
}
export type CreateMatchingOfferDraftVariables = autoGenAPI.CreateMatchingOfferDraftMutationVariables
export type CreateMatchingOfferDraftResponse = autoGenAPI.CreateMatchingOfferDraftMutation


/**
 * M3-3. マッチング募集下書き取得API
 *
 * 団体マッチングの募集の下書きの詳細を取得します。
 */
export const getMatchingOfferDraft = (accessToken: string, variables: GetMatchingOfferDraftVariables & AppInfoVariables): Promise<GraphQLResult<GetMatchingOfferDraftResponse>> => {
  return execGqlRequest<GetMatchingOfferDraftResponse>(customGql.getMatchingOfferDraft, variables, accessToken)
}
export type GetMatchingOfferDraftVariables = {
  id: string
}
type MatchingOfferDraft = {
  id: string
  team: {
    id: string
    name: string
    activity: {
      id: string
      label: string
    }
  }
  purpose?: autoGenAPI.MatchingOfferPurpose | null
  title: string
  body?: string | null
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefecture?: {
    id: string
    label: string
  } | null
  city?: {
    id: string
    label: string
  } | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate?: string | null
  createdAt: string
  updatedAt: string
}
export type GetMatchingOfferDraftResponse = {
  getMatchingOfferDraft: MatchingOfferDraft
}


/**
 * M3-4. マッチング募集下書き更新API
 *
 * 団体マッチングの募集の下書きを更新します。
 */
export const updateMatchingOfferDraft = (accessToken: string, variables: UpdateMatchingOfferDraftVariables & AppInfoVariables): Promise<GraphQLResult<UpdateMatchingOfferDraftResponse>> => {
  const {input, appInfo} = variables
  const { prefectureId, cityId, ...rest } = input
  const variables_: autoGenAPI.UpdateMatchingOfferDraftMutationVariables & AppInfoVariables = {
    input: {
      matchingOfferDraftPrefectureId: prefectureId,
      matchingOfferDraftCityId: cityId,
      ...rest
    },
    appInfo
  }
  return execGqlRequest<UpdateMatchingOfferDraftResponse>(customGql.updateMatchingOfferDraft, variables_, accessToken)
}
export type UpdateMatchingOfferDraftVariables = {
  input: Omit<autoGenAPI.UpdateMatchingOfferDraftInput, 'teamMatchingOfferDraftsId' | 'matchingOfferDraftPrefectureId' | 'matchingOfferDraftCityId'> & {
    prefectureId?: string
    cityId?: string
  }
}
export type UpdateMatchingOfferDraftResponse = {
  updateMatchingOfferDraft: {
    id: string
  }
}

/**
 * M3-5. マッチング募集下書き削除API
 *
 * 団体マッチングの募集の下書きを削除します。
 */
export const deleteMatchingOfferDraft = (accessToken: string, variables: DeleteMatchingOfferDraftVariables & AppInfoVariables): Promise<GraphQLResult<DeleteMatchingOfferDraftResponse>> => {
  return execGqlRequest<DeleteMatchingOfferDraftResponse>(customGql.deleteMatchingOfferDraft, variables, accessToken)
}
export type DeleteMatchingOfferDraftVariables = {
  input: Omit<autoGenAPI.DeleteMatchingOfferDraftInput, 'teamMatchingOfferDraftsId' | 'matchingOfferDraftPrefectureId' | 'matchingOfferDraftCityId'>
}
export type DeleteMatchingOfferDraftResponse = {
  deleteMatchingOfferDraft: {
    id: string
  }
}


/***
 * M4. マッチングお気に入り募集系
 ***/


/**
 * M4-1. マッチングお気に入り募集一覧API
 *
 * 団体マッチングのお気に入り募集の一覧を取得します。
 */
export const listFavoriteMatchingOffer = async (accessToken: string, variables: ListFavoriteMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<ListFavoriteMatchingOfferResponse>> => {
  const variables_ = {
    teamMemberId: variables.teamMemberId,
    limit: variables.limit != null ? variables.limit : 30,
    nextToken: variables.nextToken
  }
  const res = await execGqlRequest<ListFavoriteMatchingOfferResponse>(customGql.listFavoriteMatchingOffer, variables_, accessToken)
  if (res.data) {
    res.data.getTeamMember.favoriteMatchingOffer.items = res.data.getTeamMember.favoriteMatchingOffer.items.filter(item => filterSearchMatchingOffer(item.offer, variables.search)).map(item => ({...item, offer: {...item.offer, favoriteFlg: true}}))
  }
  return res
}

const filterSearchMatchingOffer = (data: MatchingOfferListItem, search?: string) => {
  if (!search) return true;
  const regex = new RegExp(search, "gi")
  return data.title.match(regex) || data.body.match(regex) || data.teamName.match(regex)
}

export type ListFavoriteMatchingOfferVariables = {
  teamMemberId: string
  search?: string
  limit?: number
  nextToken?: string
}
export type ListFavoriteMatchingOfferResponse = {
  getTeamMember: {
    favoriteMatchingOffer: {
      items: {
        offer: MatchingOfferListItem
      }[]
      nextToken?: string
    }
  }
}


/**
 * M4-2. マッチングお気に入り募集登録API
 *
 * 団体マッチングの募集をお気に入りに登録します。
 */
export const createFavoriteMatchingOffer = (accessToken: string, variables: CreateFavoriteMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<CreateFavoriteMatchingOfferResponse>> => {
  const variables_: autoGenAPI.CreateFavoriteMatchingOfferMutationVariables & AppInfoVariables = {
    input: {
      id: `${variables.teamMemberId}.${variables.matchingOfferId}`,
      teamMemberFavoriteMatchingOfferId: variables.teamMemberId,
      favoriteMatchingOfferOfferId: variables.matchingOfferId
    },
    appInfo: variables.appInfo
  }
  return execGqlRequest<CreateFavoriteMatchingOfferResponse>(customGql.createFavoriteMatchingOffer, variables_, accessToken)
}
export type CreateFavoriteMatchingOfferVariables = {
  teamMemberId: string
  matchingOfferId: string
}
export type CreateFavoriteMatchingOfferResponse = {
  createFavoriteMatchingOffer: {
    id: string
  }
}


/**
 * M4-3. マッチングお気に入り募集削除API
 *
 * 団体マッチングの募集をお気に入りから除外します。
 */
export const deleteFavoriteMatchingOffer = (accessToken: string, variables: DeleteFavoriteMatchingOfferVariables & AppInfoVariables): Promise<GraphQLResult<DeleteFavoriteMatchingOfferResponse>> => {
  const variables_: autoGenAPI.DeleteFavoriteMatchingOfferMutationVariables & AppInfoVariables = {
    input: {
      id: `${variables.teamMemberId}.${variables.matchingOfferId}`,
    },
    appInfo: variables.appInfo
  }
  return execGqlRequest<DeleteFavoriteMatchingOfferResponse>(customGql.deleteFavoriteMatchingOffer, variables_, accessToken)
}
export type DeleteFavoriteMatchingOfferVariables = {
  teamMemberId: string
  matchingOfferId: string
}
export type DeleteFavoriteMatchingOfferResponse = {
  deleteFavoriteMatchingOffer: {
    id: string
  }
}


/***
 * M5. マッチング応募系
 ***/


/**
 * M5-1. マッチング応募一覧API
 *
 * 団体マッチングの応募を更新日時順で30件ずつ取得します。
 * 募集の本文で、部分一致で検索条件を設定できます。
 */
export const listMatchingApplication = (accessToken: string, variables: ListMatchingApplicationVariables & AppInfoVariables): Promise<GraphQLResult<ListMatchingApplicationResponse>> => {
  const { teamId, limit, nextToken, appInfo } = variables
  const filter: autoGenAPI.SearchableMatchingApplicationFilterInput = {
    teamMatchingApplicationsId: {
      eq: teamId
    }
  }
  if (variables.filter?.keyword) {
    filter.or = [
      { offerTeamName: { wildcard: `*${variables.filter.keyword}*` } },
      { offerTeamName: { matchPhrase: variables.filter.keyword } },
      { offerTitle: { wildcard: `*${variables.filter.keyword}*` } },
      { offerTitle: { matchPhrase: variables.filter.keyword } },
      { offerBody: { wildcard: `*${variables.filter.keyword}*` } },
      { offerBody: { matchPhrase: variables.filter.keyword } },
    ]
  }

  const variables_ = {
    filter,
    limit: limit != null ? limit : 30,
    nextToken,
    notDeleteFlg: 1,
    appInfo
  }
  return execGqlRequest<ListMatchingApplicationResponse>(customGql.listMatchingApplication, variables_, accessToken)
}
export type ListMatchingApplicationVariables = {
  teamId: string
  filter?: {
    keyword?: string
  }
  limit?: number
  nextToken?: string
}
export type ListMatchingApplicationResponse = {
  searchMatchingApplications: {
    items: {
      id: string
      messages: {
        items: {
          text: string
          messageSenderTeamId: string
          createdAt: string
        }[]
      }
      offer: MatchingOfferListItem
    }[]
    nextToken?: string
  }
}


/**
 * M5-2. マッチング応募登録API
 *
 * 団体マッチングの募集を登録します。
 */
export const createMatchingApplication = (accessToken: string, variables: CreateMatchingApplicationVariables & AppInfoVariables): Promise<GraphQLResult<CreateMatchingApplicationResponse>> => {
  return execGqlRequest<CreateMatchingApplicationResponse>(autoGenMutations.createMatchingApplication, variables, accessToken)
}
export type CreateMatchingApplicationVariables = autoGenAPI.CreateMatchingApplicationMutationVariables
export type CreateMatchingApplicationResponse = autoGenAPI.CreateMatchingApplicationMutation


/***
 * M6. マッチングメッセージ系
 ***/


/**
 * M6-1. マッチングメッセージ一覧API
 *
 * マッチングメッセージの一覧を作成日時の降順で30件ずつ取得します。
 */
export const listMatchingMessage = (accessToken: string, variables: ListMatchingMessageVariables & AppInfoVariables): Promise<GraphQLResult<ListMatchingMessageResponse>> => {
  const {limit, ...rest} = variables
  const variables_ = {
    limit: limit != null ? limit : 30,
    ...rest
  }
  return execGqlRequest<ListMatchingMessageResponse>(customGql.listMatchingMessage, variables_, accessToken)
}
export type ListMatchingMessageVariables = {
  matchingApplicationId: string
  limit?: number | null
  nextToken?: string | null
}
export type ListMatchingMessageResponse = {
  getMatchingApplication: {
    messagesIncludeDeleted: {
      items: {
        id: string
        messageSenderTeamId: string
        text: string
        unreadFlg: number // 0: 既読, 1: 未読
        deleteFlg?: boolean | null
        contentUpdatedAt: string
        createdAt: string
      }[]
      nextToken?: string | null
    }
  }
}


/**
 * M6-2. マッチングメッセージ登録API
 *
 * マッチングメッセージを登録します。
 */
export const createMatchingMessage = (accessToken: string, variables: CreateMatchingMessageVariables & AppInfoVariables): Promise<GraphQLResult<CreateMatchingMessageResponse>> => {
  return execGqlRequest<CreateMatchingMessageResponse>(autoGenMutations.createMatchingMessage, variables, accessToken)
}
export type CreateMatchingMessageVariables = autoGenAPI.CreateMatchingMessageMutationVariables
export type CreateMatchingMessageResponse = autoGenAPI.CreateMatchingMessageMutation


/**
 * M6-3. マッチングメッセージ更新API
 *
 * マッチングメッセージを更新します。
 */
export const updateMatchingMessage = (accessToken: string, variables: UpdateMatchingMessageVariables & AppInfoVariables): Promise<GraphQLResult<UpdateMatchingMessageResponse>> => {
  return execGqlRequest<UpdateMatchingMessageResponse>(autoGenMutations.updateMatchingMessage, variables, accessToken)
}
export type UpdateMatchingMessageVariables = autoGenAPI.UpdateMatchingMessageMutationVariables
export type UpdateMatchingMessageResponse = autoGenAPI.UpdateMatchingMessageMutation

export const markMatchingMessageAsRead = (accessToken: string, variables: MarkMatchingMessageAsReadVariables & AppInfoVariables): Promise<GraphQLResult<MarkMatchingMessageAsReadResponse>> => {
  return execGqlRequest<MarkMatchingMessageAsReadResponse>(autoGenMutations.markMatchingMessageAsRead, variables, accessToken)
}
export type MarkMatchingMessageAsReadVariables = autoGenAPI.MarkMatchingMessageAsReadMutationVariables
export type MarkMatchingMessageAsReadResponse = autoGenAPI.MarkMatchingMessageAsReadMutation

/**
 * M6-4. 未読マッチングメッセージ件数取得API
 *
 * 未読のマッチングメッセージ件数を取得します。
 */
export const getUnreadMatchingMessageCount = (accessToken: string, variables: GetUnreadMatchingMessageCountVariables & AppInfoVariables): Promise<GraphQLResult<GetUnreadMatchingMessageCountResponse>> => {
  const variables_ = {
    input: {
      teamId: variables.myTeamId
    }
  }
  return execGqlRequest<GetUnreadMatchingMessageCountRawResponse>(customGql.getUnreadMatchingMessageForTeamCount, variables_, accessToken).then(res => {
    if (!res.data) return {
      data: undefined,
      errors: res.errors,
      extensions: res.extensions,
    }
    if (!variables.applicationId) {
      return {
        data: {
          unreadMessageCount: 0,
          offerMessage: res.data.getUnreadMatchingMessageForTeamCount.matchingOffer.haveUnreadMessage,
          applicationMessage: res.data.getUnreadMatchingMessageForTeamCount.matchingApplication.haveUnreadMessage
        }
      }
    }
    if (res.data.getUnreadMatchingMessageForTeamCount.matchingApplication.haveUnreadMessage) {
      return {
        data: {
          unreadMessageCount: res.data.getUnreadMatchingMessageForTeamCount.matchingApplication.unreadMatchingApplicationCount.find(el => el.id === variables.applicationId)?.count ?? 0
        }
      }
    }
    if (res.data.getUnreadMatchingMessageForTeamCount.matchingOffer.haveUnreadMessage) {
      return {
        data: {
          unreadMessageCount: res.data.getUnreadMatchingMessageForTeamCount.matchingOffer.unreadMatchingApplicationCount.find(el => el.id === variables.applicationId)?.count ?? 0
        }
      }
    }
    return {
      data: {
        unreadMessageCount: 0
      }
    }
  })
}

export type GetUnreadMatchingMessageCountVariables = {
  applicationId?: string | string[]
  myTeamId: string
}

type GetUnreadMatchingMessageCountRawResponse = {
  getUnreadMatchingMessageForTeamCount: {
    matchingOffer: {
      haveUnreadMessage: boolean,
      unreadMatchingApplicationCount: {
        id: string,
        count: number
      }[]
    },
    matchingApplication: {
      haveUnreadMessage: boolean,
      unreadMatchingApplicationCount: {
        id: string,
        count: number
      }[]
    }
  }
}
export type GetUnreadMatchingMessageCountResponse = {
  unreadMessageCount: number,
  offerMessage?: boolean,
  applicationMessage?: boolean
}


/***
 * M7. マッチングブロック/通報系
 ***/


/**
 * M7-1. マッチング団体ブロックAPI
 *
 * 団体管理者によって、特定団体をブロック状態とします。ブロックすることで、
 * ・やりとり相手から送られてくるメッセージは表示されない
 * ・ブロックした相手団体の募集は検索で出てこない
 * となります。
 */
export const blockMatchingTeam = (accessToken: string, variables: BlockMatchingTeamVariables & AppInfoVariables): Promise<GraphQLResult<BlockMatchingTeamResponse>> => {
  const variables_: autoGenAPI.CreateBlockingMatchingTeamMutationVariables & AppInfoVariables= {
    input: {
      id: `${variables.myTeamId}.${variables.blockingTeamId}`,
      blockingTeamId: variables.blockingTeamId,
      teamBlockingMatchingTeamsId: variables.myTeamId
    },
    appInfo: variables.appInfo
  }
  return execGqlRequest<BlockMatchingTeamResponse>(autoGenMutations.createBlockingMatchingTeam, variables_, accessToken)
}
export type BlockMatchingTeamVariables = {
  myTeamId: string
  blockingTeamId: string
}
export type BlockMatchingTeamResponse = {
  createBlockingMatchingTeam: {
    id: string
  }
}


/**
 * M7-2. マッチング団体ブロック解除API
 *
 * ブロック状態の団体を解除します。
 */
export const deleteBlockingMatchingTeam = (accessToken: string, variables: DeleteBlockingMatchingTeamVariables & AppInfoVariables): Promise<GraphQLResult<DeleteBlockingMatchingTeamResponse>> => {
  const variables_: autoGenAPI.DeleteBlockingMatchingTeamMutationVariables & AppInfoVariables = {
    input: {
      id: `${variables.myTeamId}.${variables.blockingTeamId}`,
    },
    appInfo: variables.appInfo
  }
  return execGqlRequest<DeleteBlockingMatchingTeamResponse>(autoGenMutations.deleteBlockingMatchingTeam, variables_, accessToken)
}
export type DeleteBlockingMatchingTeamVariables = {
  myTeamId: string
  blockingTeamId: string
}
export type DeleteBlockingMatchingTeamResponse = autoGenAPI.DeleteBlockingMatchingTeamMutation


/**
 * M7-3. ブロック中マッチング団体ID一覧API
 *
 * 団体マッチングにおいてブロック中の団体IDの一覧を全て取得します。
 */
const _listBlockingMatchingTeamId = (accessToken: string, variables: ListBlockingMatchingTeamVariables): Promise<string[]> => {
  const reader: PaginationInnerReader<string> = async (nextToken?: string | null) => {
    const res = await _listBlockingMatchingTeam(accessToken, variables, nextToken)
    if (res.errors && res.errors.length > 0) throw Error(res.errors[0].message)
    return {
      data: res.data?.getTeam.blockingMatchingTeams.items.map(item => item.blockingTeamId) || [],
      nextToken: res.data?.getTeam.blockingMatchingTeams.nextToken
    }
  }
  return new PaginationReader(reader, 100).readAll()
}

const _listBlockingMatchingTeam = (accessToken: string, variables: ListBlockingMatchingTeamVariables, nextToken?: string | null): Promise<GraphQLResult<ListBlockingMatchingTeamResponse>> => {
  const variables_ = {
    teamId: variables.teamId,
    nextToken
  }
  return execGqlRequest<ListBlockingMatchingTeamResponse>(customGql.listBlockingMatchingTeam, variables_, accessToken)
}

const _listOtherTeamsBlockMyTeamId = (accessToken: string, variables: ListBlockingMatchingTeamVariables): Promise<string[]> => {
  const reader: PaginationInnerReader<string> = async (nextToken?: string | null) => {
    const res = await _listOtherTeamsBlockMyTeam(accessToken, variables, nextToken)
    if (res.errors && res.errors.length > 0) throw Error(res.errors[0].message)
    return {
      data: res.data?.getTeam.blockingMatchingTeamsByBlockingTeamId.items.map(item => item.teamBlockingMatchingTeamsId) || [],
      nextToken: res.data?.getTeam.blockingMatchingTeamsByBlockingTeamId.nextToken
    }
  }
  return new PaginationReader(reader, 100).readAll()
}

const _listOtherTeamsBlockMyTeam = (accessToken: string, variables: ListBlockingMatchingTeamVariables, nextToken?: string | null): Promise<GraphQLResult<ListOtherTeamsBlockMyTeamResponse>> => {
  const variables_ = {
    teamId: variables.teamId,
    nextToken
  }
  return execGqlRequest<ListOtherTeamsBlockMyTeamResponse>(customGql.listOtherTeamsBlockMyTeam, variables_, accessToken)
}

export type ListBlockingMatchingTeamVariables = {
  teamId: string
}
export type ListBlockingMatchingTeamResponse = {
  getTeam: {
    blockingMatchingTeams: {
      items: {
        blockingTeamId: string
      }[]
      nextToken?: string | null
    }
  }
}

export type ListOtherTeamsBlockMyTeamResponse = {
  getTeam: {
    blockingMatchingTeamsByBlockingTeamId: {
      items: {
        teamBlockingMatchingTeamsId: string
      }[]
      nextToken?: string | null
    }
  }
}


/**
 * M7-4. マッチング団体通報API
 *
 * 団体管理者によって、特定団体を通報し、らくらく連絡網+の管理者(CS担当者)にメール送信します。
 */
export const reportMatchingTeam = (accessToken: string, variables: ReportMatchingTeamVariables & AppInfoVariables): Promise<GraphQLResult<ReportMatchingTeamResponse>> => {
  return execGqlRequest<ReportMatchingTeamResponse>(autoGenMutations.reportMatchingTeam, variables, accessToken)
}
export type ReportMatchingTeamVariables = autoGenAPI.ReportMatchingTeamMutationVariables
export type ReportMatchingTeamResponse = autoGenAPI.ReportMatchingTeamMutation




/**
 * エラー時の返り値の型
 *
 * API利用者が主に利用するのは、`response.errorsの要素の`以下の値です。
 * - message: システム開発者向けのメッセージ
 * - errorInfo.code: エラーコード
 * - errorInfo.userMessage: エンドユーザー向けのエラーメッセージ
 * - errorInfo.detail: エラーの詳細データ。オブジェクト定義はエラーの内容ごとに異なる
 *
 * その他の値はAmplifyによって自動的に出力されるため、必要に応じて参照してください。
 * (Amplifyによって自動生成されるエラーの定義に基準としてエラーの型を定義しています)
 */
export type GqlError = {
  data?: {
    [key: string]: any
  },
  errors?: {
    message?: string
    errorInfo?: {
      code?: number,
      userMessage?: string,
      userMessageWithCode?: string
      detail?: object
    },
    path?: string[],
    data?: any,
    errorType?: string | null,
    locations?: [object]
  }[]
}


/**
 * 以下、Enum定義
 */
export type MemberRole =
  'general' |
  'mailSender' |
  'manager'

export enum TeamNoticeType {
  joinRequested = 'joinRequested',
  mailSendFailed = 'mailSendFailed',
  hasMailSendFailedMember = 'hasMailSendFailedMember',
  remindToAnswer = 'remindToAnswer',
  joinRequestApproved = 'joinRequestApproved',
  matchingMessage = 'matchingMessage',
  transferApplicant = 'transferApplicant',
  paymentDeadline = 'paymentDeadline',
  startExtendOrderTime = 'startExtendOrderTime',
  middleExtendOrderTime = 'middleExtendOrderTime',
}


/**
 * 以下、ユーティリティメソッド
 */
const getRelativeDate = (date: Date, diff: {date?: number, hour?: number}): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (diff.date || 0), date.getHours() + (diff.hour || 0))
}

const dateEqual = (left: Date, right: Date) => {
  return left.getFullYear() === right.getFullYear() && left.getMonth() === right.getMonth() && left.getDate() === right.getDate()
}

const removeUndefined = <T extends object>(obj: T): Partial<T> => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined)) as Partial<T>
}

export const sort = <T, U>(li: T[], key: (item: T) => U, order: 'ASC' | 'DESC' = 'ASC'): T[] => {
  const copy = [...li]
  const orderFactor = order === 'ASC' ? 1 : -1
  copy.sort((l, r) => (key(l) < key(r) ? -1 * orderFactor : 1 * orderFactor))
  return copy
}

const notNull = <T>(item: T | undefined): item is T => item != null

type PaginationInnerReader<T> = (nextToken?: string | null) => Promise<{ data: T[], total?: number, nextToken?: string | null }>
export class PaginationReader<T> {
  private buffer: T[]
  private initialRead = true
  private readonly innerReader: PaginationInnerReader<T>
  private readonly pageSize: number
  private nextToken: string | undefined | null = undefined
  protected total: number | undefined;

  constructor(reader: PaginationInnerReader<T>, pageSize: number) {
    this.buffer = []
    this.innerReader = reader
    this.pageSize = pageSize
  }
  async read(pageIndex: number): Promise<{ data: T[], total: number }> {
    const requiredBufferSize = (pageIndex + 1) * this.pageSize
    if (this.buffer.length < requiredBufferSize) {
      await this.fillBuffer(requiredBufferSize)
    }
    const pageData = this.buffer.slice(pageIndex * this.pageSize, (pageIndex + 1) * this.pageSize)
    return { data: pageData, total: this.total?? 0 }
  }

  async readAll(): Promise<T[]> {
    let index = 0
    do {
      await this.read(index)
      index += 1
    } while(this.nextToken != null)

    return [...this.buffer]
  }

  private async fillBuffer(size: number) {
    if (!this.initialRead && this.nextToken == null) return

    this.initialRead = false
    do {
      const {data, nextToken, total} = await this.innerReader(this.nextToken)
      this.buffer = [...this.buffer, ...data]
      this.nextToken = nextToken
      if (total) {
        this.total = total
      }
    } while (this.nextToken != null && this.buffer.length < size)
  }
}

export const getMailQuery = async (accessToken: string, variables: GetMailQueryVariables & AppInfoVariables): Promise<GraphQLResult<string | null | undefined>> => {
  const mail = await execGqlRequest<GetMailQueryResponse>(autoGenQueries.getMail, {id: variables.mailId}, accessToken)
  return {
    data: mail.data?.getMail?.sender?.account?.id
  }
}
export type GetMailQueryVariables = {
  mailId: string
  teamMemberId?:string
  count?: number

}
type GetMailQueryResponse = autoGenAPI.GetMailQuery


export const getAccountPermission = (accessToken: string, variables: GetAccountPermissionVariables & AppInfoVariables): Promise<GraphQLResult<GetAccountPermissionResponse>> => {
  return execGqlRequest<GetAccountPermissionResponse>(customGql.getFullPermissionAccount, variables, accessToken)
}
export type GetAccountPermissionVariables = autoGenAPI.GetPermissionAccountQueryVariables
export type GetAccountPermissionResponse = {
  getPermissionAccount?: AccountPermission
}

export const sendMigrationInvitationEmail = (accessToken: string, variables: SendMigrationInvitationEmailMutationVariables & AppInfoVariables): Promise<GraphQLResult<SendMigrationInvitationEmailResponse>> => {
  return execGqlRequest<SendMigrationInvitationEmailResponse>(autoGenMutations.sendMigrationInvitationEmail, variables, accessToken)
}
export type SendMigrationInvitationEmailMutationVariables = autoGenAPI.SendMigrationInvitationEmailMutationVariables
export type SendMigrationInvitationEmailResponse = autoGenAPI.SendMigrationInvitationEmailMutation

export const getListPlan = (accessToken: string, variables?: AppInfoVariables): Promise<GraphQLResult<GetListPlanResponse>> => {
  return execGqlRequest<GetListPlanResponse>(autoGenQueries.listPlan, variables || {}, accessToken)
}
export type GetListPlanResponse = autoGenAPI.ListPlanQuery

export const createPaidVersionOrder = (accessToken: string,  variables: CreatePaidVersionOrderVariables): Promise<GraphQLResult<CreatePaidVersionOrderResponse>> => {
  return execGqlRequest<CreatePaidVersionOrderResponse>(autoGenMutations.createPaidVersionOrder, variables, accessToken)
}

export type CreatePaidVersionOrderResponse = autoGenAPI.CreatePaidVersionOrderMutation
export type CreatePaidVersionOrderVariables = autoGenAPI.CreatePaidVersionOrderMutationVariables

export const getTeamOrder = (accessToken: string,  variables: GetTeamOrderQueryVariables): Promise<GraphQLResult<GetTeamOrderResponse>> => {
  return execGqlRequest<GetTeamOrderResponse>(autoGenQueries.getTeamOrder, variables, accessToken)
}

export type GetTeamOrderResponse = autoGenAPI.GetTeamOrderQuery
export type GetTeamOrderQueryVariables = autoGenAPI.GetTeamOrderQueryVariables

export const cancelPaidOrderVersion = (accessToken: string,  variables: cancelPaidOrderVersionQueryVariables): Promise<GraphQLResult<cancelPaidOrderVersionResponse>> => {
  return execGqlRequest<cancelPaidOrderVersionResponse>(autoGenMutations.cancelPaidVersionOrder, variables, accessToken)
}

export type cancelPaidOrderVersionResponse = autoGenAPI.CancelPaidVersionOrderMutation
export type cancelPaidOrderVersionQueryVariables = autoGenAPI.CancelPaidVersionOrderMutationVariables

export const searchGroupMember = (accessToken: string, variables: SearchGroupMemberVariables & AppInfoVariables): Promise<GraphQLResult<SearchGroupMemberResponse>> => {
  return execGqlRequest<SearchGroupMemberResponse>(autoGenQueries.searchGroupMember, variables, accessToken)
}

export type SearchGroupMemberVariables = autoGenAPI.SearchGroupMemberQueryVariables
export type SearchGroupMemberResponse = {
  searchGroupMember: autoGenAPI.SearchGroupMemberResponse
}

export const createPaidTeamInquiry = (accessToken: string, variables: CreatePaidTeamInquiryVariables & AppInfoVariables): Promise<GraphQLResult<CreatePaidTeamInquiryResponse>> => {
  return execGqlRequest<CreatePaidTeamInquiryResponse>(autoGenMutations.createPaidTeamInquiry, variables, accessToken)
}
export type CreatePaidTeamInquiryVariables = autoGenAPI.CreatePaidTeamInquiryMutationVariables
export type CreatePaidTeamInquiryResponse = autoGenAPI.CreatePaidTeamInquiryMutation


export type Permission = {
  accessAll: boolean
  groupIds: string[]
}

export type MailPermission = {
  showRecipientCount: Permission
  showRecipientListMembers: Permission
  showReadCount: Permission
  showReadListMembers: Permission
  showAnsweredCount: Permission
  showAnsweredListMembers: Permission
  showAnswerResultCount: Permission
  showAnswerResultListMembers: Permission
}

export type AccountPermission = {
  teamId: string
  management: {
    team: {
      canSetting: boolean
      canDelete: boolean
    },
    member: {
      canInviteTeam: boolean
      canApproveMember: boolean
      canRoleSetting: boolean
      canDeleteMember: boolean
      canUpdateMemberInfo: boolean
      canExportCsv: boolean
    },
    group: {
      canCreateGroup: boolean
      canUpdateGroup: boolean
      canUpdateMemberInfo: boolean
      canDeleteGroup: boolean
    }
  },
  list: {
    canVisibleListMember: boolean
    listGroup: Permission
  },
  mail: {
    make: Permission,
    recv: {
      memberListPublishOn: MailPermission,
      memberListPublishOff: MailPermission,
      send: {
        memberListPublishOn: MailPermission,
        memberListPublishOff: MailPermission
      }
    },
  },
  order: {
    showOrder: boolean
    canCreateOrder: boolean
    canTransferApplicant: boolean
  }
}
export const changePlanPaidOrder = (accessToken: string, variables: ChangePlanVariables & AppInfoVariables): Promise<GraphQLResult<ChangePlanResponse>> => {
  return execGqlRequest<ChangePlanResponse>(autoGenMutations.changePlanPaidVersionOrder, variables, accessToken)
}
export type ChangePlanVariables = autoGenAPI.ChangePlanPaidVersionOrderMutationVariables
export type ChangePlanResponse = autoGenAPI.ChangePlanPaidVersionOrderMutation
export const changeApplicantInfo = (accessToken: string, variables: ChangeApplicantInfoVariables & AppInfoVariables): Promise<GraphQLResult<ChangeApplicantInfoResponse>> => {
  return execGqlRequest<ChangeApplicantInfoResponse>(autoGenMutations.updatePaidVersionOrder, variables, accessToken)
}
export type ChangeApplicantInfoVariables = autoGenAPI.UpdatePaidVersionOrderMutationVariables
export type ChangeApplicantInfoResponse = autoGenAPI.UpdatePaidVersionOrderMutation

export const transferApplicantAuthority = (accessToken: string, variables: TransferApplicantVariables & AppInfoVariables): Promise<GraphQLResult<TransferApplicationResponse>> => {
  return execGqlRequest<TransferApplicationResponse>(autoGenMutations.applicantAuthorityTransfer, variables, accessToken)
}
export type TransferApplicantVariables = autoGenAPI.ApplicantAuthorityTransferMutationVariables
export type TransferApplicationResponse = autoGenAPI.ApplicantAuthorityTransferMutation

export const canDeleteAccount = (accessToken: string): Promise<GraphQLResult<CanDeleteAccountResponse>> => {
  return execGqlRequest<CanDeleteAccountResponse>(autoGenQueries.canDeleteAccount, {}, accessToken)
}

export type CanDeleteAccountResponse = {canDeleteAccount: autoGenAPI.CanDeleteAccountResponse}
